import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";
const dashRepo = RepositoryFactory.get("Dashboard");

export const updateProfile = createAsyncThunk(
  "profile/updateProfile",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      // console.log(payload, "payload");
      const { data } = await dashRepo.udpateProfile(payload);
      if (data.status_code === 200) {
        toast.success(data.detail);
        onSuccess();
        return data;
      } else {
        toast.error(data.detail);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getProfile = createAsyncThunk(
  "profile/getProfile",
  async ({ onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.getProfile();
      // console.log(data, "databyahsan");
      if (data.status_code === 200) {
        onSuccess(data);
        return data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "profile/updatePassword",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.updatePassword(payload);
      // console.log("Data received from API:", data);
      if (data.status_code == 200 && data.detail === "Password updated successfully") {
        return data;
      } else {
        return rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err.response.data.detail);
      return rejectWithValue(err);
    }
  }
);

export const getPaymentCard = createAsyncThunk(
  "profile/getPaymentCard",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.getPaymentCard();
      // console.log(data, "databyahsan");
      if (data.status_code === 200) {
        return data.data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updatePaymentCard = createAsyncThunk(
  "profile/updatePaymentCard",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      // console.log(payload, "payload");
      const { data } = await dashRepo.updatePaymentCard(payload);
      // console.log(data, "databyahsaniqbal");
      if (data.status_code === 200) {
        toast.success(data.detail);
        onSuccess();
        return data;
      } else {
        toast.error(data.detail);
        return;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const deleteUsersCard = createAsyncThunk(
  "profile/deleteCard",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.deleteCard(payload);
      if (data) {
        return data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addPaymentCard = createAsyncThunk(
  "profile/addPaymentCard",
  async (tokenID, { rejectWithValue }) => {
    try {
      let payload = {
        token: tokenID,
      };
      const { data } = await dashRepo.addPaymentCard(payload);
      if (data) {
        return data;
      }
      // if (data.status === 404) {
      //   throw new Error(data.data.message);
      // } else if (data.status === 200) {
      //   toast.success("Added Card Successfully...");
      //   onSuccess();
      // }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getDashboardTrainingStats = createAsyncThunk(
  "profile/getDashboardTrainingStats",
  async (_, { rejectWithValue }) => {
    try {
      // console.log("Hello Ahsan IQbal");
      const { data } = await dashRepo.allTrainingStats();
      // console.log(data, "databyahsan");
      if (data.status_code == 200) {
        return data.data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getDashboardSimulationStats = createAsyncThunk(
  "profile/DashboardSimualtionStats",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.allSimulationStats();
      // console.log(data, "databyahsan");
      if (data.status_code == 200) {
        return data.data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getOrgResumeStats = createAsyncThunk(
  "profile/orgResumeStats",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.orgResumeStats();
      if (data.status_code == 200) {
        // console.log(data.data, "org resume");
        return data.data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const totalUserCount = createAsyncThunk(
  "profile/totalUserCount",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.totalUserCount();
      return data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const todayActiveCustomer = createAsyncThunk(
  "profile/todayActiveCustomer",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.todayActiveCustomer();
      return data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const avgInterviewSim = createAsyncThunk(
  "profile/avgInterviewSim",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.avgInterviewSim();
      return data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const avgPRoundScore = createAsyncThunk(
  "profile/avgPRoundScore",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.avgPRoundScore();
      return data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const orgIncompletionRates = createAsyncThunk(
  "profile/orgIncompletionRates",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.orgIncompletionRates();
      return data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const resumeCompletionRate = createAsyncThunk(
  "profile/resumeCompletionRate",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.resumeCompletionRate();
      return data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  profile: null,
  payments: [],
  dashboardTrainingStats: null,
  loading: false,
  error: null,
  orgResume: "N/A",
  dashboardSimulationStats: null,

  totalUserCountVal: "",
  todayActiveCustomerVal: null,
  avgInterviewSimVal: "",
  avgPRoundScoreVal: "",
  orgIncompletionRatesVal: null,
  resumeCompletionRateVal: "",
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardTrainingStats.pending, (state) => {
        // console.log("Running");
        state.loading = true;
        state.error = null;
      })
      .addCase(getDashboardTrainingStats.fulfilled, (state, action) => {
        // console.log(action.payload, "ahsandude");
        state.loading = false;
        state.dashboardTrainingStats = action.payload;
      })
      .addCase(getDashboardTrainingStats.rejected, (state, action) => {
        // console.log("Error");
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getDashboardSimulationStats.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDashboardSimulationStats.fulfilled, (state, action) => {
        // console.log(action.payload, "ahsandude");
        state.loading = false;
        state.dashboardSimulationStats = action.payload;
      })
      .addCase(getDashboardSimulationStats.rejected, (state, action) => {
        // console.log("Error");
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getOrgResumeStats.pending, (state) => {
        // console.log("Running");
        state.loading = true;
        state.error = null;
      })
      .addCase(getOrgResumeStats.fulfilled, (state, action) => {
        state.loading = false;
        state.orgResume = action.payload?.total_resume;
      })
      .addCase(getOrgResumeStats.rejected, (state, action) => {
        // console.log("Error");
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getProfile.pending, (state) => {
        // console.log("Running");
        state.loading = true;
        state.error = null;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.profile = action.payload;
      })
      .addCase(getProfile.rejected, (state, action) => {
        // console.log("Error");
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateProfile.pending, (state) => {
        // console.log("Running");
        state.loading = true;
        state.error = null;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        // console.log("Error");
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updatePassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getPaymentCard.pending, (state) => {
        // console.log("Running");
        state.loading = true;
        state.error = null;
      })
      .addCase(getPaymentCard.fulfilled, (state, action) => {
        state.loading = false;
        state.payments = action.payload;
      })
      .addCase(getPaymentCard.rejected, (state, action) => {
        // console.log("Error");
        state.loading = false;
      })
      .addCase(addPaymentCard.pending, (state) => {
        // console.log("Running");
        state.loading = true;
        state.error = null;
      })
      .addCase(addPaymentCard.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addPaymentCard.rejected, (state, action) => {
        // console.log("Error");
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteUsersCard.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteUsersCard.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteUsersCard.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(totalUserCount.pending, (state) => {
        state.loading = true;
        state.totalUserCountVal = "";
      })
      .addCase(totalUserCount.fulfilled, (state, action) => {
        state.loading = false;
        state.totalUserCountVal = action.payload?.total_count || 0;
      })
      .addCase(totalUserCount.rejected, (state) => {
        state.loading = false;
      })
      .addCase(todayActiveCustomer.pending, (state) => {
        state.loading = true;
        state.todayActiveCustomerVal = "";
      })
      .addCase(todayActiveCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.todayActiveCustomerVal = action.payload;
      })
      .addCase(todayActiveCustomer.rejected, (state) => {
        state.loading = false;
      })
      .addCase(avgInterviewSim.pending, (state) => {
        state.loading = true;
        state.avgInterviewSimVal = "";
      })
      .addCase(avgInterviewSim.fulfilled, (state, action) => {
        state.loading = false;
        state.avgInterviewSimVal = action.payload?.average_score || 0;
      })
      .addCase(avgInterviewSim.rejected, (state) => {
        state.loading = false;
      })
      .addCase(avgPRoundScore.pending, (state) => {
        state.loading = true;
        state.avgPRoundScoreVal = "";
      })
      .addCase(avgPRoundScore.fulfilled, (state, action) => {
        state.loading = false;
        state.avgPRoundScoreVal = action.payload?.average_score;
      })
      .addCase(avgPRoundScore.rejected, (state) => {
        state.loading = false;
      })
      .addCase(orgIncompletionRates.pending, (state) => {
        state.loading = true;
        state.orgIncompletionRatesVal = "";
      })
      .addCase(orgIncompletionRates.fulfilled, (state, action) => {
        state.loading = false;
        state.orgIncompletionRatesVal = action.payload;
      })
      .addCase(orgIncompletionRates.rejected, (state) => {
        state.loading = false;
      })
      .addCase(resumeCompletionRate.pending, (state) => {
        state.loading = true;
        state.resumeCompletionRateVal = "";
      })
      .addCase(resumeCompletionRate.fulfilled, (state, action) => {
        state.loading = false;
        state.resumeCompletionRateVal = action.payload;
      })
      .addCase(resumeCompletionRate.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default profileSlice.reducer;
