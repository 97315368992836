import { Box, Container, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const CvDesign = ({ tempRef }) => {
  const { singleResume } = useSelector((state) => state.users);
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };
  return (
    <Box>
      <Container
        ref={tempRef}
        sx={{ padding: 2, border: "1px solid #76838e", minHeight: "75vh", maxWidth: "" }}
      >
        {singleResume?.contact && (
          <Box textAlign="center" mb={4}>
            <Typography variant="h1" color="#1a73e8" textTransform="uppercase" fontWeight="bold">
              {singleResume?.contact?.first_name} {singleResume?.contact?.last_name}
            </Typography>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              textAlign="center"
              sx={{ fontSize: "22px", fontWeight: "600" }}
            >
              {singleResume?.contact?.email && `${singleResume?.contact.email}`}
              {singleResume?.contact?.email &&
                (singleResume?.contact?.address ||
                  singleResume?.contact?.city ||
                  singleResume?.contact?.phone ||
                  singleResume?.contact?.linked_in ||
                  singleResume?.contact?.website) &&
                " | "}
              {(singleResume?.contact?.address || singleResume?.contact?.city) &&
                `${singleResume?.contact?.address ?? ""}${
                  singleResume?.contact?.address && singleResume?.contact?.city ? ", " : ""
                }${singleResume?.contact?.city ?? ""}`}
              {(singleResume?.contact?.address || singleResume?.contact?.city) &&
                (singleResume?.contact?.phone ||
                  singleResume?.contact?.linked_in ||
                  singleResume?.contact?.website) &&
                " | "}
              {singleResume?.contact?.phone && `${singleResume?.contact.phone}`}
              <br />
              {singleResume?.contact?.phone &&
                (singleResume?.contact?.linked_in || singleResume?.contact?.website) &&
                " | "}
              {singleResume?.contact?.linked_in && `${singleResume?.contact.linked_in}`}
              {singleResume?.contact?.linked_in && singleResume?.contact?.website && " | "}
              {singleResume?.contact?.website && `${singleResume?.contact.website}`}
            </Typography>
          </Box>
        )}
        {singleResume?.blurbs && singleResume?.blurbs.length > 0 && (
          <Box mb={4}>
            <Typography variant="h3" color="#1a73e8" borderBottom={1} pb={1}>
              Professional Summary
            </Typography>
            {singleResume?.blurbs.map((item, index) => (
              <Box key={index} mb={2}>
                <Typography
                  dangerouslySetInnerHTML={{ __html: item?.text }}
                  sx={{ fontSize: "24px", fontWeight: "600" }}
                />
              </Box>
            ))}
          </Box>
        )}
        {singleResume?.certifications && singleResume?.certifications.length > 0 && (
          <Box mb={4}>
            <Typography variant="h3" color="#1a73e8" borderBottom={1} pb={1}>
              Certifications
            </Typography>
            {singleResume?.certifications?.map((certification, index) => (
              <Grid container key={index} justifyContent="space-between" alignItems="center" mb={2}>
                <Grid item>
                  <Typography variant="h4">{certification.name}</Typography>
                  <Typography
                    variant="body2"
                    textTransform="capitalize"
                    sx={{ fontSize: "20px", fontWeight: "500" }}
                  >
                    {certification?.provider}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    {formatDate(certification?.start_date)} - {formatDate(certification?.end_date)}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Box>
        )}
        {singleResume?.skills && singleResume?.skills.length > 0 && (
          <Box mb={4}>
            <Typography variant="h3" color="#1a73e8" borderBottom={1} pb={1}>
              Top Skills
            </Typography>
            {singleResume?.skills.map((category, index) => (
              <React.Fragment key={index}>
                {category.name === "Top Skills" ? (
                  <List>
                    {category?.skills &&
                      category?.skills?.map((skill, index) => (
                        <ListItem key={index} sx={{ paddingLeft: 0 }}>
                          <Typography variant="body1" sx={{ fontSize: "20px", fontWeight: "500" }}>
                            <strong>•</strong> {skill?.name}
                          </Typography>
                        </ListItem>
                      ))}
                  </List>
                ) : (
                  <List>
                    {category?.skills &&
                      category?.skills.map((skill, index) => (
                        <ListItem key={index} sx={{ paddingLeft: 0 }}>
                          <ListItemText primary={skill?.name} />
                        </ListItem>
                      ))}
                  </List>
                )}
              </React.Fragment>
            ))}
          </Box>
        )}
        {singleResume?.work_experience && singleResume?.work_experience.length > 0 && (
          <Box mb={4}>
            <Typography variant="h3" color="#1a73e8" borderBottom={1} pb={1}>
              Work Experiences
            </Typography>
            {singleResume?.work_experience.map((experience, index) => (
              <Grid container key={index} justifyContent="space-between" alignItems="center" mb={2}>
                <Grid item>
                  <Typography variant="h4">{experience?.company?.name}</Typography>
                  {experience?.roles?.map((item, roleIndex) => (
                    <Typography
                      key={roleIndex}
                      variant="body2"
                      textTransform="capitalize"
                      sx={{ fontSize: "20px", fontWeight: "500" }}
                    >
                      {item?.name}
                    </Typography>
                  ))}
                </Grid>
                <Grid item>
                  {experience?.roles?.map((item, roleIndex) => (
                    <Typography key={roleIndex} variant="body2" textTransform="capitalize">
                      {formatDate(item?.started_at)}
                      {item?.ended_at == null ? " - present" : ` - ${formatDate(item?.ended_at)}`}
                    </Typography>
                  ))}
                </Grid>
              </Grid>
            ))}
          </Box>
        )}
        {singleResume?.educations && singleResume?.educations.length > 0 && (
          <Box mb={4}>
            <Typography variant="h3" color="#1a73e8" borderBottom={1} pb={1}>
              Educations
            </Typography>
            {singleResume?.educations.map((education, index) => (
              <Grid container key={index} justifyContent="space-between" alignItems="center" mb={2}>
                <Grid item>
                  <Typography variant="h4">{education.school_name}</Typography>
                  <Typography variant="body2" sx={{ fontSize: "20px", fontWeight: "500" }}>
                    {education.degree && `${education.degree} Degree | `}
                    {education?.field}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" textTransform="capitalize">
                    {education?.ended_at == null ? "present" : `${formatDate(education?.ended_at)}`}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Box>
        )}
        {singleResume?.projects && singleResume?.projects.length > 0 && (
          <Box mb={4}>
            <Typography variant="h3" color="#1a73e8" borderBottom={1} pb={1}>
              Projects
            </Typography>
            {singleResume?.projects.map((pro, index) => (
              <Box key={index} mb={2}>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Typography variant="h4">{pro?.project_name}</Typography>
                    <Typography variant="body2" sx={{ fontSize: "20px", fontWeight: "500" }}>
                      {pro?.organization}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">
                      {formatDate(pro?.start_date)} - {formatDate(pro?.end_date)}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  sx={{ fontSize: "20px", fontWeight: "500" }}
                  variant="body2"
                  px={2}
                  dangerouslySetInnerHTML={{ __html: pro?.additional_information }}
                />
              </Box>
            ))}
          </Box>
        )}
        {singleResume?.publications && singleResume?.publications.length > 0 && (
          <Box mb={4}>
            <Typography variant="h3" color="#1a73e8" borderBottom={1} pb={1}>
              Publications
            </Typography>
            {singleResume?.publications.map((publication, index) => (
              <Grid container key={index} justifyContent="space-between" alignItems="center" mb={2}>
                <Grid item>
                  <Typography variant="h4">{publication.title}</Typography>
                  <Typography variant="body2" sx={{ fontSize: "20px", fontWeight: "500" }}>
                    {publication.publisher}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{formatDate(publication?.date)}</Typography>
                </Grid>
              </Grid>
            ))}
          </Box>
        )}
        {singleResume?.interests?.length > 0 && (
          <Box mb={4}>
            <Typography variant="h3" color="#1a73e8" borderBottom={1} pb={1}>
              Interests
            </Typography>
            <List sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              {singleResume?.interests?.map((interestGroup, index) =>
                interestGroup?.interests?.map((interest, subIndex) => (
                  <ListItem key={`${index}-${subIndex}`} sx={{ width: "auto", paddingLeft: 0 }}>
                    <Typography variant="body1" sx={{ fontSize: "24px", fontWeight: "500" }}>
                      <strong>•</strong> {interest}
                    </Typography>
                  </ListItem>
                ))
              )}
            </List>
          </Box>
        )}
        {singleResume?.soft_skills?.length > 0 && (
          <Box mb={4}>
            <Typography variant="h3" color="#1a73e8" borderBottom={1} pb={1}>
              Soft Skills
            </Typography>
            <List sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              {singleResume?.soft_skills.map((interestGroup, index) =>
                interestGroup?.skills?.map((interest, subIndex) => (
                  <ListItem key={`${index}-${subIndex}`} sx={{ width: "auto", paddingLeft: 0 }}>
                    <Typography variant="body1" sx={{ fontSize: "24px", fontWeight: "500" }}>
                      <strong>•</strong> {interest}
                    </Typography>
                  </ListItem>
                ))
              )}
            </List>
          </Box>
        )}
        {singleResume?.languages?.length > 0 && (
          <Box mb={4}>
            <Typography variant="h3" color="#1a73e8" borderBottom={1} pb={1}>
              Languages
            </Typography>
            <List sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              {singleResume?.languages.map((interestGroup, index) =>
                interestGroup?.languages?.map((interest, subIndex) => (
                  <ListItem key={`${index}-${subIndex}`} sx={{ width: "auto", paddingLeft: 0 }}>
                    <Typography variant="body1" sx={{ fontSize: "24px", fontWeight: "500" }}>
                      <strong>•</strong> {interest}
                    </Typography>
                  </ListItem>
                ))
              )}
            </List>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default CvDesign;
