import React, { useEffect } from "react";
import { Box, Avatar, Typography } from "@mui/material";
import moment from "moment";
import UserImg from "assets/images/logos/user.png";

const MessageComponent = ({ key, comment }) => {
  useEffect(() => {
    if (comment) {
      const element = document.getElementById(comment.created_at);
      if (element) {
        element.parentElement.scrollTo({
          top: element.offsetTop,
          behavior: "smooth",
        });
      }
    }
  }, [comment]);

  return (
    <Box
      key={key}
      id={comment?.created_at}
      sx={{
        backgroundColor: "#f7f7f7",
        py: 2,
        borderRadius: "12px",
        my: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "7%",
          }}
        >
          <Avatar
            src={comment?.image_url || UserImg}
            alt="Profile Image"
            sx={{ width: 50, height: 50 }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "93%",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" component="span" sx={{ color: "#000" }}>
              {comment?.customer_name}
            </Typography>
            <Typography variant="h6" component="span" sx={{ color: "#000" }}>
              &nbsp;-&nbsp;
              {moment(comment?.created_at).format("hh:mm A")}
            </Typography>
          </Box>
          <Typography
            variant="body1"
            sx={{
              color: "#000",
              mt: { xs: 0 },
              fontWeight: 500,
              lineHeight: 1.5,
            }}
          >
            {comment?.comment}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MessageComponent;
