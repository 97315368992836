import { SearchOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
  Pagination,
  Tooltip,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import ConfirmationModal from "../../components/ConfirmationModal";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Loader";
import {
  addRoleCategory,
  deleteBulkCategory,
  deleteRoleCategory,
  getAllRoleCategories,
  updateRoleCategory,
} from "../../store/Slices/roleCategorySlice";
import { getAllRole } from "store/Slices/roleSlice";
import { toast } from "react-toastify";
import { getRouteByNumber } from "context";
import { useNavigate } from "react-router-dom";
import { featurePermissionsObject } from "context";
import { permission } from "context";

const styleModal = {
  width: "90vw", // use a percentage width for responsiveness
  maxWidth: "500px", // set a max width to prevent it from getting too large
  margin: "auto", // center the modal
  padding: "20px", // add some padding
  boxShadow: 24,
  borderRadius: "8px",
};
export default function index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, allRoleCategories, hasSession } = useSelector((state) => state.roleCategories);
  const { allRoles } = useSelector((state) => state.roles);
  const { user } = useSelector((state) => state.auth);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [search, setSearch] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [rolesDetails, setRolesDetails] = useState([]);
  const [selectedRole, setSelectedRole] = useState({});
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [withOutOrgIds, setwithOutOrgIds] = useState([]);
  const [storePermissions, setStorePermissions] = useState(null);
  const parsedObject = JSON.parse(user?.rolePermissions);
  let pagePermissions;
  let featurePermissions;
  if (parsedObject) {
    const [key, value] = Object.entries(parsedObject)[0];
    pagePermissions = key;
    featurePermissions = value;
  }

  const handleDisabled = (val) => {
    if (storePermissions) {
      if (storePermissions.hasOwnProperty(val)) {
        return !storePermissions[val];
      }
      return null;
    }
  };

  const handleConfirmationToggle = () => {
    setConfirmationModal((prevState) => !prevState);
  };
  const handleDelete = () => {
    dispatch(
      deleteRoleCategory({
        categoryId: selectedCategoryId,
        onSuccess: () => {
          dispatch(getAllRoleCategories());
          setConfirmationModal(false);
        },
      })
    );
  };
  const handleDeleteConfirmationToggle = () => {
    setDeleteConfirmationModal((prevState) => !prevState);
  };
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleSelect = (opt) => {
    setSelectedRole(opt);
  };
  const handleAllDelete = () => {
    const filteredCategories = allRoleCategories
      .filter((category) => selectedRows.includes(category.id))
      .filter((category) => category.organization_id != null);
    const filteredIds = filteredCategories.map((category) => category.id);
    dispatch(
      deleteBulkCategory({
        payload: filteredIds,
        onSuccess: () => {
          handleDeleteConfirmationToggle();
          dispatch(getAllRoleCategories());
          setSelectedRows([]);
        },
      })
    );
  };
  const handleClearFields = () => {
    setModalType("");
    setSelectedRole({});
    setSelectedCategoryId("");
    setCategoryName("");
  };
  const handleSubmit = () => {
    const categoryExists = allCategories.some(
      (category) =>
        category.name.toLowerCase() === categoryName.toLowerCase() &&
        category.jobRoleName === selectedRole.label
    );
    if (modalType == "add") {
      let payload = {
        name: categoryName,
        jobRoleId: selectedRole.value.toString(),
      };
      if (categoryExists) {
        toast.error("This category name already exists in the selected role!.");
      } else {
        dispatch(
          addRoleCategory({
            payload,
            onSuccess: () => {
              handleClearFields();
              dispatch(getAllRoleCategories());
            },
          })
        );
        setModalOpen(false);
      }
    } else {
      const formData = new FormData();
      formData.append("name", categoryName);
      formData.append("job_role_id", selectedRole.value.toString());
      let payload = {
        categoryId: selectedCategoryId.toString(),
        form: formData,
      };
      if (categoryExists) {
        toast.error("This category name already exists in the selected role!.");
      } else {
        dispatch(
          updateRoleCategory({
            payload,
            onSuccess: () => {
              handleClearFields();
              dispatch(getAllRoleCategories());
            },
          })
        );
      }
    }
  };

  useEffect(() => {
    const filteredCategories = allRoleCategories
      .filter((category) => selectedRows.includes(category.id))
      .filter((category) => category.organization_id == null);
    const filteredIds = filteredCategories.map((category) => category.id);
    setwithOutOrgIds(filteredIds);
  }, [selectedRows]);

  useEffect(() => {
    if (allRoles) {
      const transformedCategories = allRoles.map((role) => ({
        label: role.display_name,
        value: role.id,
      }));
      setRolesDetails(transformedCategories);
      setSelectedRole(transformedCategories[0]);
    }
  }, [allRoles]);

  useEffect(() => {
    setColumns([
      { Header: "Role", accessor: "role", align: "left" },
      { Header: "Name", accessor: "display_name", align: "left" },
      { Header: "Action", accessor: "action", align: "center" },
    ]);

    const customAddRoleCategories =
      allCategories &&
      allCategories.length >= 1 &&
      allCategories.map((data, index) => {
        const roleName =
          rolesDetails?.find((role) => role.label === data.jobRoleName)?.label || "Unknown Role";
        return {
          id: data?.id,
          role: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {roleName}
            </MDTypography>
          ),
          display_name: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {data?.display_name}
            </MDTypography>
          ),
          action: (
            <>
              {data?.organization_id != null && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ marginRight: "10px" }}
                    size="small"
                    disabled={handleDisabled("update")}
                    onClick={() => {
                      const matchingRole = rolesDetails.find(
                        (role) => role.label === data.jobRoleName
                      );
                      handleModalOpen();
                      setModalType("edit");
                      setCategoryName(data?.name);
                      setSelectedRole(matchingRole);
                      setSelectedCategoryId(data?.id);
                    }}
                  >
                    <Icon sx={{ color: "#fff" }}>edit</Icon>
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ color: "#fff", bgcolor: "#ec4b48" }}
                    size="small"
                    disabled={handleDisabled("delete")}
                    onClick={() => {
                      // dispatch(beforeDeleteRoleCategory(data?.id));
                      handleConfirmationToggle();
                      setSelectedCategoryId(data?.id);
                    }}
                  >
                    <Icon sx={{ color: "#fff" }}>delete</Icon>
                  </Button>
                </>
              )}
            </>
          ),
        };
      });

    setRows(Array.isArray(customAddRoleCategories) ? customAddRoleCategories : []);
  }, [allCategories, rolesDetails]);

  useEffect(() => {
    if (pagePermissions !== permission.admin && pagePermissions !== permission.rolesCategories) {
      const routeLink = getRouteByNumber(pagePermissions);
      return navigate(routeLink);
    }
    if (featurePermissions) {
      const data = featurePermissionsObject(featurePermissions);
      setStorePermissions(data);
      console.log(data, "featurePermissionsfeaturePermissions");
    }
    dispatch(getAllRole());
    dispatch(getAllRoleCategories());
  }, []);

  useEffect(() => {
    if (search !== "") {
      const foundCategories = allRoleCategories.filter(
        (category) =>
          category.name.toLowerCase().includes(search.toLowerCase()) ||
          category.jobRoleName.toLowerCase().includes(search.toLowerCase()) ||
          category.id.toString().includes(search)
      );
      setAllCategories(foundCategories);
    } else {
      setAllCategories(allRoleCategories);
    }
  }, [allRoleCategories, search]);
  return (
    <>
      {/* <DashboardLayout> */}
      <MDBox pt={3} pb={3}>
        {loading && <Loader />}
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {/* <Card> */}
            <MDBox pt={3}>
              <Box pt={1} px={2}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} lg={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="search">Search</InputLabel>
                      <OutlinedInput
                        id="search"
                        type="text"
                        sx={{
                          width: { lg: "50%", xs: "100%" },
                        }}
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="search"
                              // onClick={handleClickShowPassword}
                              // onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              <SearchOutlined />
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Search"
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={6}
                    display="flex"
                    justifyContent={{ xs: "center", lg: "flex-end" }}
                    alignItems="center"
                  >
                    <Box>
                      <Tooltip
                        title={
                          withOutOrgIds.length >= 1
                            ? " You can't delete Role Categories without organization Id"
                            : ""
                        }
                        arrow
                        placement="top"
                        disableInteractive={!withOutOrgIds.length >= 1}
                      >
                        <span>
                          <Button
                            variant="contained"
                            sx={{
                              color: "#fff",
                              bgcolor: "#ec4b48",
                              "&:hover": {
                                bgcolor: "darkslategray",
                              },
                              marginRight: "10px",
                              cursor: withOutOrgIds.length >= 1 ? "not-allowed" : "pointer",
                            }}
                            disabled={withOutOrgIds.length >= 1 || handleDisabled("delete")}
                            onClick={() => {
                              if (selectedRows.length > 0) {
                                handleDeleteConfirmationToggle();
                              } else {
                                toast.error("Please select any category for deletion!");
                              }
                            }}
                          >
                            <Icon fontSize="small">delete_forever</Icon> Delete
                          </Button>
                        </span>
                      </Tooltip>

                      <Button
                        variant="contained"
                        sx={{
                          color: "#fff",
                          bgcolor: "#3b8335",
                          "&:hover": {
                            bgcolor: "darkslategray",
                          },
                        }}
                        disabled={handleDisabled("create")}
                        onClick={() => {
                          handleModalOpen();
                          setModalType("add");
                          setCategoryName("");
                          setSelectedRole(rolesDetails[0]);
                        }}
                      >
                        <Icon fontSize="small">add</Icon> New
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              {allCategories && allCategories.length >= 1 ? (
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                  checkboxSelection
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  checkBoxes={true}
                />
              ) : (
                <MDTypography
                  component="h2"
                  href="#"
                  color="text"
                  fontWeight="bold"
                  sx={{
                    textAlign: "center",
                    my: 10,
                  }}
                >
                  No Role Categories Exists
                </MDTypography>
              )}
            </MDBox>
            {/* <MDBox
                  m={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pagination count={10} />
                </MDBox> */}
            {/* </Card> */}
          </Grid>
        </Grid>
      </MDBox>
      {/* </DashboardLayout> */}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
          <Card sx={styleModal}>
            <Box
              variant="gradient"
              bgcolor="info.main"
              borderRadius="12px"
              boxShadow={3}
              mx={2}
              p={2}
              mb={1}
              textAlign="center"
            >
              <Typography variant="h4" fontWeight="medium" color="white.main" mt={1} mb={1}>
                {modalType === "add" ? "Add Role Category" : "Edit Role Category"}
              </Typography>
            </Box>
            <Box mx={1} p={1} mt={1}>
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  handleSubmit();
                }}
              >
                <Box mb={2}>
                  <MDInput
                    type="text"
                    label="Name"
                    name="name"
                    value={categoryName}
                    required
                    onChange={(e) => setCategoryName(e.target.value)}
                    fullWidth
                  />
                </Box>

                <Box mb={2}>
                  <Select
                    className="mt-3"
                    options={rolesDetails}
                    placeholder="Select Category"
                    required
                    value={selectedRole}
                    onChange={handleSelect}
                  />
                </Box>

                <Box mt={4} mb={1} sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => setModalOpen(false)}
                    disabled={loading}
                  >
                    Cancel
                  </MDButton>
                  <MDButton variant="gradient" color="info" type="submit" disabled={loading}>
                    {modalType === "add" && loading == false
                      ? "Add"
                      : modalType === "add" && loading
                      ? "Adding..."
                      : modalType === "edit" && loading
                      ? "Updating..."
                      : modalType === "edit" && loading == false
                      ? "Update "
                      : ""}
                  </MDButton>
                </Box>
              </form>
            </Box>
          </Card>
        </Grid>
      </Modal>
      <ConfirmationModal
        modalOpen={confirmationModal}
        modalToggle={handleConfirmationToggle}
        onSubmit={handleDelete}
        loading={loading}
        infoText="Deleting a category will also delete all its associated questions."
      />
      <ConfirmationModal
        modalOpen={deleteConfirmationModal}
        modalToggle={handleDeleteConfirmationToggle}
        onSubmit={handleAllDelete}
        loading={loading}
        infoText="Deleting a category will also delete all its associated questions."
      />
    </>
  );
}
