import React, { useState } from "react";
import { Menu, MenuItem, Button } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const FilterDropdown = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const isOpen = Boolean(anchorEl);

  const dropdownOptions = [
    { id: 1, label: "Most Recent" },
    { id: 2, label: "Oldest" },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (option) => {
    if (option) {
      setSelectedOption(option);
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        sx={{
          width: "100%",
          textAlign: "left",
          textTransform: "capitalize",
          fontSize: "14px",
          fontWeight: "500",
          color: "#000",
        }}
      >
        {selectedOption ? selectedOption.label : "Select Filter"}
      </Button>
      <Menu anchorEl={anchorEl} open={isOpen} onClose={() => handleClose(null)}>
        {dropdownOptions.map((option) => (
          <MenuItem key={option.id} onClick={() => handleClose(option)} sx={{ cursor: "pointer" }}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default FilterDropdown;
