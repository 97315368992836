import { Box, TextareaAutosize } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import React from "react";
import Select from "react-select";
import DropDowns from "./DropDowns";
import { useDispatch, useSelector } from "react-redux";
import { addSimpleQuestions, getQuestions } from "../../../store/Slices/questionsSlice";
const bodyStyle = {
  height: "auto",
};
export default function SimpleQuestions({
  simpleQuestions,
  setSimpleQuestions,
  rolesDetails,
  categoriesDetails,
  setAddModalOpen,
  debouncedSearch,
  questionsType,
}) {
  console.log(simpleQuestions, "simpleQuestions");
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.questions);
  const handleQuestionTextChange = (index, event) => {
    const updatedQuestions = [...simpleQuestions];
    updatedQuestions[index].question = event.target.value;
    setSimpleQuestions(updatedQuestions);
  };

  const handleAddQuestion = () => {
    setSimpleQuestions([
      ...simpleQuestions,
      { question_type_id: "", role_category_id: "", question: "", roleId: "" },
    ]);
  };

  const handleRemoveQuestion = (index) => {
    const updatedQuestions = simpleQuestions.filter((_, i) => i !== index);
    console.log(updatedQuestions, "updatedQuestions");
    setSimpleQuestions(updatedQuestions);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let payload = {
      questions: simpleQuestions.map((question) => {
        const { roleId, ...remainingQuestion } = question;
        return remainingQuestion;
      }),
    };
    dispatch(
      addSimpleQuestions({
        payload,
        onSuccess: () => {
          setSimpleQuestions([
            { question_type_id: "", role_category_id: "", question: "", roleId: "" },
          ]);

          dispatch(getQuestions({ page: 1, search: debouncedSearch }));
        },
      })
    );
    setAddModalOpen(false);
  };
  return (
    <form onSubmit={handleSubmit}>
      <Box sx={bodyStyle}>
        {simpleQuestions.map((question, index) => (
          <Box mb={2}>
            <DropDowns
              rolesDetails={rolesDetails}
              question={question}
              categoriesDetails={categoriesDetails}
              Questions={simpleQuestions}
              setQuestions={setSimpleQuestions}
              index={index}
              questionsType={questionsType}
            />
            <TextareaAutosize
              minRows={3}
              placeholder="Enter Question"
              value={question.question}
              onChange={(event) => handleQuestionTextChange(index, event)}
              style={{ width: "100%", marginTop: "10px", padding: "10px" }}
              required
            />
            {simpleQuestions.length > 1 && (
              <MDButton
                variant="gradient"
                color="error"
                onClick={() => handleRemoveQuestion(index)}
              >
                Remove
              </MDButton>
            )}
          </Box>
        ))}

        <MDButton variant="gradient" color="info" onClick={handleAddQuestion}>
          Add Another Question
        </MDButton>
      </Box>
      <MDBox mt={4} mb={1} sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
        <MDButton
          variant="gradient"
          color="info"
          onClick={() => setAddModalOpen(false)}
          disabled={loading}
        >
          Cancel
        </MDButton>
        <MDButton variant="gradient" color="info" type="submit" disabled={loading}>
          {loading ? "Loading..." : "Submit"}
        </MDButton>
      </MDBox>
    </form>
  );
}
