import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { TextInput } from "./TextInput.js";
import { MessageLeft, MessageRight } from "./Message";
import { Avatar, Icon } from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: "100%",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      position: "relative",
    },
    header: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      backgroundColor: "#f5f5f5",
      padding: "16px",
      borderRadius: "12px",
    },
    messagesBody: {
      width: "100%",
      padding: "10px",
      margin: 10,
      overflowY: "scroll",
      height: "calc( 100% - 80px )",
      "&::-webkit-scrollbar": {
        width: "10px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
        borderRadius: "10px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#888",
        borderRadius: "10px",
        border: "3px solid #f1f1f1",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#555",
      },
    },
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    avatarNothing: {
      color: "transparent",
      backgroundColor: "transparent",
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    displayName: {
      marginLeft: "20px",
      fontSize: "20px",
      fontWeight: "bold",
    },
  })
);

export default function ChatBox({ handleActive, handleDisabled }) {
  const { singleChatLoading, singleChat } = useSelector((state) => state.chats);

  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        {singleChatLoading ? (
          <div>Loading...</div>
        ) : (
          <>
            <div className={classes.header}>
              <Icon
                onClick={() => handleActive(null)}
                sx={{
                  marginRight: "10px",
                  fontSize: "25px !important",
                  fontWeight: "bold !important",
                  cursor: "pointer",
                  display: {
                    xs: "block",
                    lg: "none",
                  },
                }}
              >
                arrow_back
              </Icon>
              <Avatar
                alt={singleChat?.is_group ? singleChat?.name : "User Chat"}
                className={classes.orange}
                src={singleChat?.photoURL}
              ></Avatar>
              <div className={classes.displayName}>
                {singleChat?.is_group ? singleChat?.name : "User Chat"}
              </div>
            </div>
            <Paper id="style-1" className={classes.messagesBody}>
              {singleChat?.messages?.length > 0 ? (
                <>
                  {" "}
                  {singleChat?.messages?.map((msg, index) => {
                    return msg.is_my_message === false ? (
                      <MessageLeft
                        key={index}
                        message={msg.content}
                        timestamp={msg.created_at}
                        photoURL={msg.photoURL}
                        displayName={msg.displayName}
                        avatarDisp={msg.avatarDisp}
                      />
                    ) : (
                      <MessageRight
                        key={index}
                        message={msg.content}
                        timestamp={msg.created_at}
                        photoURL={msg.photoURL}
                        displayName={msg.displayName}
                        avatarDisp={msg.avatarDisp}
                      />
                    );
                  })}
                </>
              ) : (
                <div style={{ padding: "10px", textAlign: "center" }}>No Messages</div>
              )}
            </Paper>
            <TextInput chatID={singleChat?.id} handleDisabled={handleDisabled} />
          </>
        )}
      </div>
    </>
  );
}
