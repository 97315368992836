import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const AuthRoutes = ({ element }) => {
  const { user } = useSelector((state) => state.auth);

  if (user) {
    if (user?.isNewUser) {
      return <Navigate to="/onboarding" />;
    } else {
      return <Navigate to="/dashboard" />;
    }
  }

  return element;
};

export default AuthRoutes;
