import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { deepOrange } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) =>
  createStyles({
    messageRow: {
      display: "flex",
      padding: "10px",
      borderRadius: "10px",
      justifyContent: "flex-start",
      alignItems: "start",
      marginTop: "10px",
      cursor: "pointer",
    },
    rowActive: {
      backgroundColor: "#f5f5f5",
    },
    messageText: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginLeft: "10px",
      width: "100%",
    },

    messageContent: {
      padding: 0,
      margin: 0,
      fontSize: "12px",
    },
    messageTimeStampRight: {
      fontSize: ".5em",
      width: "100%",
      textAlign: "right",
      marginTop: "4px",
    },

    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    avatarNothing: {
      color: "transparent",
      backgroundColor: "transparent",
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    displayName: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  })
);
const UserChat = (props) => {
  const message = props.message ? props.message : "no message";
  const timestamp = props.timestamp ? props.timestamp : "";
  const photoURL = props.photoURL ? props.photoURL : "dummy.js";
  const displayName = props.displayName ? props.displayName : "test name";
  const classes = useStyles();

  const formatMessage = (msg) => {
    if (msg.length > 35) {
      return `${msg.substring(0, 35)}...`;
    }
    return msg;
  };
  return (
    <div
      className={classes.messageRow + " " + (props.activeChat ? classes.rowActive : "")}
      onClick={() => props.handleActive(props.id)}
    >
      <Avatar alt={displayName} className={classes.orange} src={photoURL}></Avatar>
      <div className={classes.messageText}>
        <div className={classes.displayName}>{displayName}</div>
        <div>
          {/* <div>
            <p className={classes.messageContent}>{formatMessage(message)}</p>
          </div> */}
          <div className={classes.messageTimeStampRight}>{timestamp}</div>
        </div>
      </div>
    </div>
  );
};

export default UserChat;
