import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ element }) => {
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();

  // Check if the current path contains "/open/"
  const isOpenRoute = location.pathname.includes("/open/");

  if (!user && !isOpenRoute) {
    return <Navigate to="/authentication/sign-in" />;
  }

  return element;
};

export default ProtectedRoute;
