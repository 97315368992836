import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";
const boardingRepo = RepositoryFactory.get("boarding");

export const AddBoarding = createAsyncThunk(
  "Boarding/AddBoarding",
  async ({ payload }, { rejectWithValue }) => {
    try {
      // console.log(payload, "payload");
      const { data } = await boardingRepo.AddBoarding(payload);
      if (data.status_code == 200) {
        // toast.success(data.detail);
        // onSuccess();
        return data;
      } else {
        toast.error(data.detail);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBoarding = createAsyncThunk(
  "Boarding/getBoarding",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await boardingRepo.getBoarding();
      if (data.entries.length > 0) {
        return data.entries;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateOnboarding = createAsyncThunk(
  "Boarding/updateOnBoard",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await boardingRepo.udpateOnBoard(payload);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const generateSkillsGapAnalysis = createAsyncThunk(
  "Boarding/skillsGap",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await boardingRepo.skillsGapAnalysis(payload);
      if (data.status_code == 200) {
        return data.data.analysis;
      }
    } catch (error) {
      toast.error("SomeThing went wrong");
      return rejectWithValue(error.message);
    }
  }
);
export const getAllSkillGapAnalysis = createAsyncThunk(
  "Boarding/getAllSkillGapAnalysis",
  async ({ payload }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await boardingRepo.getAllSkillGapAnalysis(payload);
      if (data.status_code == 200) {
        return data.data;
      }
    } catch (error) {
      toast.error("SomeThing went wrong");
      return rejectWithValue(error.message);
    }
  }
);

export const addSkillGapAnalysis = createAsyncThunk(
  "Boarding/addSkillGapAnalysis",
  async ({ payload, onSuccess }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await boardingRepo.addSkillGapAnalysis(payload);
      if (data.status_code == 200) {
        onSuccess();
        return data.data;
      }
    } catch (error) {
      toast.error("SomeThing went wrong");
      return rejectWithValue(error.message);
    }
  }
);

export const deleteSkillGapAnalysis = createAsyncThunk(
  "Boarding/deleteSkillGapAnalysis",
  async ({ payload, onSuccess }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await boardingRepo.deleteSkillGapAnalysis(payload);

      if (data.status_code == 200) {
        onSuccess();
        return data.data;
      }
    } catch (error) {
      toast.error("SomeThing went wrong");
      return rejectWithValue(error.message);
    }
  }
);

export const getSingleSkillGap = createAsyncThunk(
  "Boarding/getSingleSkillGap",
  async ({ payload, onSuccess }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await boardingRepo.getSingleSkillGap(payload);
      console.log(data, "databyahsaniqbal");
      if (data.status_code == 200) {
        onSuccess();
        return data.data;
      }
    } catch (error) {
      toast.error("SomeThing went wrong");
      return rejectWithValue(error.message);
    }
  }
);

export const updateSkillGapAnalysis = createAsyncThunk(
  "Boarding/updateSkillGapAnalysis",
  async ({ payload, onSuccess }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await boardingRepo.updateSkillGapAnalysis(payload);
      if (data.status_code == 200) {
        onSuccess();
        return data.data;
      }
    } catch (error) {
      toast.error("SomeThing went wrong");
      return rejectWithValue(error.message);
    }
  }
);
export const sendSkillGapReport = createAsyncThunk(
  "Boarding/sendSkillGapReport",
  async ({ payload, onSuccess }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await boardingRepo.sendSkillGapReport(payload);
      onSuccess();
      return data.message;
    } catch (error) {
      toast.error("SomeThing went wrong");
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  onboardingData: [],
  skillGapData: null,
  singleSkillGap: null,
  skillGapDetail: null,
  onBoardStep: 0,
  loading: false,
  error: null,
  totalPages: 0,
  mailLoading: false,
};

const BoardingSlice = createSlice({
  name: "Boarding",
  initialState,
  reducers: {
    setOnboardStep(state, action) {
      // console.log(action.payload, "setOnboardStep");
      state.onBoardStep = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendSkillGapReport.pending, (state) => {
        state.mailLoading = true;
      })
      .addCase(sendSkillGapReport.fulfilled, (state, action) => {
        state.mailLoading = false;
      })
      .addCase(sendSkillGapReport.rejected, (state, action) => {
        state.mailLoading = false;
      })
      .addCase(generateSkillsGapAnalysis.pending, (state) => {
        state.loading = true;
      })
      .addCase(generateSkillsGapAnalysis.fulfilled, (state, action) => {
        state.loading = false;
        state.skillsGap = action.payload;
      })
      .addCase(generateSkillsGapAnalysis.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(AddBoarding.pending, (state) => {
        // console.log("Running");
        state.loading = true;
        state.error = null;
      })
      .addCase(AddBoarding.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(AddBoarding.rejected, (state, action) => {
        // console.log("Error");
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getBoarding.pending, (state) => {
        // console.log("Running");
        state.loading = true;
        state.error = null;
      })
      .addCase(getBoarding.fulfilled, (state, action) => {
        state.loading = false;
        state.onboardingData = action.payload;
      })
      .addCase(getBoarding.rejected, (state, action) => {
        // console.log("Error");
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getAllSkillGapAnalysis.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllSkillGapAnalysis.fulfilled, (state, action) => {
        state.loading = false;
        state.skillGapData = action.payload.analyses;
        state.totalPages = action.payload.total_pages;
      })
      .addCase(getAllSkillGapAnalysis.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addSkillGapAnalysis.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addSkillGapAnalysis.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addSkillGapAnalysis.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteSkillGapAnalysis.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteSkillGapAnalysis.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteSkillGapAnalysis.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getSingleSkillGap.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSingleSkillGap.fulfilled, (state, action) => {
        console.log(action.payload, "action.payload");
        state.loading = false;
        state.singleSkillGap = action.payload;
        state.skillGapDetail = action.payload.skill_gap_analysis;
      })
      .addCase(getSingleSkillGap.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateSkillGapAnalysis.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSkillGapAnalysis.fulfilled, (state, action) => {
        console.log(action.payload, "action.payload");
        state.loading = false;
      })
      .addCase(updateSkillGapAnalysis.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export const { setOnboardStep } = BoardingSlice.actions;
export default BoardingSlice.reducer;
