import BasicLayout from "layouts/authentication/components/BasicLayout";
import React from "react";

const NotAuthenticated = () => {
  return (
    <BasicLayout>
      <h2>Not Authenticated</h2>
    </BasicLayout>
  );
};

export default NotAuthenticated;
