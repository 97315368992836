import { Card } from "@mui/material";
import Modal from "@mui/material/Modal";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getSubscription } from "store/Slices/authSlice";
import { checkOutSession } from "store/Slices/subscriptionSlice";
import UserLimitModal from "./UserLimitModal";
import { useState } from "react";
import { CancelationActiveBundle } from "store/Slices/subscriptionSlice";

const DownGradeModal = ({ modal, handleModal, activePricingId, userStats }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { total_invitations } = useSelector((state) => state?.users);
  const [stopmodal, setStopModal] = useState(false);

  const handleStopModal = () => {
    setStopModal(!stopmodal);
  };

  const handleSubmit = () => {
    const payload = {
      bundlePlanId: activePricingId,
    };

    if (activePricingId == "QolEraPnrw" && total_invitations >= 5) {
      handleModal();
      handleStopModal();
      return;
    }
    // if (user?.cancelation) {
    //   // const pricingId = activePricingId;
    //   dispatch(
    //     CancelationActiveBundle({
    //       pricingId: activePricingId,
    //       quantity: userStats?.stripe_quantity,
    //     })
    //   )
    //     .unwrap()
    //     .then((res) => {
    //       toast.success(res.detail);
    //       setTimeout(() => {
    //         window.location.href = res.redirectUrl;
    //       }, 1500);
    //     })
    //     .catch((res) => {
    //       toast.error(res?.response?.data?.detail);
    //     });
    // } else {
    dispatch(checkOutSession(payload))
      .unwrap()
      .then((res) => {
        toast.success(res.message);
        getCurrentSubscription();
      });
    // }
    handleModal();
  };
  const getCurrentSubscription = () => {
    dispatch(getSubscription())
      .unwrap()
      .then((res) => {})
      .catch((rejected) => {
        toast.error(rejected?.response?.data?.detail);
      });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "90%", sm: "60%", md: "60%", lg: "40%" },
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Modal
        open={modal}
        onClose={handleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style}>
          <MDBox mx={1} p={1} mt={1}>
            <MDTypography variant="h4" mt={1}>
              Are you sure you want to downgrade
            </MDTypography>
            <MDTypography variant="p" sx={{ fontSize: "14px" }}>
              Downgrading will complete at start of your next billing cycle
            </MDTypography>
            <MDBox mt={4} mb={1} sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
              <MDButton variant="gradient" color="error" onClick={handleModal}>
                No
              </MDButton>
              <MDButton variant="gradient" color="info" onClick={handleSubmit}>
                Yes
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      </Modal>
      <UserLimitModal stopmodal={stopmodal} handleStopModal={handleStopModal} />
    </div>
  );
};

export default DownGradeModal;
