// Socket-related actions and listeners
import socket from "./socket";

export function addUserSocket(userId) {
  if (!socket.connected) {
    socket.auth = { token: userId };
    socket.connect();
  }
  socket.emit("add_user", { user_id: userId, role: "organization" });
}

export function get_message() {
  socket.on("get_message", function (msg) {});
}
export const sendMessage = (messageData) => {
  if (socket.connected) {
    console.log("sending=>", socket.id);
    socket.emit("send_message", messageData, () => {
      console.log("Message sent successfully", messageData);
    });
  }
};

export function pageRefresh(token) {
  const sanitizedToken = token.replace(/"/g, "");
  socket.auth = { token: sanitizedToken };
  socket.connect();
  socket.emit("page_refresh", {
    user_id: sanitizedToken,
    role: "organization",
  });
}
