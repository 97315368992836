import Repository from "./Repository";
const GET_ALL_ROLES = "/organization/job-role";
const ADD_ROLE = "/organization/job-role";
const DELETE_ROLE = "/organization/job-role";
const UPDATE_ROLE = "/organization/job-role";
const GET_USER_ROLES = "/organization/roles";
const roleRepository = {
  addRole(payload) {
    return Repository.post(`${ADD_ROLE}?name=${payload.name}`, payload.form);
  },
  getAllRole() {
    return Repository.get(`${GET_ALL_ROLES}`);
  },
  deleteRole(roleId) {
    return Repository.delete(`${DELETE_ROLE}?job_role_id=${roleId}`);
  },
  updateRole(payload) {
    return Repository.put(`${UPDATE_ROLE}?job_role_id=${payload.roleId}`, payload.form);
  },
  userRoles() {
    return Repository.get(`${GET_USER_ROLES}`);
  },
};

export default roleRepository;
