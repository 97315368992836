import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { updatePassSchema } from "components/schema";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updatePassword } from "store/Slices/profileSlice";
const UpdatePassForm = ({ setUpdatePassPayload, storePermissions }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const initialValues = {
    curPassword: "",
    newPassword: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: initialValues,
    validationSchema: updatePassSchema,
    onSubmit: (values) => {
      if (values.curPassword !== values.newPassword) {
        const payload = {
          old_password: values.curPassword,
          new_password: values.newPassword,
        };
        setUpdatePassPayload(values);
        submitPayload(payload);
        resetForm();
      } else {
        toast.error("Old and new password needs to be different");
      }
    },
  });

  const handleDisabled = (val) => {
    if (storePermissions) {
      if (storePermissions.hasOwnProperty(val)) {
        return !storePermissions[val];
      }
      return null;
    }
  };

  const submitPayload = (payload) => {
    dispatch(updatePassword(payload))
      .unwrap()
      .then((res) => toast.success(res.detail))
      .catch((rejected) => {});
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1} direction="row">
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password-2">Current Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password-2"
                type={showPassword ? "text" : "password"}
                label="Current Password"
                name="curPassword"
                value={values.curPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errors.curPassword && touched.curPassword ? (
                <MDTypography color="error" sx={{ fontSize: "13px" }}>
                  {<p>{errors.curPassword}</p>}
                </MDTypography>
              ) : null}
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                label="New Password"
                name="newPassword"
                value={values.newPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errors.newPassword && touched.newPassword ? (
                <MDTypography color="error" sx={{ fontSize: "13px" }}>
                  {<p>{errors.newPassword}</p>}
                </MDTypography>
              ) : null}
            </FormControl>
          </Grid>
        </Grid>

        <MDBox mt={4} mb={1} sx={{ display: "flex", gap: "10px", justifyContent: "center" }}>
          <MDButton
            variant="gradient"
            color="info"
            type="submit"
            disabled={handleDisabled("update")}
          >
            Update Password
          </MDButton>
        </MDBox>
      </form>
    </>
  );
};

export default UpdatePassForm;
