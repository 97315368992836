import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
// import Header from "../header";
// import Footer from "../footer";

const PrivacyPolicyLayout = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="ic_privacy_policy">
        <div className="container py-5">
          <div className="privacy_main">
            <aside className="privacy_policy_sidebar">
              <ul>
                <li>
                  <Link
                    to="/open/tos"
                    className={location.pathname === "/open/tos" ? "active" : ""}
                  >
                    Terms & Conditions
                  </Link>
                </li>
                <li>
                  <Link
                    to="/open/privacy-policy"
                    className={location.pathname === "/open/privacy-policy" ? "active" : ""}
                  >
                    Privacy & Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="/open/cookies-policy"
                    className={location.pathname === "/open/cookies-policy" ? "active" : ""}
                  >
                    Cookie Policy
                  </Link>
                </li>
              </ul>
            </aside>
            {children}
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicyLayout;
