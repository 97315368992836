import { Grid, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React, { useEffect } from "react";
import introvideo from "../../assets/media/introduction.mp4";
import { useNavigate } from "react-router-dom";
import { getRouteByNumber } from "context";
import { useSelector } from "react-redux";
import { permission } from "context";

const index = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const parsedObject = JSON.parse(user?.rolePermissions);
  let pagePermissions;
  let featurePermissions;
  if (parsedObject) {
    const [key, value] = Object.entries(parsedObject)[0];
    pagePermissions = key;
    featurePermissions = value;
  }

  useEffect(() => {
    if (pagePermissions !== permission.admin) {
      const routeLink = getRouteByNumber(pagePermissions);
      return navigate(routeLink);
    }
  }, []);
  return (
    <DashboardLayout>
      <MDBox py={3}>
        <Grid
          container
          spacing={3}
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <MDBox sx={{ textAlign: "end", width: "100%", my: 2 }}>
            <Typography
              variant="p"
              sx={{
                textDecoration: "underline",
                color: "#1167d6",
                cursor: "pointer",
                mx: 3,
              }}
              onClick={() => navigate("/dashboard")}
            >
              Skip
            </Typography>
          </MDBox>
          <video src={introvideo} controls autoPlay muted width="70%" />
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default index;
