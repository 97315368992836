import { SearchOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
  Pagination,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import ConfirmationModal from "../../components/ConfirmationModal";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import SimpleQuestions from "./components/SimpleQuestions";
import ChatGptQuestions from "./components/ChatGptQuestions";
import ImportQuestions from "./components/ImportQuestions";
import MDButton from "components/MDButton";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getQuestions } from "store/Slices/questionsSlice";
import Loader from "components/Loader";
import { getAllRole } from "store/Slices/roleSlice";
import { getAllRoleCategories } from "store/Slices/roleCategorySlice";
import { deleteQuestion } from "store/Slices/questionsSlice";
import { deleteQuestionsBulk, updateQuestion } from "../../store/Slices/questionsSlice";
import { useDebounce } from "use-debounce";
import { toast } from "react-toastify";
import { getRouteByNumber } from "context";
import { useNavigate } from "react-router-dom";
import { permission } from "context";
import { featurePermissionsObject } from "context";

const styleModal = {
  width: "90vw", // use a percentage width for responsiveness
  maxWidth: "600px", // set a max width to prevent it from getting too large
  margin: "auto", // center the modal
  padding: "20px", // add some padding
  boxShadow: 24,
  borderRadius: "8px",
  maxHeight: "85vh",
  overflowY: "auto",
};
const questionsType = [
  { value: 1, label: "Technical Question" },
  { value: 2, label: "Situational Question" },
  { value: 3, label: "Background Question" },
];
export default function index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { loading, questions, total, page, totalPages } = useSelector((state) => state.questions);
  const { allRoles } = useSelector((state) => state.roles);
  const { allRoleCategories } = useSelector((state) => state.roleCategories);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [rolesDetails, setRolesDetails] = useState([]);
  const [categoriesDetails, setCategoriesDetails] = useState([]);
  const [matchingCategories, setMatchingCategories] = useState([]);
  const [selectedQuestionId, setSelectedQuestionId] = useState("");
  const [selectedQuestion, setSelectedQuestion] = useState("");
  const [selectedRole, setSelectedRole] = useState({});
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedQuestionType, setSelectedQuestionType] = useState({});
  const [storePermissions, setStorePermissions] = useState(null);
  const [value, setValue] = useState("1");
  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebounce(search, 500);
  const [simpleQuestions, setSimpleQuestions] = useState([
    { question_type_id: "", role_category_id: "", question: "", roleId: "" },
  ]);
  console.log("selectedRows: ", selectedRows);
  const [chatgptQuestions, setChatgptQuestions] = useState({
    question_type_id: "",
    role_category_id: "",
    questionNo: "",
    roleId: "",
  });
  const [importQuestions, setImportQuestions] = useState({
    question_type_id: "",
    role_category_id: "",
    file: "",
    roleId: "",
  });
  const parsedObject = JSON.parse(user?.rolePermissions);
  let pagePermissions;
  let featurePermissions;
  if (parsedObject) {
    const [key, value] = Object.entries(parsedObject)[0];
    pagePermissions = key;
    featurePermissions = value;
  }
  const handleConfirmationToggle = () => {
    setConfirmationModal((prevState) => !prevState);
  };
  const handleDeleteConfirmationToggle = () => {
    setDeleteConfirmationModal((prevState) => !prevState);
  };
  const handleDelete = () => {
    dispatch(
      deleteQuestion({
        questionId: selectedQuestionId,
        onSuccess: () => {
          handleConfirmationToggle();
          dispatch(getQuestions({ page: page, search: debouncedSearch }));
        },
      })
    );
  };
  const handleAllDelete = () => {
    let payload = {
      ids: selectedRows,
    };
    dispatch(
      deleteQuestionsBulk({
        payload,
        onSuccess: () => {
          dispatch(getQuestions({ page: 1, search: debouncedSearch }));
          setSelectedRows([]);
        },
      })
    );
    handleDeleteConfirmationToggle();
  };
  const handleAddModalOpen = () => {
    setAddModalOpen(true);
  };
  const handleEditModalOpen = () => {
    setEditModalOpen(true);
  };
  const handlePaginationChange = (event, value) => {
    dispatch(getQuestions({ page: value, search: debouncedSearch }));
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSimpleQuestions([{ question_type_id: "", role_category_id: "", question: "", roleId: "" }]);
    setChatgptQuestions({ question_type_id: "", role_category_id: "", questionNo: "", roleId: "" });
    setImportQuestions({ question_type_id: "", role_category_id: "", file: "", roleId: "" });
  };
  const handleRoleSelect = (opt) => {
    setSelectedRole(opt);
    setSelectedCategory({});
    setMatchingCategories([]);
  };
  const handleCategorySelect = (opt) => {
    setSelectedCategory(opt);
  };
  const handleQuestionTypeSelect = (opt) => {
    setSelectedQuestionType(opt);
  };
  const handleSubmit = () => {
    let payload = {
      question: selectedQuestion,
      role_category_id: selectedCategory.value,
      question_type_id: selectedQuestionType.value,
    };
    dispatch(
      updateQuestion({
        questionId: selectedQuestionId,
        payload: payload,
        onSuccess: () => {
          setEditModalOpen(false);
          setSelectedQuestion("");
          setSelectedCategory({});
          setSelectedRole({});
          dispatch(getQuestions({ page: 1, search: debouncedSearch }));
        },
      })
    );
  };
  const handleDisabled = (val) => {
    if (storePermissions) {
      if (storePermissions.hasOwnProperty(val)) {
        return !storePermissions[val];
      }
      return null;
    }
  };
  useEffect(() => {
    if (allRoles) {
      const transformedCategories = allRoles.map((role) => ({
        label: role.display_name,
        value: role.id,
      }));
      setRolesDetails(transformedCategories);
    }
  }, [allRoles]);
  useEffect(() => {
    if (allRoleCategories) {
      const transformedCategories = allRoleCategories.map((role) => ({
        label: role.display_name,
        value: role.id,
      }));
      setCategoriesDetails(transformedCategories);
    }
  }, [allRoleCategories]);
  useEffect(() => {
    if (selectedRole?.label) {
      const matchingCategories = allRoleCategories
        .filter((category) => category.jobRoleName === selectedRole.label)
        .map((category) => ({
          label: category.display_name,
          value: category.id,
        }));
      setMatchingCategories(matchingCategories);
    }
  }, [selectedRole?.label]);
  useEffect(() => {
    setColumns([
      { Header: "ID", accessor: "id", align: "left" },
      { Header: "Role Category", accessor: "role_category_id", align: "left" },
      { Header: "Question Type", accessor: "question_type_id", align: "left" },
      { Header: "Question", accessor: "question", align: "left" },
      { Header: "Action", accessor: "action", align: "center" },
    ]);

    const customAddQuestion =
      questions &&
      questions.length >= 1 &&
      questions.map((data, index) => {
        const questionCategory =
          questionsType?.find((role) => role.value === data.question_type_id)?.label ||
          "Unknown Role";
        const roleCategory =
          categoriesDetails?.find((category) => category.value === data.role_category_id)?.label ||
          "Unknown Role";
        return {
          id: (
            <MDTypography
              component="a"
              href="#"
              variant="button"
              color="text"
              fontWeight="medium"
              key={index + 100}
            >
              {data?.id}
            </MDTypography>
          ),
          role_category_id: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {roleCategory}
            </MDTypography>
          ),
          question_type_id: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {questionCategory}
            </MDTypography>
          ),
          question: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {data?.question}
            </MDTypography>
          ),
          action: (
            <>
              <Button
                variant="contained"
                color="primary"
                sx={{ marginRight: "10px" }}
                size="small"
                disabled={handleDisabled("update")}
                onClick={() => {
                  const matchingRoleCategory = categoriesDetails.find(
                    (category) => category.value === data.role_category_id
                  );
                  const matchingCategory = allRoleCategories.find(
                    (category) => category.id === data.role_category_id
                  );
                  const matchingRole = rolesDetails.find(
                    (role) => role.label === matchingCategory?.jobRoleName
                  );
                  const selectedRole = matchingRole;
                  handleEditModalOpen();
                  setSelectedQuestionId(data?.id);
                  setSelectedQuestion(data.question);
                  setSelectedRole(selectedRole);
                  setSelectedCategory(matchingRoleCategory);
                  setSelectedQuestionType(
                    questionsType.find((role) => role.value === data.question_type_id)
                  );
                }}
              >
                <Icon sx={{ color: "#fff" }}>edit</Icon>
              </Button>
              <Button
                variant="contained"
                sx={{ color: "#fff", bgcolor: "#ec4b48" }}
                size="small"
                onClick={() => {
                  handleConfirmationToggle();
                  setSelectedQuestionId(data?.id);
                }}
                disabled={handleDisabled("delete")}
              >
                <Icon sx={{ color: "#fff" }}>delete</Icon>
              </Button>
            </>
          ),
        };
      });

    setRows(Array.isArray(customAddQuestion) ? customAddQuestion : []);
  }, [questions, rolesDetails, categoriesDetails]);
  useEffect(() => {
    if (pagePermissions !== permission.admin && pagePermissions !== permission.questions) {
      const routeLink = getRouteByNumber(pagePermissions);
      return navigate(routeLink);
    }
    if (featurePermissions) {
      const data = featurePermissionsObject(featurePermissions);
      setStorePermissions(data);
      console.log(data, "featurePermissionsfeaturePermissions");
    }
    dispatch(getAllRole());
    dispatch(getAllRoleCategories());
  }, []);
  useEffect(() => {
    dispatch(getQuestions({ page: page, search: debouncedSearch }));
  }, [debouncedSearch]);
  return (
    <>
      {/* <DashboardLayout> */}
      <MDBox pt={3} pb={3}>
        {loading && <Loader />}
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {/* <Card> */}
            <MDBox pt={3}>
              <Box pt={1} px={2}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} lg={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="search">Search</InputLabel>
                      <OutlinedInput
                        id="search"
                        type="text"
                        sx={{
                          width: { lg: "50%", xs: "100%" },
                        }}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton aria-label="search" edge="end">
                              <SearchOutlined />
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Search"
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={6}
                    display="flex"
                    justifyContent={{ xs: "center", lg: "flex-end" }}
                    alignItems="center"
                  >
                    <Box>
                      <Button
                        variant="contained"
                        sx={{
                          color: "#fff",
                          bgcolor: "#ec4b48",
                          "&:hover": {
                            bgcolor: "darkslategray",
                          },
                          marginRight: "10px",
                        }}
                        onClick={() => {
                          if (selectedRows.length > 0) {
                            handleDeleteConfirmationToggle();
                          } else {
                            toast.error("Please select any question for deletion!");
                          }
                        }}
                        disabled={handleDisabled("delete")}
                      >
                        <Icon fontSize="small">delete_forever</Icon> Delete
                      </Button>

                      <Button
                        variant="contained"
                        sx={{
                          color: "#fff",
                          bgcolor: "#3b8335",
                          "&:hover": {
                            bgcolor: "darkslategray",
                          },
                        }}
                        disabled={handleDisabled("create")}
                        onClick={() => {
                          handleAddModalOpen();
                        }}
                      >
                        <Icon fontSize="small">add</Icon> New
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {questions && questions.length >= 1 ? (
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                  checkboxSelection
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  checkBoxes={true}
                />
              ) : (
                <MDTypography
                  component="h2"
                  href="#"
                  color="text"
                  fontWeight="bold"
                  sx={{
                    textAlign: "center",
                    my: 10,
                  }}
                >
                  No Questions Exists
                </MDTypography>
              )}
            </MDBox>
            <MDBox
              m={3}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pagination count={totalPages} onChange={handlePaginationChange} />
            </MDBox>
            {/* </Card> */}
          </Grid>
        </Grid>
      </MDBox>
      {/* </DashboardLayout> */}
      <Modal
        open={addModalOpen}
        onClose={() => setAddModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
          <Card sx={styleModal}>
            <Box
              variant="gradient"
              bgcolor="info.main"
              borderRadius="12px"
              boxShadow={3}
              mx={2}
              p={2}
              mb={1}
              textAlign="center"
            >
              <Typography variant="h4" fontWeight="medium" color="white.main" mt={1} mb={1}>
                Add Questions
              </Typography>
            </Box>
            <Box mx={1} p={1} mt={1}>
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                      <Tab label="Create Questions" value="1" />
                      <Tab label="Create by ChatGpt" value="2" />
                      <Tab label="Import Questions" value="3" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <SimpleQuestions
                      simpleQuestions={simpleQuestions}
                      setSimpleQuestions={setSimpleQuestions}
                      rolesDetails={rolesDetails}
                      categoriesDetails={allRoleCategories}
                      setAddModalOpen={setAddModalOpen}
                      debouncedSearch={debouncedSearch}
                      questionsType={questionsType}
                    />
                  </TabPanel>
                  <TabPanel value="2">
                    <ChatGptQuestions
                      chatgptQuestions={chatgptQuestions}
                      setChatgptQuestions={setChatgptQuestions}
                      rolesDetails={rolesDetails}
                      categoriesDetails={allRoleCategories}
                      setAddModalOpen={setAddModalOpen}
                      debouncedSearch={debouncedSearch}
                      questionsType={questionsType}
                    />
                  </TabPanel>
                  <TabPanel value="3">
                    <ImportQuestions
                      importQuestions={importQuestions}
                      setImportQuestions={setImportQuestions}
                      rolesDetails={rolesDetails}
                      categoriesDetails={allRoleCategories}
                      setAddModalOpen={setAddModalOpen}
                      debouncedSearch={debouncedSearch}
                      questionsType={questionsType}
                    />
                  </TabPanel>
                </TabContext>
              </Box>
            </Box>
          </Card>
        </Grid>
      </Modal>
      <Modal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
          <Card sx={styleModal}>
            <Box
              variant="gradient"
              bgcolor="info.main"
              borderRadius="12px"
              boxShadow={3}
              mx={2}
              p={2}
              mb={1}
              textAlign="center"
            >
              <Typography variant="h4" fontWeight="medium" color="white.main" mt={1} mb={1}>
                Edit Question
              </Typography>
            </Box>
            <Box mx={1} p={1} mt={1}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <Select
                    options={rolesDetails}
                    value={rolesDetails?.find((role) => role?.value === selectedRole?.value)}
                    onChange={(selectedOption) => handleRoleSelect(selectedOption)}
                    placeholder="Select Role"
                    isClearable
                    required
                  />
                  <div style={{ marginTop: "10px" }}>
                    <Select
                      options={matchingCategories}
                      value={
                        selectedCategory?.value
                          ? matchingCategories?.find(
                              (category) => category?.value === selectedCategory?.value
                            )
                          : null
                      }
                      onChange={(selectedOption) => handleCategorySelect(selectedOption)}
                      placeholder="Select Category"
                      isClearable
                      required
                    />
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <Select
                      options={questionsType}
                      value={questionsType?.find(
                        (category) => category?.value === selectedQuestionType?.value
                      )}
                      onChange={(selectedOption) => handleQuestionTypeSelect(selectedOption)}
                      placeholder="Select Category"
                      isClearable
                      required
                    />
                  </div>
                  <TextareaAutosize
                    minRows={3}
                    placeholder="Enter Question"
                    value={selectedQuestion}
                    onChange={(event) => setSelectedQuestion(event.target.value)}
                    style={{ width: "100%", marginTop: "10px", padding: "10px" }}
                    required
                  />
                </Box>
                <MDBox mt={4} mb={1} sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => setEditModalOpen(false)}
                    disabled={loading}
                  >
                    Cancel
                  </MDButton>
                  <MDButton variant="gradient" color="info" type="submit" disabled={loading}>
                    {loading ? "Updating" : "Update"}
                  </MDButton>
                </MDBox>
              </form>
            </Box>
          </Card>
        </Grid>
      </Modal>
      <ConfirmationModal
        modalOpen={confirmationModal}
        modalToggle={handleConfirmationToggle}
        onSubmit={handleDelete}
        loading={loading}
      />
      <ConfirmationModal
        modalOpen={deleteConfirmationModal}
        modalToggle={handleDeleteConfirmationToggle}
        onSubmit={handleAllDelete}
        loading={loading}
      />
    </>
  );
}
