import React, { useEffect, useState } from "react";
import blueTick from "../../assets/images/blueTick.svg";
import { useDispatch, useSelector } from "react-redux";
import { checkOutSession } from "store/Slices/subscriptionSlice";
import { toast } from "react-toastify";
import { firstTimeLogin } from "store/Slices/authSlice";
import { getSubscription } from "store/Slices/authSlice";
import { MonthlydummySubscriptionData, YearlydummySubscriptionData } from "./DummyData";
import DownGradeModal from "examples/Modal/DownGradeModal";
import loadingAnimation from "../../assets/animations/Animation - 1721988987502.gif";
import { CancelationActiveBundle } from "store/Slices/subscriptionSlice";
import { getStats } from "store/Slices/usersSlice";
import UpdateSubscritionModal from "examples/Modal/UpdateSubscritionModal";

const PricingCards = ({ plan, handleOpen, isCheckYearly, handleDisabled, userStats }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [modal, setModal] = useState(false);
  const [activePricingId, setActivePricingId] = useState(null);
  const [buttonStatusLoader, setbuttonStatusLoader] = useState(false);
  const [openModal, setopenModal] = useState(false);

  const activeBundleData = MonthlydummySubscriptionData.filter(
    (data) => data?.id === user?.activeBundle
  );

  const handleModal = () => {
    setModal(!modal);
  };

  const getCurrentSubscription = () => {
    setbuttonStatusLoader(true);
    dispatch(getSubscription())
      .unwrap()
      .then((res) => {
        setbuttonStatusLoader(false);
      })
      .catch((rejected) => {
        toast.error(rejected?.response?.data?.detail);
        setbuttonStatusLoader(false);
      });
  };

  const handlePlanActivate = (pricingId, selectedPlanPrice) => {
    setActivePricingId(pricingId);

    if (pricingId !== "4OYPQmPj9K" && activeBundleData[0]?.price > selectedPlanPrice) {
      handleModal();
    } else if (
      ["0qXPowPlVW", "QWdbKnDxoL", "QolEraPnrw"].includes(pricingId) &&
      user?.activeBundle === "4OYPQmPj9K"
    ) {
      handleModal();
    } else if (pricingId == "0qXPowPlVW" && user?.is_trial_consumed) {
      setopenModal(true);
    } else {
      // if (user?.cancelation) {
      //   dispatch(CancelationActiveBundle({ pricingId, quantity: userStats?.stripe_quantity }))
      //     .unwrap()
      //     .then((res) => {
      //       toast.success(res.detail);
      //       setTimeout(() => {
      //         window.location.href = res.redirectUrl;
      //       }, 1500);
      //     })
      //     .catch((res) => {
      //       toast.error(res?.response?.data?.detail);
      //     });
      // } else {
      const payload = {
        bundlePlanId: pricingId,
      };
      dispatch(checkOutSession(payload))
        .unwrap()
        .then((res) => {
          toast.success(res.message);
          if (!res.checkout_url) {
            getCurrentSubscription();
          } else {
            setTimeout(() => {
              window.location.href = res.checkout_url;
            }, 1500);
          }
        })
        .catch((res) => {
          toast.error(res?.response?.data?.detail);
        });
      // }
    }
  };

  return (
    <div className="pricing-plan-card">
      <div className="top-section">
        <h2>{plan.name}</h2>
        <p>{plan.title}</p>
      </div>
      <div className="description-section">
        <div className="price">
          {plan.price !== "Custom Pricing" && <p className="dollar">$</p>}
          {plan.price !== "Custom Pricing" ? <h2>{plan.price}</h2> : <h4>{plan.price}</h4>}
        </div>
        <div className="div-box">User/month</div>
        <div className="pricing-content">
          {plan.features.map((item, i) => (
            <div key={i} className="item">
              <div className="blue-tick-icon-wrapper">
                <img src={blueTick} alt="icon" />
              </div>
              <p>{item}</p>
            </div>
          ))}

          <div className="pricing-button">
            <>
              {plan?.idealFor && (
                <div className="mt-5 text-center">
                  <p className="idealFor">{plan.idealFor}</p>
                </div>
              )}
              <button
                className="ic-btn-go-to-pro ic-btn-primary"
                disabled={
                  handleDisabled("update") || user?.activeBundle == plan?.id || user?.cancelation
                }
                onClick={
                  plan?.id !== "enterprise"
                    ? () => handlePlanActivate(plan.id, plan?.price)
                    : undefined
                }
              >
                {buttonStatusLoader
                  ? "Loading..."
                  : isCheckYearly
                  ? plan?.id == "enterprise"
                    ? "Contact Us"
                    : user?.activeBundle == plan?.id
                    ? "Subscribed"
                    : user?.activeBundle != "4OYPQmPj9K"
                    ? "Upgrade Plan"
                    : "Downgrade Plan"
                  : plan?.id == "enterprise"
                  ? "Contact Us"
                  : user?.activeBundle == plan?.id
                  ? "Subscribed"
                  : plan?.price > activeBundleData[0]?.price
                  ? "Upgrade Plan"
                  : "Downgrade Plan"}
              </button>
            </>
          </div>
        </div>
      </div>
      <DownGradeModal
        modal={modal}
        handleModal={handleModal}
        activePricingId={activePricingId}
        userStats={userStats}
      />
      <UpdateSubscritionModal
        openModal={openModal}
        setopenModal={setopenModal}
        activePricingId={activePricingId}
        setbuttonStatusLoader={setbuttonStatusLoader}
      />
    </div>
  );
};

export default PricingCards;
