import { useLocation } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import logo from "assets/images/logos/header-logo-official.png";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { registerSubOrgSchema } from "components/schema";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Loader";
import { toast } from "react-toastify";
import { registerSubOrganization } from "store/Slices/authSlice";

const initialValues = {
  name: "",
  password: "",
  address: "",
  phone: "",
};

function InviteSubOrganization() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: initialValues,
    validationSchema: registerSubOrgSchema,
    onSubmit: (values) => {
      const payload = {
        token,
        name: values.name,
        password: values.password,
        address: values.address,
        phone: values.phone,
      };
      dispatch(
        registerSubOrganization({
          payload,
          onSuccess: (res) => {},
        })
      )
        .unwrap()
        .then((resolved) => {
          resetForm();
        })
        .catch((rejected) => {
          //   resetForm();
          toast.error(rejected?.response?.data?.detail);
        });
    },
  });

  return (
    <BasicLayout image={bgImage}>
      {loading && <Loader />}
      <Card>
        <MDBox pt={2} px={3}>
          <MDBox mx={2} mt={1} p={2} mb={1} textAlign="center">
            <img src={logo} alt="Logo" />
          </MDBox>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Full Name"
                name="name"
                fullWidth
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.name && touched.name ? (
                <MDTypography color="error" sx={{ fontSize: "13px" }}>
                  {<p>{errors.name}</p>}
                </MDTypography>
              ) : null}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                name="password"
                fullWidth
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.password && touched.password ? (
                <MDTypography color="error" sx={{ fontSize: "13px" }}>
                  {<p>{errors.password}</p>}
                </MDTypography>
              ) : null}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Address"
                name="address"
                fullWidth
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.address && touched.address ? (
                <MDTypography color="error" sx={{ fontSize: "13px" }}>
                  {<p>{errors.address}</p>}
                </MDTypography>
              ) : null}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Phone"
                name="phone"
                fullWidth
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.phone && touched.phone ? (
                <MDTypography color="error" sx={{ fontSize: "13px" }}>
                  {<p>{errors.phone}</p>}
                </MDTypography>
              ) : null}
            </MDBox>

            <MDBox mt={2} mb={2}>
              <MDButton variant="gradient" color="info" type="submit" fullWidth>
                Register
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default InviteSubOrganization;
