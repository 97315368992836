import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReports } from "store/Slices/ReportAnalyticsSlice";
import Analytics from "./Components/Analytics";
import RecentReportings from "./Components/RecentReportings";
import ReportsHeader from "./Components/ReportsHeader";
import { getUsers } from "store/Slices/usersSlice";
import { toast } from "react-toastify";
import { getRouteByNumber } from "context";
import { useNavigate } from "react-router-dom";
import { permission } from "context";

const ReportsAnalytics = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [parentActiveState, setParentActiveState] = useState(0);
  const parsedObject = JSON.parse(user?.rolePermissions);
  let pagePermissions;
  let featurePermissions;
  if (parsedObject) {
    const [key, value] = Object.entries(parsedObject)[0];
    pagePermissions = key;
    featurePermissions = value;
  }
  const getUserFunction = () => {
    dispatch(
      getUsers({
        onSuccess: () => {},
      })
    )
      .unwrap()
      .then((res) => {})
      .catch((rejected) => toast.error(rejected?.response?.data?.detail));
  };

  useEffect(() => {
    if (
      pagePermissions !== permission.admin &&
      pagePermissions !== permission.reportingAndAnalytics
    ) {
      const routeLink = getRouteByNumber(pagePermissions);
      return navigate(routeLink);
    }
    getUserFunction();
  }, []);

  return (
    <DashboardLayout>
      <ReportsHeader
        parentActiveState={parentActiveState}
        setParentActiveState={setParentActiveState}
        title="Reports & Analytics"
      >
        {parentActiveState == 0 ? (
          <>
            <RecentReportings featurePermissions={featurePermissions} />
          </>
        ) : (
          <>
            {" "}
            <MDBox pt={6} pb={3}>
              <Analytics featurePermissions={featurePermissions} />
            </MDBox>
          </>
        )}
      </ReportsHeader>
    </DashboardLayout>
  );
};

export default ReportsAnalytics;
