import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DataTable from "examples/Tables/DataTable";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createReport } from "store/Slices/ReportAnalyticsSlice";
import moment from "moment";
import { getReports } from "store/Slices/ReportAnalyticsSlice";
import Loader from "components/Loader";
import { deleteSingleReport } from "store/Slices/ReportAnalyticsSlice";
import { toast } from "react-toastify";
import { getJoinedUsers } from "store/Slices/usersSlice";
import { featurePermissionsObject } from "context";

const RecentReportings = ({ featurePermissions }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { reports, loading } = useSelector((state) => state.ReportAnalytics);
  const { joinedUsers } = useSelector((state) => state.userManagment);

  const [modalOpen, setModalOpen] = useState(false);
  const [storePermissions, setStorePermissions] = useState(null);
  const [startValue, setStartValue] = useState(dayjs(new Date()).subtract(1, "day"));
  const [endValue, setEndValue] = useState(dayjs(new Date()));

  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [selectedUsers, setselectedUsers] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  const handleDisabled = (val) => {
    if (storePermissions) {
      if (storePermissions.hasOwnProperty(val)) {
        return !storePermissions[val];
      }
      return null;
    }
  };

  const handleOpen = () => {
    setModalOpen(true);
  };
  const formatDate = (isoDate) => {
    const date = new Date(isoDate);

    // Extract date components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    // Format as mm-dd-yyyy
    return `${year}-${month}-${day}`;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const fromDate = new Date(startValue);
    const toDate = new Date(endValue);
    if (fromDate <= toDate) {
      let payload = {
        name: name,
        type: type,
        toDate: formatDate(endValue),
        fromDate: formatDate(startValue),
      };
      if (type !== "top_interview_simulations" && type !== "top_practice_rounds") {
        payload.customer_ids = selectedUsers;
      }
      dispatch(
        createReport({
          payload,
          onSuccess: () => {
            toast.success("Report created successfully");
            setName("");
            setType("");
            setStartValue(dayjs(new Date()).subtract(1, "day"));
            setEndValue(dayjs(new Date()));
            setselectedUsers([]);
            setModalOpen(false);
            dispatch(getReports());
          },
        })
      );
    } else {
      toast.error("The start date must be before or equal to the end date.");
    }
  };

  useEffect(() => {
    setColumns([
      { Header: "Report Name", accessor: "name", align: "left" },
      { Header: "Type", accessor: "type", align: "center" },
      { Header: "Created At", accessor: "createdAt", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ]);
    // add user array
    const reportsArray =
      reports &&
      reports?.length >= 1 &&
      reports?.map((data, index) => {
        return {
          name: (
            <MDTypography
              component="a"
              href="#"
              variant="button"
              color="text"
              fontWeight="medium"
              key={index + 100}
            >
              {data?.name}
            </MDTypography>
          ),
          type: (
            <Typography
              component="a"
              href="#"
              variant="caption"
              color="textPrimary"
              fontWeight="medium"
            >
              <Box
                component="span"
                sx={{
                  ...pillSx,
                  backgroundColor: data?.status === "completed" ? "green" : "red",
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                {/* I want to check if it contain "_" like "top_practice_rounds" then I want to show
                "Top Practice Rounds" if it did not contain "_" then show as it is */}
                {data?.type.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())}
              </Box>
            </Typography>
          ),
          createdAt: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {moment(data?.created_at).format("DD MMM YYYY")}
            </MDTypography>
          ),

          action: (
            <MDBox sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <>
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    ml: 1.3,
                    color: "#fff",
                    bgcolor: "info",
                    "&:hover": {
                      bgcolor: "darkslategray",
                    },
                  }}
                  onClick={() => navigate(`/view-report/${data?.id}_${data?.name}`)}
                >
                  View Report
                </Button>

                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    ml: 1.3,
                    color: "#fff",
                    bgcolor: "black",
                    "&:hover": {
                      bgcolor: "darkslategray",
                    },
                  }}
                  disabled={handleDisabled("delete")}
                  onClick={() =>
                    dispatch(deleteSingleReport(data?.id))
                      .unwrap()
                      .then((res) => {
                        toast.success("Report deleted successfully");
                        dispatch(getReports());
                      })
                  }
                >
                  <Icon color="#fff" sx={{ fontSize: "20px" }}>
                    delete
                  </Icon>
                </Button>
              </>
            </MDBox>
          ),
        };
      });
    setRows(Array.isArray(reportsArray) ? reportsArray : []);
  }, [reports]);
  const pillSx = {
    display: "inline-block",
    padding: "0.2em 0.6em",
    borderRadius: "9999px",
    fontSize: "0.75rem",
    fontWeight: 500,
    textAlign: "center",
    color: "white",
  };

  const styleModal = {
    width: "90vw", // use a percentage width for responsiveness
    maxWidth: "500px", // set a max width to prevent it from getting too large
    margin: "auto", // center the modal
    padding: "20px", // add some padding
    boxShadow: 24,
    borderRadius: "8px",
  };

  const handleStartDateChange = (newValue) => {
    setStartValue(newValue);
  };

  const handleEndDateChange = (newValue) => {
    setEndValue(newValue);
  };

  useEffect(() => {
    if (featurePermissions) {
      const data = featurePermissionsObject(featurePermissions);
      setStorePermissions(data);
      console.log(data, "featurePermissionsfeaturePermissions");
    }
    dispatch(getJoinedUsers());
    dispatch(getReports());
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <MDBox
            mx={2}
            mt={5}
            mb={5}
            py={2}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MDTypography variant="h6" color="white">
              Reports
            </MDTypography>
            <Box>
              <Button
                variant="contained"
                sx={{
                  color: "#fff",
                  bgcolor: "#000",
                  "&:hover": {
                    bgcolor: "darkslategray",
                  },
                }}
                disabled={handleDisabled("create")}
                onClick={handleOpen}
              >
                Generate Report
              </Button>
            </Box>
          </MDBox>
          <DataTable
            table={{ columns, rows }}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder
            checkBoxes={false}
          />
        </Grid>
      </Grid>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
          <Card sx={styleModal}>
            <Box
              variant="gradient"
              bgcolor="info.main"
              borderRadius="12px"
              boxShadow={3}
              mx={2}
              p={2}
              mb={2}
              textAlign="center"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mb={1}>
                Generate Report
              </MDTypography>
            </Box>
            <MDBox mx={1} p={1} mt={1}>
              <form onSubmit={handleSubmit}>
                <MDBox mb={2}>
                  <Box>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Report Type *</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="account"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        // onBlur={handleBlur}
                        label="Users *"
                        required
                      >
                        <MenuItem value="training">Practice Rounds</MenuItem>
                        <MenuItem value="simulation">Interview Simulations</MenuItem>
                        <MenuItem value="resume">Resumes</MenuItem>
                        <MenuItem value="top_interview_simulations">
                          Top interview simulations
                        </MenuItem>
                        <MenuItem value="top_practice_rounds">Top practice rounds</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </MDBox>

                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Name"
                    name="name"
                    value={name}
                    required
                    onChange={(e) => setName(e.target.value)}
                    // onBlur={handleBlur}
                    fullWidth
                  />
                  {/* {errors.name && touched.name ? (
                  <MDTypography color="error" sx={{ fontSize: "13px" }}>
                    {<p>{errors.name}</p>}
                  </MDTypography>
                ) : null} */}
                </MDBox>

                {type !== "top_interview_simulations" && type !== "top_practice_rounds" && (
                  <MDBox mb={2}>
                    <Box>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label-users">Users *</InputLabel>
                        <Select
                          labelId="demo-simple-select-label-users"
                          id="demo-simple-select"
                          name="account"
                          multiple
                          value={selectedUsers}
                          onChange={(event) => {
                            const {
                              target: { value },
                            } = event;
                            setselectedUsers(typeof value === "string" ? value.split(",") : value);
                          }}
                          // onBlur={handleBlur}
                          label="Users *"
                          sx={{ py: 1.5 }}
                          required
                        >
                          {joinedUsers?.map((user) => {
                            return (
                              <MenuItem value={user?.customer_id}>{user?.customer_name}</MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  </MDBox>
                )}

                <MDBox mb={2}>
                  <Box>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Report Type *</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="account"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        // onBlur={handleBlur}
                        label="Report Type *"
                        required
                      >
                        <MenuItem value="training">Practice Rounds</MenuItem>
                        <MenuItem value="simulation">Interview Simulations</MenuItem>
                        <MenuItem value="resume">Resumes</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </MDBox>

                <MDBox mt={4} mb={1} sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
                  <MDButton variant="gradient" color="info" onClick={() => setModalOpen(false)}>
                    Cancel
                  </MDButton>
                  <MDButton variant="gradient" color="info" type="submit">
                    Generate Report
                  </MDButton>
                </MDBox>
              </form>
            </MDBox>
          </Card>
        </Grid>
      </Modal>
    </>
  );
};

export default RecentReportings;
