import { Button, Pagination } from "@mui/material";
import Loader from "components/Loader";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FeedbackModal from "examples/Modal/FeedbackModal";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getPRoundFeedback } from "store/Slices/usersSlice";
import { getUserPRounds } from "store/Slices/usersSlice";

const UserPRounds = ({ storePermissions }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const spiltID = id?.split("_")?.[0];
  const { practiceRoundsList, loading, pRoundPagination } = useSelector((state) => state.users);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [interviewFeedback, setInterviewFeedback] = useState(null);
  const [feedbackLoader, setFeedbackLoader] = useState(false);
  const handleClose = () => setFeedbackModal(false);

  const handleDisabled = (val) => {
    if (storePermissions) {
      if (storePermissions.hasOwnProperty(val)) {
        return !storePermissions[val];
      }
      return null;
    }
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return date.toLocaleString("en-US", options).replace(",", "");
  };

  useEffect(() => {
    setColumns([
      { Header: "Date of Interview", accessor: "created_on", align: "left" },
      { Header: "Category", accessor: "role_category", align: "center" },
      { Header: "Feedback", accessor: "action", align: "center" },
    ]);
    // add user array
    const customAddUser =
      practiceRoundsList &&
      practiceRoundsList.length >= 1 &&
      practiceRoundsList.map((data, index) => {
        return {
          created_on: (
            <MDTypography
              component="a"
              href="#"
              variant="button"
              color="text"
              fontWeight="medium"
              key={index + 100}
            >
              {formatTimestamp(data?.created_on)}
            </MDTypography>
          ),
          role_category: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {data?.role_category}
            </MDTypography>
          ),

          action: (
            <MDBox sx={{ display: "flex", justifyContent: "start", alignItems: "start" }}>
              <Button
                variant="contained"
                size="small"
                disabled={handleDisabled("update") || loading || !data.feedback_status}
                sx={{
                  ml: 1.3,
                  color: "#fff",
                  bgcolor: "info",
                  "&:hover": {
                    bgcolor: "darkslategray",
                  },
                }}
                onClick={() => handleFeedback(data.id, spiltID)}
              >
                Get Feedback
              </Button>
            </MDBox>
          ),
        };
      });
    setRows(Array.isArray(customAddUser) ? customAddUser : []);
  }, [practiceRoundsList]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    dispatch(getUserPRounds({ spiltID, page }));
  }, [spiltID, page]);

  const handleFeedback = (id, customer_id) => {
    let payload = {
      id,
      customer_id,
    };
    setFeedbackLoader(true);
    dispatch(getPRoundFeedback(payload))
      .unwrap()
      .then((res) => {
        setInterviewFeedback(res);
        setFeedbackLoader(false);
        setFeedbackModal(true);
      })
      .catch((err) => {
        setFeedbackLoader(false);
        toast.error(err.response?.data?.detail);
      });
  };

  if (loading || feedbackLoader) {
    return <Loader />;
  }

  return (
    <>
      <MDBox pt={3}>
        {practiceRoundsList && practiceRoundsList.length >= 1 ? (
          <DataTable
            table={{ columns, rows }}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder
            checkBoxes={false}
          />
        ) : (
          <MDTypography
            component="h2"
            href="#"
            color="text"
            fontWeight="bold"
            sx={{
              textAlign: "center",
              my: 10,
            }}
          >
            This user has no Practice rounds
          </MDTypography>
        )}
        <MDBox
          m={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Pagination
            count={pRoundPagination?.total_pages}
            page={page}
            onChange={handlePageChange}
          />
        </MDBox>
      </MDBox>
      <FeedbackModal
        modal={feedbackModal}
        handleClose={handleClose}
        interviewFeedback={interviewFeedback}
      />
    </>
  );
};

export default UserPRounds;
