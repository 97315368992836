/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";

import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import BasicLayout from "layouts/authentication/components/BasicLayout";

import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { signInSchema } from "components/schema";
import { loginOrganization } from "store/Slices/authSlice";
import { token } from "stylis";
import { resetPasswordAction } from "store/Slices/authSlice";
import { sendResetLinkAction } from "store/Slices/authSlice";
import { toast } from "react-toastify";
const initialValues = {
  email: "",
  password: "",
  cPassword: "",
};

function Basic() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [authToken, setAuthToken] = useState("");

  useEffect(() => {
    if (id) {
      const tokenPart = id.split("token=")[1];
      const params = tokenPart.split("&email=");
      setAuthToken(params[0]);
      setEmail(params[1]);

      // console.log(params[0], "token", params[1], "email");
    }
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let payload;
    if (id) {
      payload = {
        token: authToken,
        email,
        newPass: password,
        confirmNewPass: cPassword,
      };
      dispatch(resetPasswordAction(payload))
        .unwrap()
        .then((resolved) => {
          setEmail("");
          setPassword("");
          setCPassword("");
        })
        .catch((rejected) => toast.error(rejected?.response?.data?.detail));
    } else {
      payload = {
        email,
      };
      dispatch(sendResetLinkAction(payload))
        .unwrap()
        .then((resolved) => {
          // console.log(resolved);
          setEmail("");
        })
        .catch((rejected) => toast.error(rejected?.response?.data?.detail));
    }

    // dispatch(loginOrganization({ email, password }))
    // .unwrap()
    // .then((resolved) => {
    //   // console.log(resolved);
    //   navigate("/dashboard");
    // });
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                name="email"
                disabled={id ? true : false}
                fullWidth
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </MDBox>
            {id && (
              <>
                <MDBox mb={2}>
                  <MDInput
                    type="password"
                    label="Password"
                    name="password"
                    fullWidth
                    value={password}
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="password"
                    label="Confirm Password"
                    name="cPassword"
                    fullWidth
                    value={cPassword}
                    required
                    onChange={(e) => setCPassword(e.target.value)}
                  />
                </MDBox>
              </>
            )}
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                {id ? "Reset Password" : "Send Email"}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
