export const MonthlydummySubscriptionData = [
  {
    id: "QolEraPnrw",
    title: "Free Tier",
    name: "Nexa Explorer",
    price: "0.00",
    features: [
      "Basic resume builder",
      "5  interviews simulations per month",
      "5 organization user limit",
    ],
  },
  // {
  //   id: "QWdbKnDxoL",
  //   title: "Basic Tier",
  //   name: "Nexa Learner",
  //   price: "10.00",
  //   idealFor:
  //     "Ideal For New Training organizations that want to give their  job seekers unlimited interview practice and resume building",
  //   features: [
  //     "AI resume builder",
  //     "Unlimited interview simulations",
  //     "For Training Centers",
  //     "Manage learner progress with reporting and analytics in realtime",
  //   ],
  // },
  {
    id: "0qXPowPlVW",
    title: "Growth Tier",
    name: "Nexa Career",
    price: "20.00",
    idealFor:
      "Ideal For organizations looking to provide their job seekers full access to all career preparation tools",
    features: [
      "Everything in Learner +",
      "Learners unlock real time labour market analytics in their job search.",
      "Unlimited Resume Feedback",
      "Cover letter builder",
      "Skills gap analyzer",
      "Unlimited practice rounds",
      "Unlimited Mock Interviews",
      "Job application automation",
      "Nexa Voyce coaching",
    ],
  },
  {
    id: "enterprise",
    title: "Enterprise Tier",
    name: "Nexa Scale",
    price: "Custom Pricing",
    idealFor:
      "Ideal For Educational institutions and large organizations requiring tailored solutions.",
    features: [
      "Everything in Career +",
      "Nexa Voyce coaching",
      "Custom integrations",
      "White-labeling",
      "Advanced administrative controls",
      "Dedicated support",
      "Scalability for institutional needs",
    ],
  },
];

export const YearlydummySubscriptionData = [
  {
    id: "4OYPQmPj9K",
    title: "Growth Tier",
    name: "Nexa Learner",
    price: "$14.99",
    idealFor:
      "Ideal For organizations looking to provide their job seekers full access to all career preparation tools",
    features: [
      "Everything in Learner +",
      "Learners unlock real time labour market analytics in their job search.",
      "Unlimited Resume Feedback",
      "Cover letter builder",
      "Skills gap analyzer",
      "Unlimited practice rounds",
      "Unlimited Mock Interviews",
      "Job application automation",
      "Nexa Voyce coaching",
    ],
  },
  {
    id: "enterprise",
    title: "Enterprise Tier",
    name: "Nexa Scale",
    price: "Custom Pricing",
    idealFor:
      "Ideal For Educational institutions and large organizations requiring tailored solutions.",
    features: [
      "Everything in Career +",
      "Nexa Voyce coaching",
      "Custom integrations",
      "White-labeling",
      "Advanced administrative controls",
      "Dedicated support",
      "Scalability for institutional needs",
    ],
  },
];

export function convertTimestampToReadableDate(timestamp) {
  // Convert the timestamp from seconds to milliseconds
  const date = new Date(timestamp * 1000);

  // Format the date and time
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
