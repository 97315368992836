import React, { useState, useEffect } from "react";
import DataTable from "examples/Tables/DataTable";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { Link } from "react-router-dom";
import Icon from "@mui/material/Icon"; // Ensure you import Icon
import MDButton from "components/MDButton";

const styleModal = {
  width: "90%", // use a percentage width for responsiveness
  margin: "auto", // center the modal
  padding: "20px", // add some padding
  boxShadow: 24,
  borderRadius: "8px",
};

const InvitesTable = ({ inviteData, handleClose }) => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [Data, setData] = useState([]);

  // let inviteData = {
  //   already_registered: [],
  //   refresh_invites: [],
  //   invited_by_other_org: ["ranjha@smith.info", "waseemaaa@gmail.com"],
  //   new_added_emails: [],
  // };

  useEffect(() => {
    if (inviteData) {
      if (inviteData.already_registered?.length > 0) {
        inviteData?.already_registered?.map((item) => {
          let newRow = {
            email: item,
            reason: "Already Registered",
            status: "rejected",
          };
          setData((prev) => [...prev, newRow]);
        });
      }
      if (inviteData.refresh_invites?.length > 0) {
        inviteData?.refresh_invites?.map((item) => {
          let newRow = {
            email: item,
            reason: "Resent Invite",
            status: "accepted",
          };
          setData((prev) => [...prev, newRow]);
        });
      }
      if (inviteData.new_added_emails?.length > 0) {
        inviteData?.new_added_emails?.map((item) => {
          let newRow = {
            email: item,
            reason: "Invite Sent Successfully",
            status: "accepted",
          };
          setData((prev) => [...prev, newRow]);
        });
      }
      if (inviteData.invited_by_other_org?.length > 0) {
        inviteData?.invited_by_other_org?.map((item) => {
          let newRow = {
            email: item,
            reason: "Already Added by another Organization",
            status: "rejected",
          };
          setData((prev) => [...prev, newRow]);
        });
      }
    }
  }, [inviteData]);

  useEffect(() => {
    setColumns([
      { Header: "Email", accessor: "email", align: "left" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "Reason", accessor: "reason", align: "left" },
    ]);

    const customAddUser = Data.map((data, index) => {
      return {
        email: (
          <MDTypography
            component="a"
            href="#"
            variant="button"
            color="text"
            fontWeight="medium"
            key={index + 100}
          >
            {data.email}
          </MDTypography>
        ),
        status: (
          <Icon sx={{ fontSize: "20px", color: data?.status == "rejected" ? "red" : "green" }}>
            {data?.status == "rejected" ? "close" : "check"}
          </Icon>
        ),
        reason: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {data.reason}
          </MDTypography>
        ),
      };
    });

    setRows(customAddUser);
  }, [Data]);

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
      <Card sx={styleModal}>
        <DataTable
          table={{ columns, rows }}
          isSorted={false}
          entriesPerPage={false}
          showTotalEntries={false}
          noEndBorder
          checkBoxes={false}
        />
        <Box mt={4} mb={1} sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
          <MDButton variant="gradient" color="info" onClick={handleClose}>
            Cancel
          </MDButton>
        </Box>
      </Card>
    </Grid>
  );
};

export default InvitesTable;
