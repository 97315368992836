import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import Loader from "components/Loader";
import ProfileConfigration from "components/ProfileConfigration";
import Billings from "layouts/billings";
import Payments from "layouts/payments";
import ProfileForm from "layouts/profile/ProfileForm";
import UpdatePassForm from "layouts/profile/UpdatePassForm";
import Header from "layouts/profile/components/Header";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SettingHeader from "./components/Header";
import { getRouteByNumber } from "context";
import { permission } from "context";
import { featurePermissionsObject } from "context";

const Setting = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.orgProfile);
  const [parentActiveState, setParentActiveState] = useState(0);
  const [activeState, setactiveState] = useState(0);
  const [profilePayload, setprofilePayload] = useState(null);
  const [udpatePassPayload, setUpdatePassPayload] = useState(null);
  const [storePermissions, setStorePermissions] = useState(null);
  const parsedObject = JSON.parse(user?.rolePermissions);
  let pagePermissions;
  let featurePermissions;
  if (parsedObject) {
    const [key, value] = Object.entries(parsedObject)[0];
    pagePermissions = key;
    featurePermissions = value;
  }

  useEffect(() => {
    if (user?.activeBundle == null) {
      toast.error("Please Active your Bundle");
      return navigate("/subscription");
    }
    if (pagePermissions !== permission.admin && pagePermissions !== permission.settings) {
      const routeLink = getRouteByNumber(pagePermissions);
      return navigate(routeLink);
    }
    if (featurePermissions) {
      const data = featurePermissionsObject(featurePermissions);
      setStorePermissions(data);
      console.log(data, "featurePermissionsfeaturePermissions");
    }
  }, []);

  return (
    <DashboardLayout>
      {loading && <Loader />}
      <SettingHeader setParentActiveState={setParentActiveState}>
        {parentActiveState == 0 && (
          <>
            <MDBox mb={2} />
            <Header setactiveState={setactiveState}>
              <MDBox mt={5} mb={3}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    {activeState == 0 ? (
                      <ProfileForm
                        setprofilePayload={setprofilePayload}
                        storePermissions={storePermissions}
                      />
                    ) : (
                      <UpdatePassForm
                        setUpdatePassPayload={setUpdatePassPayload}
                        storePermissions={storePermissions}
                      />
                    )}
                  </Grid>
                </Grid>
              </MDBox>
            </Header>
          </>
        )}

        {parentActiveState == 1 && (
          <>
            <Payments storePermissions={storePermissions} />
          </>
        )}

        {parentActiveState == 2 && (
          <>
            <Billings storePermissions={storePermissions} />
          </>
        )}

        {parentActiveState == 3 && (
          <>
            <ProfileConfigration storePermissions={storePermissions} />
          </>
        )}
      </SettingHeader>
    </DashboardLayout>
  );
};

export default Setting;
