import { Box, Button, Card, Grid, Pagination, Typography } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "components/Loader";
import { Link, useNavigate } from "react-router-dom";
import { getRouteByNumber } from "context";
import { permission } from "context";
import DataTable from "examples/Tables/DataTable";
import moment from "moment";
import { getAllSkillGapAnalysis } from "store/Slices/BoardingSlice";
import { deleteSkillGapAnalysis } from "store/Slices/BoardingSlice";
import ConfirmationModal from "../../components/ConfirmationModal";
import { getSingleSkillGap } from "store/Slices/BoardingSlice";
import SkillGapModal from "components/SkillGapModal";
import { getJoinedUsers } from "store/Slices/usersSlice";

const SkillsGap = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [columns, setColumns] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [rows, setRows] = useState([]);
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const { skillGapData, loading, totalPages } = useSelector((state) => state.boarding);
  const { joinedUsers } = useSelector((state) => state.users);
  const [storeSelectedId, setStoreSelectedId] = useState(null);
  const [storeActivePagination, setStoreActivePagination] = useState(null);
  const [role, setRole] = useState("");
  const { user } = useSelector((state) => state.auth);
  const parsedObject = JSON.parse(user?.rolePermissions);
  let pagePermissions;
  let featurePermissions;
  if (parsedObject) {
    const [key, value] = Object.entries(parsedObject)[0];
    pagePermissions = key;
    featurePermissions = value;
  }

  const handleOpenAddModal = () => {
    setOpenAddModal(!openAddModal);
  };

  const handlePaginationChange = (event, value) => {
    setStoreActivePagination(value);
    dispatch(getAllSkillGapAnalysis({ payload: value }));
  };

  const handleDeleteConfirmationToggle = () => {
    setDeleteConfirmationModal((prevState) => !prevState);
  };

  const handleAllDelete = () => {
    dispatch(
      deleteSkillGapAnalysis({
        payload: storeSelectedId,
        onSuccess: () => {
          dispatch(getAllSkillGapAnalysis({ payload: storeActivePagination || 1 }));
          toast.success("Skill Gap Deleted Sucessfully");
        },
      })
    );
    handleDeleteConfirmationToggle();
  };

  useEffect(() => {
    setColumns([
      { Header: "Name", accessor: "name", align: "left" },
      { Header: "Created At", accessor: "createdAt", align: "center" },
      { Header: "Customer Id", accessor: "customer_id", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ]);
    // add user array
    const customAddAdmin =
      skillGapData &&
      skillGapData.length >= 1 &&
      skillGapData?.map((data, index) => {
        return {
          name: (
            <MDTypography
              component="a"
              href="#"
              variant="button"
              color="text"
              fontWeight="medium"
              key={index + 100}
            >
              {data?.name}
            </MDTypography>
          ),
          createdAt: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {moment(data?.created_on).format("DD MMM YYYY")}
            </MDTypography>
          ),
          customer_id: (
            <Typography
              component="a"
              href="#"
              variant="caption"
              color="textPrimary"
              fontWeight="medium"
            >
              {(() => {
                const user = joinedUsers.find((user) => user.customer_id === data?.customer_id);
                return user ? user.customer_name : "N/A";
              })()}
            </Typography>
          ),
          action: (
            <MDBox sx={{ display: "flex", justifyContent: "start", alignItems: "start" }}>
              <Link to={`/skills-gap-analysis-details/${data?.id}_${data?.name}`}>
                <Button
                  variant="contained"
                  size="small"
                  disabled={loading}
                  sx={{
                    ml: 1.3,
                    color: "#fff",
                    bgcolor: "#388e3c",
                    "&:hover": {
                      bgcolor: "darkslategray",
                    },
                  }}
                >
                  More Details
                </Button>
              </Link>
              <Button
                onClick={() => {
                  setStoreSelectedId(data?.id);
                  dispatch(
                    getSingleSkillGap({
                      payload: data?.id,
                      onSuccess: () => {
                        handleOpenAddModal();
                      },
                    })
                  );
                  setRole("update");
                }}
                variant="contained"
                size="small"
                disabled={loading}
                sx={{
                  ml: 1.3,
                  color: "#fff",
                  bgcolor: "info",
                  "&:hover": {
                    bgcolor: "darkslategray",
                  },
                }}
              >
                Update
              </Button>
              <Button
                // onClick={(e) => {
                //   handleOpenUpdateModal(data?.email);
                // }}
                variant="contained"
                size="small"
                disabled={loading}
                sx={{
                  ml: 1.3,
                  color: "#fff",
                  bgcolor: "#ec4b48",
                  "&:hover": {
                    bgcolor: "darkslategray",
                  },
                }}
                onClick={() => {
                  setStoreSelectedId(data?.id);
                  handleDeleteConfirmationToggle();
                }}
              >
                Delete
              </Button>
            </MDBox>
          ),
        };
      });
    setRows(Array.isArray(customAddAdmin) ? customAddAdmin : []);
  }, [skillGapData]);

  useEffect(() => {
    if (pagePermissions !== permission.admin && pagePermissions !== permission.skillsGapAnalysis) {
      const routeLink = getRouteByNumber(pagePermissions);
      return navigate(routeLink);
    }
    dispatch(getAllSkillGapAnalysis({ payload: 1 }));
  }, []);

  useEffect(() => {
    dispatch(getJoinedUsers());
  }, []);

  return (
    <DashboardLayout>
      {loading && <Loader />}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h5" color="white">
                  Skill Gap Analysis
                </MDTypography>
                <Box>
                  <Button
                    variant="contained"
                    sx={{
                      color: "#fff",
                      bgcolor: "#000",
                      "&:hover": {
                        bgcolor: "darkslategray",
                      },
                    }}
                    onClick={() => {
                      handleOpenAddModal();
                      setRole("create");
                    }}
                  >
                    Add New
                  </Button>
                </Box>{" "}
              </MDBox>

              <MDBox pt={3}>
                {skillGapData && skillGapData?.length >= 1 ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                    checkBoxes={false}
                  />
                ) : (
                  <MDTypography
                    component="h2"
                    href="#"
                    color="text"
                    fontWeight="bold"
                    sx={{
                      textAlign: "center",
                      my: 10,
                    }}
                  >
                    YOU DON'T HAVE ANY Skills Gap Analysis
                  </MDTypography>
                )}
              </MDBox>
              <MDBox
                m={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pagination count={totalPages} onChange={handlePaginationChange} />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <SkillGapModal
        isOpen={openAddModal}
        onClose={handleOpenAddModal}
        role={role}
        storeSelectedId={storeSelectedId}
        storeActivePagination={storeActivePagination}
      />
      <ConfirmationModal
        modalOpen={deleteConfirmationModal}
        modalToggle={handleDeleteConfirmationToggle}
        onSubmit={handleAllDelete}
        loading={loading}
        infoText="Deleting a category will also delete all its associated questions."
      />
    </DashboardLayout>
  );
};

export default SkillsGap;
