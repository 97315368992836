import Repository from "./Repository";
const CREATE_NEW_TASK = "/organization/task/create";
const GET_ALL_TASKS = "/organization/task/list";
const DELETE_TASK = "/organization/task/delete/";
const GET_SINGLE_TASK = "/organization/task/";
const UPDATE_TASK = "/organization/task/update/";
const ADD_ASSIGN_USER = "/organization/task/assign";
const REMOVE_ASSIGN_USER = "/organization/task/unassign";
const ADD_FEEBACK = "/organization/task/feedback/";
const TaskRepository = {
  createNewTask(payload) {
    const formData = new FormData();
    formData.append("title", payload.title);
    formData.append("description", payload.description);
    formData.append("due_date", payload.duedate);
    formData.append("priority", payload.priority);
    if (payload.task_file) {
      formData.append("task_file", payload.task_file);
    }
    formData.append("customer_ids", payload.assignto);
    formData.append("link", payload.link);
    console.log(payload, "payloadpayload");
    return Repository.post(`${CREATE_NEW_TASK}`, formData);
  },
  getAllTasks(payload) {
    return Repository.get(`${GET_ALL_TASKS}?page_size=10&page=${payload}`);
  },
  deleteTask(payload) {
    return Repository.delete(`${DELETE_TASK}${payload}`);
  },
  getSingleTask(payload) {
    return Repository.get(`${GET_SINGLE_TASK}${payload}`);
  },
  udpateTask(payload) {
    const formData = new FormData();
    formData.append("title", payload.title);
    formData.append("description", payload.description);
    formData.append("due_date", payload.duedate);
    formData.append("priority", payload.priority);
    if (payload.task_file && payload.task_file instanceof File) {
      formData.append("task_file", payload.task_file);
    }
    formData.append("customer_ids", payload.assignto);
    formData.append("link", payload.link);
    console.log(payload, "payloadpayload");
    return Repository.put(`${UPDATE_TASK}${payload?.id}`, formData);
  },
  AddAssignUser(payload) {
    return Repository.post(`${ADD_ASSIGN_USER}`, payload);
  },
  removeAssignUser(payload) {
    return Repository.post(`${REMOVE_ASSIGN_USER}`, payload);
  },
  addTaskFeedback(payload, paramId) {
    return Repository.post(`${ADD_FEEBACK}${paramId}`, payload);
  },
};

export default TaskRepository;
