import React from "react";
import PageLayout from "examples/LayoutContainers/PageLayout";

import PrivacyPolicyLayout from "./PrivacyPolicyLayout";

const CookiesPolicy = () => {
  return (
    <PrivacyPolicyLayout>
      <PageLayout>
        <div class="privacy_policy_content">
          <div class="ic_text_content">
            <h6 className="section_heading">Introduction</h6>
            <p>
              Welcome to Nexa, accessible at www.nexaofficial.co. This Cookie Policy outlines how
              Nexa ("we", "us", or "our") utilizes cookies and similar technologies on our website
              to enhance your experience, provide personalized services, and gather insights to
              improve our platform. By using our site, you consent to the use of cookies in
              accordance with this policy.
            </p>
          </div>
          <div class="ic_text_content">
            <h6 className="section_heading">What Are Cookies?</h6>
            <p>
              Cookies are small text files stored on your device (computer, tablet, or mobile) when
              you visit certain websites. They are used to remember your preferences, login
              information, and browsing activities, helping to make your next visit more efficient
              and personalized.
            </p>
          </div>
          <div class="ic_text_content">
            <h6 className="section_heading">Why We Use Cookies</h6>
            <p>We use cookies for several essential purposes:</p>
            <ul>
              <li>
                <p>
                  <span>Functionality: </span>To enable basic site functionality, like staying
                  logged into your account.
                </p>
              </li>
              <li>
                <p>
                  <span>Performance and Analytics: </span> To analyze how our visitors use our site
                  and to monitor site performance. This helps us provide a high-quality experience
                  by customizing our offering and quickly identifying and fixing any issues that
                  arise.
                </p>
              </li>
              <li>
                <p>
                  <span>Personalization</span> To remember your preferences (such as your language
                  or region) and provide enhanced, more personal features.
                </p>
              </li>
              <li>
                <p>
                  <span>Marketing and Advertising: </span>To display advertisements that are
                  relevant and engaging for you and to measure the effectiveness of our advertising
                  campaigns.
                </p>
              </li>
            </ul>
          </div>
          <div class="ic_text_content">
            <h6 className="section_heading">Types of Cookies We Use</h6>
            <ul>
              <li>
                <p>
                  <span>Strictly Necessary Cookies: </span>These cookies are essential for you to
                  browse the website and use its features.
                </p>
              </li>
              <li>
                <p>
                  <span>Performance Cookies: </span> These cookies collect information about how you
                  use our website, such as which pages you visit most often.
                </p>
              </li>
              <li>
                <p>
                  <span>Functionality Cookies: </span> These cookies allow our website to remember
                  choices you make and provide enhanced, more personal features.
                </p>
              </li>
              <li>
                <p>
                  <span>Targeting or Advertising Cookies: </span>These cookies are used to make
                  advertising messages more relevant to you.
                </p>
              </li>
            </ul>
          </div>
          <div class="ic_text_content">
            <h6 className="section_heading">Managing Cookies</h6>
            <p>
              You have the right to decide whether to accept or reject cookies. You can set or amend
              your web browser controls to accept or refuse cookies. If you choose to reject
              cookies, you may still use our website though your access to some functionality and
              areas of our website may be restricted.
            </p>
          </div>
          <div class="ic_text_content">
            <h6 className="section_heading">Third-Party Cookies</h6>
            <p>
              In addition to our own cookies, we may also use various third-party cookies to report
              usage statistics of the service, deliver advertisements on and through the service,
              and so on.
            </p>
          </div>
          <div class="ic_text_content">
            <h6 className="section_heading">Changes to This Cookie Policy</h6>
            <p>
              We may update this Cookie Policy from time to time to reflect, for example, changes to
              the cookies we use or for other operational, legal, or regulatory reasons. Please
              re-visit this Cookie Policy regularly to stay informed about our use of cookies and
              related technologies.
            </p>
          </div>
          <div class="ic_text_content">
            <h6 className="section_heading">Contact Us</h6>
            <p>
              If you have any questions about our use of cookies or other technologies, please email
              us at [insert contact email].
            </p>
          </div>
        </div>
      </PageLayout>
    </PrivacyPolicyLayout>
  );
};

export default CookiesPolicy;
