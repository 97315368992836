import { FormControl, InputLabel, MenuItem, Pagination, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import Loader from "components/Loader";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { addAdmin } from "components/schema";
import { permission } from "context";
import { featurePermissionsObject } from "context";
import { getRouteByNumber } from "context";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  ActiveInvitation,
  get_subAdmins,
  post_invite_subAdmin,
  resendInvitation,
  revokeInvitaion,
  updateSubAdmin,
} from "store/Slices/adminSlice";
import { getUserRoles } from "store/Slices/roleSlice";

const Admins = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { userRoles } = useSelector((state) => state.roles);
  const { subAdmins, loading, paginationData } = useSelector((state) => state.subadmin);
  // console.log(subAdmins, "subAdmins>>>>>>>>>>>>");
  // console.log(userRoles, "userRoles>>>>>>>>>");
  const [open, setOpen] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [currentRole, setCurrentRole] = useState("");
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [RoleEmail, setRoleEmail] = useState("");
  // console.log(RoleEmail, "RoleEmail>>>>>>>>>>>>>>>>>>>");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [roleOptions, setRoleOptions] = useState([]);
  const [dataTobeupdate, setdataTobeupdate] = useState({});
  const [storePermissions, setStorePermissions] = useState(null);
  const parsedObject = JSON.parse(user?.rolePermissions);
  let pagePermissions;
  let featurePermissions;
  if (parsedObject) {
    const [key, value] = Object.entries(parsedObject)[0];
    pagePermissions = key;
    featurePermissions = value;
  }
  // console.log(dataTobeupdate, "datatobeupdate>>>>>>>>>>>>");
  useEffect(() => {
    if (RoleEmail) {
      const data = admins.find((t) => t.email === RoleEmail);
      const foundRole = roleOptions?.find((item) => item.name == data.role_name);
      // console.log(foundRole, "foundRole>>>>>>>>>>>>>>>>>>");
      setCurrentRole(foundRole.id);
      setdataTobeupdate(data || {});
    }
  }, [RoleEmail, admins]);
  const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: { email: dataTobeupdate?.email || "", role: currentRole || "" },
    validationSchema: addAdmin,
    enableReinitialize: true,
    onSubmit: async (values) => {
      let payload = {
        email: values.email,
        roleId: values.role,
      };
      if (RoleEmail) {
        let payloadData = {
          email: dataTobeupdate?.email,
          new_role_id: values.role,
        };
        dispatch(updateSubAdmin(payloadData))
          .unwrap()
          .then((res) => {
            getsubAdminFunction();
            toast.success(res);
            handleupdateModalClose();
            resetForm();
          })
          .catch((rejected) => toast.error(rejected?.response?.data?.detail));
        setRoleEmail("");
      } else {
        dispatch(post_invite_subAdmin(payload))
          .unwrap()
          .then((res) => {
            console.log(res, "response");
            if (res.already_registered?.length > 0) {
              toast.error(`Email:${res.already_registered[0]} already registered `);
            }
            if (res.invited_by_other_org?.length > 0) {
              toast.error(
                `Email:${res.already_registered[0]} already invited by other organization`
              );
            }
            if (res.new_added_emails?.length > 0) {
              toast.success(`Email:${res.new_added_emails[0]} Invited Sucessfully`);
            }
            if (res.refresh_invites?.length > 0) {
              toast.success(`Invitation Resent to Email:${res.refresh_invites[0]}`);
            }

            getsubAdminFunction();
            handleClose();
            resetForm();
          })
          .catch((rejected) => toast.error(rejected?.response?.data?.detail));
      }
    },
  });

  const handleDisabled = (val) => {
    if (storePermissions) {
      if (storePermissions.hasOwnProperty(val)) {
        return !storePermissions[val];
      }
      return null;
    }
  };
  const getsubAdminFunction = () => {
    let payload = `limit=${limit}&page=${page}`;

    dispatch(
      get_subAdmins({
        payload,
        onSuccess: (res) => {},
      })
    )
      .unwrap()
      .then((res) => {})
      .catch((rejected) => toast.error(rejected?.response?.data?.detail));
  };
  useEffect(() => {
    if (pagePermissions !== permission.admin && pagePermissions !== permission.admins) {
      const routeLink = getRouteByNumber(pagePermissions);
      return navigate(routeLink);
    }
    if (featurePermissions) {
      const data = featurePermissionsObject(featurePermissions);
      setStorePermissions(data);
      console.log(data, "featurePermissionsfeaturePermissions");
    }
    dispatch(getUserRoles());
  }, []);
  useEffect(() => {
    getsubAdminFunction();
  }, [limit, page]);
  useEffect(() => {
    if (userRoles?.length > 0) {
      setRoleOptions([...userRoles]);
    }
  }, [userRoles]);
  useEffect(() => {
    if (subAdmins.length > 0) {
      setAdmins([...subAdmins]);
    }
  }, [subAdmins]);
  // Modal open function
  const handleOpen = (email) => {
    setOpen(true);
  };
  const handleOpenUpdateModal = (email) => {
    setRoleEmail(email || "");
    setOpenUpdateModal(true);
  };

  //Modal close function
  const handleClose = () => {
    resetForm();
    setOpen(false);
  };
  const handleupdateModalClose = () => {
    setRoleEmail("");
    resetForm();
    setOpenUpdateModal(false);
  };
  const handleRevoke = (email) => {
    // console.log(email, "handleRevoke>>>>>>>>>>>>>>>>>");
    dispatch(
      revokeInvitaion({
        email,
        onSuccess: (res) => {
          getsubAdminFunction();
          toast.success(res);
        },
      })
    )
      .unwrap()
      .then((res) => {})
      .catch((rejected) => toast.error(rejected?.response?.data?.detail));
  };
  const handleActiveRevoke = (email) => {
    // console.log(email, "handleRevoke>>>>>>>>>>>>>>>>>");
    dispatch(
      ActiveInvitation({
        email,
        onSuccess: (res) => {
          getsubAdminFunction();
          toast.success(res);
        },
      })
    )
      .unwrap()
      .then((res) => {})
      .catch((rejected) => toast.error(rejected?.response?.data?.detail));
  };
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const handleResendInvitation = (email) => {
    dispatch(
      resendInvitation({
        email,
        onSuccess: (res) => {
          toast.success(res);
        },
      })
    )
      .unwrap()
      .then((res) => {})
      .catch((rejected) => toast.error(rejected?.response?.data?.detail));
    // console.log(email, "email");
  };
  ///for store data on form
  useEffect(() => {
    setColumns([
      { Header: "Email", accessor: "email", align: "left" },
      { Header: "Role", accessor: "role", align: "center" },
      { Header: "Created At", accessor: "createdAt", align: "center" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "Updated On", accessor: "updatedon", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ]);
    // add user array
    const customAddAdmin =
      admins &&
      admins.length >= 1 &&
      admins.map((data, index) => {
        return {
          email: (
            <MDTypography
              component="a"
              href="#"
              variant="button"
              color="text"
              fontWeight="medium"
              key={index + 100}
            >
              {data?.email}
            </MDTypography>
          ),
          role: (
            <Typography
              component="a"
              href="#"
              variant="caption"
              color="textPrimary"
              fontWeight="medium"
            >
              <Box
                component="span"
                sx={{
                  ...pillSx,
                  backgroundColor: "#ab47bc",
                  color: "#fff",
                }}
              >
                {data?.role_name}
              </Box>
            </Typography>
          ),
          createdAt: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {moment(data?.created_on).format("DD MMM YYYY")}
            </MDTypography>
          ),
          status: (
            <Typography
              component="a"
              href="#"
              variant="caption"
              color="textPrimary"
              fontWeight="medium"
            >
              <Box
                component="span"
                sx={{
                  ...pillSx,
                  backgroundColor:
                    data?.status === "joined"
                      ? "#388e3c"
                      : data?.status === "revoked"
                      ? "#ef5350"
                      : data?.status === "pending"
                      ? "#f57c00"
                      : "#ab47bc",
                  color: "#fff",
                }}
              >
                {data?.status}
              </Box>
            </Typography>
          ),
          updatedon: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {moment(data?.updated_on).format("DD MMM YYYY")}
            </MDTypography>
          ),
          action: (
            // <MDBox sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            //   <>
            //     <Button
            //       variant="contained"
            //       size="small"
            //       onClick={() => handleDelete(data?.email)}
            //       sx={{
            //         ml: 1.3,
            //         color: "#fff",
            //         bgcolor: "black",
            //         "&:hover": {
            //           bgcolor: "darkslategray",
            //         },
            //       }}
            //     >
            //       <Icon color="#fff" sx={{ fontSize: "20px" }}>
            //         delete
            //       </Icon>
            //     </Button>
            //   </>
            // </MDBox>
            <MDBox sx={{ display: "flex", justifyContent: "start", alignItems: "start" }}>
              {data?.status == "joined" ? (
                <>
                  {" "}
                  <Button
                    onClick={(e) => {
                      handleOpenUpdateModal(data?.email);
                    }}
                    variant="contained"
                    size="small"
                    disabled={handleDisabled("update") || loading}
                    sx={{
                      ml: 1.3,
                      color: "#fff",
                      bgcolor: "#388e3c",
                      "&:hover": {
                        bgcolor: "darkslategray",
                      },
                    }}
                  >
                    Update Role
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    disabled={handleDisabled("update") || loading}
                    onClick={() => handleRevoke(data?.email)}
                    sx={{
                      ml: 1.3,
                      color: "#fff",
                      bgcolor: "#ef5350",
                      "&:hover": {
                        bgcolor: "darkslategray",
                      },
                    }}
                  >
                    Revoke
                  </Button>
                </>
              ) : data?.status == "revoked" ? (
                <>
                  {" "}
                  <Button
                    variant="contained"
                    size="small"
                    disabled={handleDisabled("update") || loading}
                    onClick={() => handleActiveRevoke(data?.email)}
                    sx={{
                      ml: 1.3,
                      color: "#fff",
                      bgcolor: "#f57c00",
                      "&:hover": {
                        bgcolor: "darkslategray",
                      },
                    }}
                  >
                    Active Revoked
                  </Button>
                </>
              ) : data?.status == "pending" ? (
                <>
                  <Button
                    variant="contained"
                    size="small"
                    disabled={handleDisabled("update") || loading}
                    onClick={() => handleRevoke(data?.email)}
                    sx={{
                      ml: 1.3,
                      color: "#fff",
                      bgcolor: "#ef5350",
                      "&:hover": {
                        bgcolor: "darkslategray",
                      },
                    }}
                  >
                    Revoke
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    disabled={handleDisabled("update") || loading}
                    onClick={() => handleResendInvitation(data?.email)}
                    sx={{
                      ml: 1.3,
                      color: "#fff",
                      bgcolor: "info",
                      "&:hover": {
                        bgcolor: "darkslategray",
                      },
                    }}
                  >
                    Resend Invitation
                  </Button>
                </>
              ) : (
                <></>
              )}
            </MDBox>
          ),
        };
      });
    setRows(Array.isArray(customAddAdmin) ? customAddAdmin : []);
  }, [admins]);

  ////styling for modal box
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    boxShadow: 24,
    p: 4,
  };

  const pillSx = {
    display: "inline-block",
    padding: "0.2em 0.6em",
    borderRadius: "9999px",
    fontSize: "0.75rem",
    fontWeight: 500,
    textAlign: "center",
    color: "white",
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <DashboardLayout>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  ADMINS MANAGEMENT
                </MDTypography>
                <Box>
                  <Button
                    variant="contained"
                    sx={{
                      color: "#fff",
                      bgcolor: "#000",
                      "&:hover": {
                        bgcolor: "darkslategray",
                      },
                    }}
                    onClick={handleOpen}
                    disabled={handleDisabled("create")}
                  >
                    Add New
                  </Button>
                </Box>
              </MDBox>
              <MDBox pt={3}>
                {admins && admins.length >= 1 ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                    checkBoxes={false}
                  />
                ) : (
                  <MDTypography
                    component="h2"
                    href="#"
                    color="text"
                    fontWeight="bold"
                    sx={{
                      textAlign: "center",
                      my: 10,
                    }}
                  >
                    YOU DON'T HAVE ANY ADMINS
                  </MDTypography>
                )}
              </MDBox>
              <MDBox
                m={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pagination
                  count={paginationData?.total_pages}
                  page={page}
                  onChange={handlePageChange}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {/* user Page ENd */}

      {/* Add modal box */}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style}>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1} mb={1}>
              Add New Admin
            </MDTypography>
          </MDBox>
          <MDBox mx={1} p={1} mt={1}>
            <form onSubmit={handleSubmit}>
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label="Email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  required
                />
                {errors.email && touched.email ? (
                  <MDTypography color="error" sx={{ fontSize: "13px" }}>
                    {<p>{errors.email}</p>}
                  </MDTypography>
                ) : null}
              </MDBox>
              <MDBox mb={2}>
                <Box>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Role</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Role"
                      name="role"
                      value={values.role}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    >
                      {roleOptions.map((option, index) => (
                        <MenuItem key={index} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.role && touched.role ? (
                      <MDTypography color="error" sx={{ fontSize: "13px" }}>
                        {<p>{errors.role}</p>}
                      </MDTypography>
                    ) : null}
                  </FormControl>
                </Box>
              </MDBox>

              <MDBox mt={4} mb={1} sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
                <MDButton variant="gradient" color="info" onClick={handleClose}>
                  Cancel
                </MDButton>
                <MDButton variant="gradient" color="info" type="submit">
                  Save
                </MDButton>
              </MDBox>
            </form>
          </MDBox>
        </Card>
      </Modal>
      <Modal
        open={openUpdateModal}
        onClose={() => {
          setOpenUpdateModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style}>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1} mb={1}>
              Update Sub Admin
            </MDTypography>
          </MDBox>
          <MDBox mx={1} p={1} mt={1}>
            <form onSubmit={handleSubmit}>
              <MDBox mb={2}>
                <Box>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Role</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Role"
                      name="role"
                      value={values.role}
                      onChange={(e) => setCurrentRole(e.target.value)}
                      // onBlur={handleBlur}
                      required
                    >
                      {roleOptions.map((option, index) => (
                        <MenuItem key={index} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </MDBox>
              <MDBox mt={4} mb={1} sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
                <MDButton variant="gradient" color="info" onClick={handleupdateModalClose}>
                  Cancel
                </MDButton>
                <MDButton variant="gradient" color="info" type="submit">
                  Save
                </MDButton>
              </MDBox>
            </form>
          </MDBox>
        </Card>
      </Modal>
      {/* End modal box */}
    </DashboardLayout>
  );
};

export default Admins;
