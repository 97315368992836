/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

import { NavLink, useLocation } from "react-router-dom";

import PropTypes from "prop-types";

import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import List from "@mui/material/List";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

import {
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  useMaterialUIController,
} from "context";
import { useDispatch, useSelector } from "react-redux";
import { permission } from "context";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { orgTheme } = useSelector((state) => state.oraganizationTheme);
  const [controller, disptachCall] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");
  const [textColor, setTextColor] = useState("white");
  let pagePermissions;
  let featurePermissions;
  if (user) {
    const parsedObject = JSON.parse(user?.rolePermissions);
    if (parsedObject) {
      const [key, value] = Object.entries(parsedObject)[0];
      pagePermissions = key;
      featurePermissions = value;
    }
  }
  const Enviorment = process.env.REACT_APP_ENVIORMENT;

  useEffect(() => {
    if (orgTheme?.sidebar_type === "#000000") {
      setTextColor("white");
    } else {
      setTextColor("inherit");
    }
  }, [orgTheme?.sidebar_type]);

  const closeSidenav = () => setMiniSidenav(disptachCall, true);

  useEffect(() => {
    function handleMiniSidenav() {
      setMiniSidenav(disptachCall, window.innerWidth < 1200);
      setTransparentSidenav(disptachCall, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(disptachCall, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    window.addEventListener("resize", handleMiniSidenav);

    handleMiniSidenav();

    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [disptachCall, location]);

  const renderRoutes = routes.map(({ type, name, icon, key, route, isMenu }) => {
    const permissionRoutes = {
      [permission.dashboard]: "/dashboard",
      [permission.reportingAndAnalytics]: "/reporting-&-analytics",
      [permission.users]: "/users",
      [permission.tasks]: "/Tasks",
      [permission.chats]: "/chats",
      [permission.questions]: "/questions",
      [permission.admins]: "/admins",
      [permission.subscription]: "/subscription",
      [permission.settings]: "/settings",
      [permission.roles]: "/roles",
      [permission.rolesCategories]: "/roles-categories",
    };

    if (user?.data?.is_sub_organization && route === "/sub-organizations") {
      return null;
    }

    if (
      user?.activeBundle == null ||
      user?.activeBundle === "access" ||
      (pagePermissions in permissionRoutes && route !== permissionRoutes[pagePermissions]) ||
      isMenu === false
    ) {
      return null;
    }

    const liveRestrictedRoutes = [
      "/questions",
      "/chats",
      "/admins",
      "/Tasks",
      "/skills-gap-analysis",
    ];

    if (Enviorment === "live" && liveRestrictedRoutes.includes(route)) {
      return null;
    }

    return (
      <NavLink key={key} to={route}>
        <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
      </NavLink>
    );
  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          {brand && <MDBox component="img" src={brand} alt="Brand" width="2rem" />}
          <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography
              component="h6"
              variant="button"
              fontWeight="medium"
              color={textColor}
              style={{ marginLeft: "10px" }}
            >
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>
    </SidenavRoot>
  );
}

Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
