import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";
const chatRepo = RepositoryFactory.get("chat");

export const startChatAction = createAsyncThunk(
  "chat/createChat",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await chatRepo.startChat(payload);
      if (data) {
        return data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const sendMessageAction = createAsyncThunk(
  "chat/sendMessage",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await chatRepo.sendMessage(payload);
      if (data) {
        // console.log({ data });
        // return data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getAllChatAction = createAsyncThunk(
  "chat/getChats",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await chatRepo.getAllChats();
      console.log(data);
      if (data) {
        return data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getSingleChatAction = createAsyncThunk(
  "chat/singleChat",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await chatRepo.getSingleChat(id);
      if (data) {
        return data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  loading: false,
  singleChatLoading: false,
};

const ChatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //Get all roles
      .addCase(getAllChatAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllChatAction.fulfilled, (state, action) => {
        state.loading = false;
        state.allChats = action.payload;
      })
      .addCase(getAllChatAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getSingleChatAction.pending, (state) => {
        state.singleChatLoading = true;
      })
      .addCase(getSingleChatAction.fulfilled, (state, action) => {
        state.singleChatLoading = false;
        state.singleChat = action.payload;
      })
      .addCase(getSingleChatAction.rejected, (state, action) => {
        state.singleChatLoading = false;
      });
  },
});
export default ChatSlice.reducer;
