import { Paper } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import UserChat from "./UserChat";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: "100%",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
    },
    messagesBody: {
      margin: "10px 0px",
      width: "90%",
      height: "calc(100% - 25px)",
      overflowY: "scroll",
      "&::-webkit-scrollbar": {
        width: "10px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
        borderRadius: "10px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#888",
        borderRadius: "10px",
        border: "3px solid #f1f1f1",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#555",
      },
    },
  })
);

const ChatLeft = ({ singleChatId, handleActive }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { allChats } = useSelector((state) => state.chats);

  return (
    <div className={classes.container}>
      <Paper id="style-1" className={classes.messagesBody}>
        {allChats?.map((chat, index) => {
          return (
            <UserChat
              key={index}
              id={chat.id}
              // message={chat.messages?.[0]?.message}
              timestamp={chat.messages?.[0]?.timestamp}
              photoURL={chat.messages?.[0]?.photoURL}
              displayName={chat.is_group ? chat.name : chat?.members[0]?.name}
              handleActive={handleActive}
              activeChat={singleChatId === chat.id}
            />
          );
        })}
      </Paper>
    </div>
  );
};

export default ChatLeft;
