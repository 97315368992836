import {
  Box,
  Card,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useEffect, useState } from "react";
import ChatBox from "./Components/ChatBox";
import ChatLeft from "./Components/ChatLeft";
import { useDispatch, useSelector } from "react-redux";
import { getJoinedUsers } from "store/Slices/usersSlice";
import MDInput from "components/MDInput";
import { startChatAction } from "store/Slices/ChatSlice";
import { sendMessageAction } from "store/Slices/ChatSlice";
import { getAllChatAction } from "store/Slices/ChatSlice";
import { getSingleChatAction } from "store/Slices/ChatSlice";
import Loader from "components/Loader";
import { getRouteByNumber } from "context";
import { useNavigate } from "react-router-dom";
import { permission } from "context";
import { featurePermissionsObject } from "context";
// import { lastChats } from "./Components/DummyData";

const styleModal = {
  width: "90vw", // use a percentage width for responsiveness
  maxWidth: "500px", // set a max width to prevent it from getting too large
  margin: "auto", // center the modal
  padding: "20px", // add some padding
  boxShadow: 24,
  borderRadius: "8px",
};

const Chats = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { joinedUsers } = useSelector((state) => state.userManagment);
  const { user } = useSelector((state) => state.auth);
  const { allChats, loading } = useSelector((state) => state.chats);
  const [storePermissions, setStorePermissions] = useState(null);
  const [lastChats, setlastChats] = useState([]);
  const [groupChat, setGroupChat] = useState(false);
  const [groupChatName, setGroupChatName] = useState("");
  const [chatIndex, setChatIndex] = useState(-1);
  const [chatModal, setChatModal] = useState(false);
  const [chatUser, setChatUser] = useState([]);
  const [message, setMessage] = useState("");
  const [singleChatId, setSingleChatId] = useState(null);
  const parsedObject = JSON.parse(user?.rolePermissions);
  let pagePermissions;
  let featurePermissions;
  if (parsedObject) {
    const [key, value] = Object.entries(parsedObject)[0];
    pagePermissions = key;
    featurePermissions = value;
  }
  const handleDisabled = (val) => {
    if (storePermissions) {
      if (storePermissions.hasOwnProperty(val)) {
        return !storePermissions[val];
      }
      return null;
    }
  };
  useEffect(() => {
    if (allChats?.length > 0) {
      setSingleChatId(allChats[0].id);
    }
  }, [allChats]);
  useEffect(() => {
    if (singleChatId) {
      dispatch(getSingleChatAction(singleChatId));
    }
  }, [singleChatId]);

  const handleActive = (id) => {
    setSingleChatId(id);
  };

  const clearModal = () => {
    setGroupChat(false);
    setGroupChatName("");
    setChatUser([]);
    setMessage("");
    setChatModal(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let payload = {
      name: groupChatName,
      is_group: groupChat,
      member_ids: chatUser,
      member_type: "customer",
    };

    dispatch(startChatAction(payload))
      .unwrap()
      .then((res) => {
        let messagePayload = {
          chat_id: res.id,
          content: message,
        };
        dispatch(sendMessageAction(messagePayload))
          .unwrap()
          .then(() => {
            clearModal();
            dispatch(getAllChatAction());
          })
          .catch((err) => {
            toast.error(err.message);
          });
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  useEffect(() => {
    if (pagePermissions !== permission.admin && pagePermissions !== permission.chats) {
      const routeLink = getRouteByNumber(pagePermissions);
      return navigate(routeLink);
    }
    if (featurePermissions) {
      const data = featurePermissionsObject(featurePermissions);
      setStorePermissions(data);
      console.log(data, "featurePermissionsfeaturePermissions");
    }
    dispatch(getJoinedUsers());
    dispatch(getAllChatAction());
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <DashboardLayout>
      <MDBox py={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "1rem",
                }}
              >
                <CardHeader title="Chats" />
                <MDButton
                  size="small"
                  color="primary"
                  onClick={() => setChatModal(true)}
                  disabled={handleDisabled("create")}
                >
                  New Chat
                </MDButton>
              </MDBox>
              {allChats?.length > 0 ? (
                <Grid
                  container
                  sx={{
                    padding: "0 1rem",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    xl={3}
                    sx={{
                      display: {
                        xs: singleChatId ? "none" : "block", // Hide on extra-small screens
                        lg: "block", // Display on large and larger screens
                      },
                    }}
                  >
                    <ChatLeft singleChatId={singleChatId} handleActive={handleActive} />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    xl={9}
                    sx={{
                      display: {
                        xs: singleChatId ? "block" : "none", // Hide on extra-small screens
                        lg: "block", // Display on large and larger screens
                      },
                    }}
                  >
                    <ChatBox handleActive={handleActive} handleDisabled={handleDisabled} />
                  </Grid>
                </Grid>
              ) : (
                <MDBox p={2}>
                  <Typography variant="h5" sx={{ textAlign: "center" }}>
                    No Chats Exists
                  </Typography>
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Modal
        open={chatModal}
        onClose={() => setChatModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
          <Card sx={styleModal}>
            <Box
              variant="gradient"
              bgcolor="info.main"
              borderRadius="12px"
              boxShadow={3}
              mx={2}
              p={2}
              mb={2}
              textAlign="center"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mb={1}>
                Start Chat
              </MDTypography>
            </Box>
            <MDBox mx={1} p={1} mt={1}>
              <form onSubmit={handleSubmit}>
                <MDBox mb={2}>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox value={groupChat} onChange={() => setGroupChat(!groupChat)} />
                      }
                      label="Group Message"
                    />
                  </Box>
                </MDBox>
                {groupChat && (
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Group Chat Name"
                      name="Group Chat Name"
                      value={groupChatName}
                      required
                      onChange={(e) => setGroupChatName(e.target.value)}
                      fullWidth
                    />
                  </MDBox>
                )}
                <MDBox mb={2}>
                  <Box>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label-users">Users</InputLabel>
                      <Select
                        labelId="demo-simple-select-label-users"
                        id="demo-simple-select"
                        name="account"
                        multiple
                        value={chatUser}
                        onChange={(event) => {
                          const {
                            target: { value },
                          } = event;
                          setChatUser(typeof value === "string" ? value.split(",") : value);
                        }}
                        // onBlur={handleBlur}
                        label="Users"
                        required
                      >
                        {joinedUsers?.map((user) => {
                          return (
                            <MenuItem value={user?.customer_id}>{user?.customer_name}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </MDBox>

                <MDBox mb={2}>
                  <TextareaAutosize
                    minRows={3}
                    placeholder="Message"
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                    style={{ width: "100%", marginTop: "10px", padding: "10px" }}
                    fullWidth
                    required
                  />
                </MDBox>

                <MDBox mt={4} mb={1} sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
                  <MDButton variant="gradient" color="info" onClick={() => setChatModal(false)}>
                    Cancel
                  </MDButton>
                  <MDButton variant="gradient" color="info" type="submit">
                    Send
                  </MDButton>
                </MDBox>
              </form>
            </MDBox>
          </Card>
        </Grid>
      </Modal>
    </DashboardLayout>
  );
};

export default Chats;
