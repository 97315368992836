import { Box, Paper, Typography } from "@mui/material";
import MessageComponent from "components/MessageComponent";
// import FeedbackMessageComponent from "components/FeedbackMessageComponent";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import FeedbackMessageComponent from "../FeedbackMessageComponent";

const TaskChatSection = () => {
  const { getSingleTaskData, loading } = useSelector((state) => state.task);
  const [modifiedComments, setModifiedComments] = useState([]);
  console.log(modifiedComments, "modifiedFeedbacks");

  useEffect(() => {
    // setqueryLength(singleTask?.comments?.length);
    const restructuredData = getSingleTaskData?.comments?.reduce((acc, comment) => {
      const messageDate = moment(comment?.created_at).format("MM/DD/YYYY");
      console.log(messageDate, "messageDate");
      const existingDateIndex = acc?.findIndex((item) => item.date === messageDate);
      if (existingDateIndex !== -1) {
        acc[existingDateIndex]?.comments.push(comment);
      } else {
        acc.push({
          date: messageDate,
          comments: [comment],
        });
      }
      return acc;
    }, []);
    setModifiedComments(restructuredData);
    console.log(restructuredData, "restructuredData");
  }, [getSingleTaskData]);

  return (
    <>
      {getSingleTaskData?.comments?.length <= 0 && (
        <Box sx={{ textAlign: "center", my: 6 }}>
          <Typography variant="h5" fontWeight="bold">
            No Comments Found
          </Typography>
        </Box>
      )}
      {modifiedComments?.map((item, index) => (
        <Box key={index}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              position: "sticky",
              top: 0,
              zIndex: 1,
            }}
          >
            <Paper
              elevation={2}
              sx={{
                border: "1px solid black",
                color: "black",
                fontWeight: "bold",
                borderRadius: "8px",
                p: 1,
                backgroundColor: "white",
                fontSize: "14px",
              }}
            >
              {moment(item?.date).format("DD-MM-YYYY")}
            </Paper>
          </Box>

          {item?.comments?.map((comment, index) => (
            <MessageComponent key={index} comment={comment} />
          ))}
        </Box>
      ))}
    </>
  );
};

export default TaskChatSection;
