import {
  Avatar,
  Box,
  Card,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Loader";
import UserImg from "../../assets/images/logos/user.png";

const AssignStatusModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const { getSingleTaskData, loading } = useSelector((state) => state.task);
  const styleModal = {
    width: "100vw",
    maxWidth: "500px",
    margin: "auto",
    padding: "20px",
    boxShadow: 24,
    borderRadius: "8px",
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid container justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
        {loading && <Loader />}
        <Card sx={styleModal}>
          <Box
            variant="gradient"
            bgcolor="info.main"
            borderRadius="12px"
            boxShadow={3}
            mx={2}
            p={2}
            mb={1}
            textAlign="center"
          >
            <Typography variant="h4" fontWeight="medium" color="white.main" mt={1} mb={1}>
              Task Users Status
            </Typography>
          </Box>
          <Box mx={1} p={1} mt={1}>
            <List
              sx={{
                width: "100%",
                borderRadius: "12px",
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              {getSingleTaskData?.assigned_user_list?.map((item, index) => (
                <ListItem
                  key={index + 100}
                  sx={{
                    p: 2,
                    display: "flex",
                    justifyContent: "space-between",
                    borderRadius: "12px",
                    mb: 2,
                    backgroundColor: "#f7f7f7",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Avatar
                      src={item?.image_url || UserImg}
                      alt="user"
                      sx={{ width: 50, height: 50, borderRadius: "50%" }}
                    />
                    <Typography variant="h6" component="div" sx={{ color: "white" }}>
                      <span style={{ fontWeight: "bold", marginLeft: 8, fontSize: "1.3rem" }}>
                        {item?.customer_name}
                      </span>
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Chip
                      label={item?.task_status}
                      sx={{
                        padding: "6px 6px",
                        borderRadius: "100px",
                        gap: "5px",
                        fontSize: "18px",
                        fontWeight: 500,
                        textTransform: "capitalize",
                        alignItems: "center",
                        backgroundColor:
                          item?.task_status === "COMPLETED"
                            ? "#e7f5e8"
                            : item?.task_status === "IN_PROGRESS"
                            ? "rgba(241, 235, 255, 0.5)"
                            : "#fbe9e9",
                        color:
                          item?.task_status === "COMPLETED"
                            ? "#0f9918"
                            : item?.task_status === "IN_PROGRESS"
                            ? "#f49c0f"
                            : "#d32f2f",
                      }}
                    />
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>
          <Box mt={4} mb={1} sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
            <MDButton variant="gradient" color="info" onClick={onClose}>
              Cancel
            </MDButton>
          </Box>
        </Card>
      </Grid>
    </Modal>
  );
};

export default AssignStatusModal;
