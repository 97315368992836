import {
  Box,
  Card,
  Divider,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import MDTypography from "components/MDTypography";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getSkillGapAnalysis,
  getUserOnBoarding,
  getUserLinkedinProfile,
  setUserLinkedinProfile,
  getUserEmploymentStatus,
  setUserEmploymentStatus,
} from "store/Slices/usersSlice";
import Link from "@mui/material/Link";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { toast } from "react-toastify";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Loader from "components/Loader";
import { getUserInfoById } from "store/Slices/usersSlice";
import { updateUserInfoById } from "store/Slices/usersSlice";

const ProfileInformation = ({ storePermissions }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const spiltID = id?.split("_")?.[0];
  const { skillGapData, userOnBoardingData, linkedinUrlLink, loading, userInfo } = useSelector(
    (state) => state.users
  );

  const [formData, setFormData] = useState({
    image: "",
    name: "",
    address: "",
    education: "",
    contact: "",
    linkedIn_profile: "",
    employment_status: "",
  });
  const handleInputChange = ({ target: { name, type, value, files } }) => {
    if (name === "contact" && !/^\d*$/.test(value)) {
      return;
    }
    if (type === "file") {
      const file = files[0];
      const validTypes = ["image/jpeg", "image/png", "image/jpg"];
      const maxSize = 1 * 1024 * 1024; // 1MB in bytes

      if (file && validTypes.includes(file.type) && file.size <= maxSize) {
        setFormData((prev) => ({ ...prev, [name]: file }));
      } else {
        toast.error("Invalid file type or size exceeds 1MB.");
      }
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = () => {
    if (formData.name.trim("") === "") {
      toast.error("User Name is required");
      return;
    }
    const payload = new FormData();
    Object.entries(formData).forEach(([key, val]) => {
      if (key === "image") {
        if (typeof val === "object") {
          payload.append(key, val);
        }
      } else {
        payload.append(key, val);
      }
    });

    dispatch(
      updateUserInfoById({
        id: spiltID,
        payload,
        onSuccess: () => {
          toast.success("User Info Updated Successfully");
        },
      })
    );
  };

  const handleDisabled = (val) => {
    if (storePermissions) {
      if (storePermissions.hasOwnProperty(val)) {
        return !storePermissions[val];
      }
      return null;
    }
  };

  useEffect(() => {
    setFormData({
      image: userInfo?.image || "",
      name: userInfo?.name || "",
      education: userInfo?.education || "",
      address: userInfo?.address || "",
      contact: userInfo?.contact || "",
      employment_status: userInfo?.employment_status || "",
      linkedIn_profile: userInfo?.linkedIn_profile || "",
    });
  }, [userInfo]);

  useEffect(() => {
    dispatch(getSkillGapAnalysis({ id: spiltID }));
    dispatch(getUserOnBoarding({ id: spiltID }));
    dispatch(getUserLinkedinProfile({ id: spiltID }));
    dispatch(getUserEmploymentStatus({ id: spiltID }));
    dispatch(getUserInfoById(spiltID));
  }, []);

  return (
    <MDBox mt={5} mb={3}>
      {loading && <Loader />}
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} xl={12}>
          <Card sx={{ height: "100%", padding: "10px" }}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap="30px"
              py={2}
              px={2}
            >
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <label
                    htmlFor="image"
                    style={{
                      display: "block",
                      width: "96px",
                      height: "96px",
                      borderRadius: "50%",
                      backgroundColor: "#fafafa",
                      // overflow: "hidden",
                      position: "relative",
                    }}
                  >
                    {formData?.image ? (
                      <img
                        src={
                          typeof formData.image === "object"
                            ? URL.createObjectURL(formData.image)
                            : formData.image
                        }
                        style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                        alt="profile-img"
                      />
                    ) : (
                      <img
                        src="/images/avatar.png"
                        style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                        alt="profile-img"
                      />
                    )}
                    <IconButton
                      component="span"
                      style={{
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        backgroundColor: "#fafaf9",
                        width: 32,
                        height: 32,
                        borderRadius: "50%",
                        boxShadow: "0px 0px 5px rgba(0,0,0,0.2)",
                      }}
                    >
                      <EditIcon fontSize="medium" color="inherit" />
                    </IconButton>
                  </label>
                  <MDInput
                    style={{ display: "none" }}
                    type="file"
                    id="image"
                    name="image"
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MDInput
                    type="text"
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>

                {/* Second Row - Address and Education */}
                <Grid item xs={12} md={6}>
                  <MDInput
                    type="text"
                    label="Address"
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MDInput
                    type="text"
                    label="Education"
                    name="education"
                    value={formData.education}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>

                {/* Third Row - Contact and Employment Status */}
                <Grid item xs={12} md={6}>
                  <MDInput
                    type="text"
                    label="Contact"
                    name="contact"
                    value={formData.contact}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="employment_status">Employment Status</InputLabel>
                    <Select
                      labelId="employment_status"
                      id="employment_status"
                      label="Employment Status"
                      name="employment_status"
                      value={formData.employment_status}
                      onChange={handleInputChange}
                      // disabled={
                      //   emplymentStatus == null
                      //     ? handleDisabled("create")
                      //     : handleDisabled("update")
                      // }
                    >
                      <MenuItem value="FullTime">Full-Time</MenuItem>
                      <MenuItem value="PartTime">Part-Time</MenuItem>
                      <MenuItem value="Contractor">Contractor</MenuItem>
                      <MenuItem value="Intern">Intern</MenuItem>
                      <MenuItem value="Freelancer">Freelancer</MenuItem>
                      <MenuItem value="Temporary">Temporary</MenuItem>
                      <MenuItem value="Unemployed">Unemployed</MenuItem>
                      <MenuItem value="Student/Unemployed">Student/Unemployed</MenuItem>
                      <MenuItem value="Retired">Retired</MenuItem>
                      <MenuItem value="OnLeave">OnLeave</MenuItem>
                      <MenuItem value="Sabbatical">Sabbatical</MenuItem>
                      <MenuItem value="Probation">Probation</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <MDInput
                    type="text"
                    label="Add Linkedin URL"
                    name="linkedIn_profile"
                    value={formData.linkedIn_profile}
                    onChange={handleInputChange}
                    // disabled={
                    //   linkedinUrlLink == null ? handleDisabled("create") : handleDisabled("update")
                    // }
                    fullWidth
                  />
                </Grid>

                {/* Submit Button */}
                <Grid item xs={12}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={handleSubmit}
                    disabled={
                      linkedinUrlLink == null ? handleDisabled("create") : handleDisabled("update")
                    }
                    fullWidth
                  >
                    Update User Info
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </Grid>

        <Grid item xs={12} md={12} xl={12}>
          <Card sx={{ height: "100%", padding: "10px" }}>
            <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                User onboarding Data
              </MDTypography>
            </MDBox>
            <MDBox p={2}>
              {userOnBoardingData?.map((data, index) => (
                <MDBox mb={2} lineHeight={1} key={index}>
                  <Typography
                    variant="h2"
                    sx={{
                      fontSize: "20px",
                      fontWeight: "400",
                      color: "#000",
                      marginBottom: "10px",
                    }}
                  >
                    {data?.question}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "16px", fontWeight: "500", marginTop: "15px" }}
                  >
                    {data?.file_url == null ? (
                      <>
                        <strong>•</strong> {data?.answer}
                      </>
                    ) : (
                      <Link
                        href={data?.file_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <PictureAsPdfIcon sx={{ marginRight: "5px" }} />
                        Download PDF
                      </Link>
                    )}
                  </Typography>
                </MDBox>
              ))}
            </MDBox>
          </Card>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} xl={12} mt={3} sx={{ display: "flex" }}>
        <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
        <Card sx={{ height: "100%", padding: "10px", shadow: "" }}>
          <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              Skill Gap assessments
            </MDTypography>
          </MDBox>
          <MDBox p={2}>
            <MDBox mb={2} lineHeight={1}>
              <Typography
                dangerouslySetInnerHTML={{ __html: skillGapData?.analysis }}
                sx={{ fontSize: "14px", fontWeight: "400" }}
              />
            </MDBox>
          </MDBox>
        </Card>
        <Divider orientation="vertical" sx={{ mx: 0 }} />
      </Grid>
    </MDBox>
  );
};

export default ProfileInformation;
