import Grid from "@mui/material/Grid";
import Loader from "components/Loader";

import MDBox from "components/MDBox";
import { permission } from "context";
import { getRouteByNumber } from "context";

import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSubscription } from "store/Slices/authSlice";
import { todayActiveCustomer } from "store/Slices/profileSlice";
import { avgPRoundScore } from "store/Slices/profileSlice";
import { orgIncompletionRates } from "store/Slices/profileSlice";
import { resumeCompletionRate } from "store/Slices/profileSlice";
import { avgInterviewSim } from "store/Slices/profileSlice";
import { getDashboardSimulationStats } from "store/Slices/profileSlice";
import { totalUserCount } from "store/Slices/profileSlice";
import { getOrgResumeStats } from "store/Slices/profileSlice";
import { getDashboardTrainingStats } from "store/Slices/profileSlice";

function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const {
    dashboardTrainingStats,
    dashboardSimulationStats,
    orgResume,
    loading,
    totalUserCountVal,
    todayActiveCustomerVal,
    avgInterviewSimVal,
    avgPRoundScoreVal,
    orgIncompletionRatesVal,
    resumeCompletionRateVal,
  } = useSelector((state) => state.orgProfile);
  const parsedObject = JSON.parse(user?.rolePermissions);
  let pagePermissions;
  let featurePermissions;
  if (parsedObject) {
    const [key, value] = Object.entries(parsedObject)[0];
    pagePermissions = key;
    featurePermissions = value;
  }

  useEffect(() => {
    if (pagePermissions !== permission.admin && pagePermissions !== permission.dashboard) {
      const routeLink = getRouteByNumber(pagePermissions);
      return navigate(routeLink);
    }
    dispatch(getDashboardTrainingStats());
    dispatch(getDashboardSimulationStats());
    dispatch(getOrgResumeStats());
    dispatch(getSubscription());

    dispatch(totalUserCount());
    dispatch(todayActiveCustomer());
    dispatch(avgInterviewSim());
    dispatch(avgPRoundScore());
    dispatch(orgIncompletionRates());
    dispatch(resumeCompletionRate());
  }, []);

  return (
    <DashboardLayout>
      {loading && <Loader />}
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="Practice Rounds"
                count={dashboardTrainingStats?.total_sessions}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Interviews Taken"
                count={dashboardSimulationStats?.total_sessions}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Resumes"
                count={orgResume}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="money"
                title="Money Saved"
                count={`$${Number(orgResume || 0) * 30}`}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="save"
                title="Time Saved"
                count={`${Number(dashboardTrainingStats?.total_sessions || 0) * 30} minutes`}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard icon="people" title="Total Users" count={totalUserCountVal} />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="notifications"
                title="Today Active Users"
                count={todayActiveCustomerVal?.total_active_users}
                userList={todayActiveCustomerVal?.todays_active_users || []}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="secondary"
                icon="scoreboard"
                title="Average Interview Score"
                count={Number(avgInterviewSimVal).toFixed(2)}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="scoreboard"
                title="Average P Round Score"
                count={Number(avgPRoundScoreVal).toFixed(2)}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="warning"
                icon="pendingActions"
                title="Incomplete Simulation"
                count={`${orgIncompletionRatesVal?.incomplete_simulations || 0}/${
                  orgIncompletionRatesVal?.total_simulations || 0
                }`}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="warning"
                icon="pendingActions"
                title="Incomplete P Rounds"
                count={`${orgIncompletionRatesVal?.incomplete_trainings || 0}/${
                  orgIncompletionRatesVal?.total_trainings || 0
                }`}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="checklist"
                title="Resume Completion Rate"
                count={`${resumeCompletionRateVal?.completed_resumes_count || 0}/${
                  resumeCompletionRateVal?.total_resumes_count || 0
                }`}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
