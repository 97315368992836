import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authSlice from "./Slices/authSlice";
import usersSlice from "./Slices/usersSlice";
import themeSlice from "./Slices/themeSlice";
import profileSlice from "./Slices/profileSlice";
import subscriptionSlice from "./Slices/subscriptionSlice";
import BoardingSlice from "./Slices/BoardingSlice";
import ReportAnalyticsSlice from "./Slices/ReportAnalyticsSlice";
import questionsSlice from "./Slices/questionsSlice";
import roleSlice from "./Slices/roleSlice";
import roleCategorySlice from "./Slices/roleCategorySlice";
import ChatSlice from "./Slices/ChatSlice";
import adminSlice from "./Slices/adminSlice";
import TaskSlice from "./Slices/TaskSlice";
import suborganizationSlice from "./Slices/suborganizationSlice";

const reducers = combineReducers({
  auth: authSlice,
  users: usersSlice,
  oraganizationTheme: themeSlice,
  userManagment: usersSlice,
  orgProfile: profileSlice,
  subScription: subscriptionSlice,
  boarding: BoardingSlice,
  questions: questionsSlice,
  ReportAnalytics: ReportAnalyticsSlice,
  roles: roleSlice,
  roleCategories: roleCategorySlice,
  chats: ChatSlice,
  subadmin: adminSlice,
  task: TaskSlice,
  subOrganization: suborganizationSlice,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
