import { useEffect, useState } from "react";

import PropTypes from "prop-types";

import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import MDBox from "components/MDBox";

import breakpoints from "assets/theme/base/breakpoints";
import { Button } from "@mui/material";

function ReportsHeader({
  children,
  parentActiveState,
  setParentActiveState,
  title,
  handleDownloadReport,
  handleDisabled,
}) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);

    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
    setParentActiveState(newValue);
    setTabValue(newValue);
  };

  return (
    <MDBox position="relative" mb={5}>
      <MDBox display="flex" position="relative" minHeight="6rem" borderRadius="xl" />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid
          container
          spacing={3}
          alignItems="center"
          justifyContent="space-between"
          sx={{
            mt: 1,
          }}
        >
          <Grid
            item
            sx={{
              fontSize: "22px",
              fontWeight: "700",
              display: "flex",
              alignItems: "center",
            }}
          >
            {title}
          </Grid>
          {parentActiveState !== undefined ? (
            <Grid item xs={8} md={5} lg={4} sx={{ ml: "auto" }}>
              <AppBar position="static">
                <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                  <Tab
                    sx={{
                      width: "25px",
                    }}
                    label="Reports"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        reports
                      </Icon>
                    }
                  />
                  <Tab
                    sx={{
                      width: "25px",
                    }}
                    label="Analytics"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        analytics
                      </Icon>
                    }
                  />
                </Tabs>
              </AppBar>
            </Grid>
          ) : (
            <Button
              variant="contained"
              size="small"
              sx={{
                ml: 1.3,
                color: "#fff",
                bgcolor: "info",
                "&:hover": {
                  bgcolor: "darkslategray",
                },
              }}
              onClick={() => handleDownloadReport()}
              disabled={handleDisabled("create")}
            >
              Download Report
            </Button>
          )}
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

ReportsHeader.defaultProps = {
  children: "",
};

ReportsHeader.propTypes = {
  children: PropTypes.node,
};

export default ReportsHeader;
