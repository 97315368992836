import React from "react";
import { Box, Typography } from "@mui/material";

const TextLoader = ({ title, icon }) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <Box display="flex" flexDirection="column" alignItems="center">
        <img src={icon} alt="loader" style={{ width: "200px" }} />
        <Typography
          sx={{
            marginTop: "-30px",
            width: "60%",
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "22px",
            color: "#000",
          }}
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

export default TextLoader;
