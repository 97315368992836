import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import { Icon, Pagination } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Loader";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import Typography from "@mui/material/Typography";
import { getRouteByNumber } from "context";
import { permission } from "context";
import { featurePermissionsObject } from "context";
import { getAllTasks } from "store/Slices/TaskSlice";
import moment from "moment";
import { getJoinedUsers } from "store/Slices/usersSlice";
import ConfirmationModal from "../../components/ConfirmationModal";
import { deleteTask } from "store/Slices/TaskSlice";
import AssignTaskModal from "components/AssignTaskModal";
import { getSingleTask } from "store/Slices/TaskSlice";

const Tasks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { joinedUsers } = useSelector((state) => state.users);
  const { loading, allTasks, totalPages } = useSelector((state) => state.task);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [storeTaskId, setStoreTaskId] = useState(null);
  const [storePermissions, setStorePermissions] = useState(null);
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [storeSelectedId, setStoreSelectedId] = useState(null);
  const [storeActivePagination, setStoreActivePagination] = useState(null);
  const [open, setOpen] = useState(false);
  const [hoveredTaskId, setHoveredTaskId] = useState(null);
  const parsedObject = JSON.parse(user?.rolePermissions);
  let pagePermissions;
  let featurePermissions;
  if (parsedObject) {
    const [key, value] = Object.entries(parsedObject)[0];
    pagePermissions = key;
    featurePermissions = value;
  }

  const handleDeleteConfirmationToggle = () => {
    setDeleteConfirmationModal((prevState) => !prevState);
  };

  const handleAllDelete = () => {
    dispatch(
      deleteTask({
        payload: storeSelectedId,
        onSuccess: () => {
          dispatch(getAllTasks({ payload: storeActivePagination || 1 }));
          toast.success("Task Deleted Sucessfully");
        },
      })
    );
    handleDeleteConfirmationToggle();
  };

  const handleDisabled = (val) => {
    if (storePermissions) {
      if (storePermissions.hasOwnProperty(val)) {
        return !storePermissions[val];
      }
      return null;
    }
  };

  const handlePaginationChange = (event, value) => {
    setStoreActivePagination(value);
    dispatch(getAllTasks({ payload: value }));
  };

  const handleOpen = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const pillAssignSx = {
    padding: "8px 12px",
    borderRadius: "50%",
    backgroundColor: "#a78bfa",
    color: "#fff",
    textTransform: "capitalize",
  };
  const pillAssignSxIcon = {
    padding: "8px 10px",
    borderRadius: "50%",
    cursor: "pointer",
    backgroundColor: "#a78bfa",
    color: "#fff",
    textTransform: "capitalize",
    marginLeft: "8px",
  };

  useEffect(() => {
    setColumns([
      { Header: "Title", accessor: "title", align: "left" },
      { Header: "Due Date", accessor: "duedate", align: "center" },
      { Header: "description", accessor: "description", align: "center" },
      { Header: "Priority", accessor: "priority", align: "center" },
      { Header: "Assigned", accessor: "assign", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
    ]);

    const customAddUser =
      allTasks &&
      allTasks.length >= 1 &&
      allTasks.map((data, index) => {
        return {
          title: (
            <MDTypography
              component="a"
              href="#"
              variant="button"
              color="text"
              fontWeight="medium"
              key={index + 100}
            >
              {data?.task_details?.title}
            </MDTypography>
          ),
          duedate: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {moment(data?.task_details?.due_date).format("DD MMM YYYY")}
            </MDTypography>
          ),
          description: (
            <Box
              component="div"
              sx={{
                maxWidth: "200px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              title={data?.task_details?.description}
            >
              <MDTypography
                component="a"
                href="#"
                variant="button"
                color="text"
                fontWeight="medium"
                key={index + 100}
              >
                {data?.task_details?.description}
              </MDTypography>
            </Box>
          ),
          priority: (
            <Typography
              component="a"
              href="#"
              variant="caption"
              color="textPrimary"
              fontWeight="medium"
            >
              <Box
                component="span"
                sx={{
                  ...pillSx,
                  backgroundColor:
                    data?.task_details?.priority === "LOW"
                      ? "#388e3c"
                      : data?.task_details?.priority === "HIGH"
                      ? "#ef5350"
                      : data?.task_details?.priority === "MEDIUM"
                      ? "#f57c00"
                      : "inherit",
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                {data?.task_details?.priority}
              </Box>
            </Typography>
          ),
          assign: (
            <Typography
              variant="caption"
              color="textPrimary"
              fontWeight="medium"
              onMouseEnter={() => setHoveredTaskId(data?.task_details?.id)}
              onMouseLeave={() => setHoveredTaskId(null)}
              sx={{ cursor: "pointer", width: "100%", height: "100%" }}
            >
              <Box component="span" sx={pillAssignSx}>
                {data?.assigned_user_list?.length}
              </Box>

              {hoveredTaskId === data?.task_details?.id && (
                <Box
                  component="span"
                  sx={pillAssignSxIcon}
                  onClick={() => {
                    setStoreTaskId(data?.task_details?.id);
                    dispatch(
                      getSingleTask({
                        payload: data?.task_details?.id,
                        onSuccess: () => {
                          handleOpen();
                        },
                      })
                    );
                  }}
                >
                  <EditIcon sx={{ fontSize: "20px", color: "#fff" }} />
                </Box>
              )}
            </Typography>
          ),
          action: (
            <MDBox sx={{ display: "flex", justifyContent: "start", alignItems: "start" }}>
              <>
                <Link to={`/viewTask/${data?.task_details?.id}`}>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      ml: 1.3,
                      color: "#fff",
                      bgcolor: "#ef5350",
                      "&:hover": {
                        bgcolor: "darkslategray",
                      },
                    }}
                  >
                    <Icon color="#fff" sx={{ fontSize: "20px" }}>
                      visibility
                    </Icon>
                  </Button>
                </Link>
                <Button
                  variant="contained"
                  size="small"
                  disabled={handleDisabled("delete")}
                  onClick={() => {
                    handleDeleteConfirmationToggle();
                    setStoreSelectedId(data?.task_details?.id);
                  }}
                  sx={{
                    ml: 1.3,
                    color: "#fff",
                    bgcolor: "#ef5350",
                    "&:hover": {
                      bgcolor: "darkslategray",
                    },
                  }}
                >
                  <Icon color="#fff" sx={{ fontSize: "20px" }}>
                    delete
                  </Icon>
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  disabled={handleDisabled("update")}
                  onClick={() => navigate(`/createTask/${data?.task_details?.id}`)}
                  sx={{
                    ml: 1.3,
                    color: "#fff ",
                    bgcolor: "#ef5350",
                    "&:hover": {
                      bgcolor: "darkslategray",
                    },
                  }}
                >
                  <Icon color="#fff" sx={{ fontSize: "20px" }}>
                    <EditIcon />
                  </Icon>
                </Button>
              </>
            </MDBox>
          ),
        };
      });
    setRows(Array.isArray(customAddUser) ? customAddUser : []);
  }, [allTasks, getJoinedUsers, hoveredTaskId]);
  const pillSx = {
    display: "inline-block",
    padding: "0.2em 0.6em",
    borderRadius: "9999px",
    fontSize: "0.75rem",
    fontWeight: 500,
    textAlign: "center",
    color: "white",
  };
  useEffect(() => {
    if (user?.activeBundle == null) {
      toast.error("Please Active your Bundle");
      return navigate("/subscription");
    }
    if (pagePermissions !== permission.admin && pagePermissions !== permission.tasks) {
      const routeLink = getRouteByNumber(pagePermissions);
      return navigate(routeLink);
    }
    if (featurePermissions) {
      const data = featurePermissionsObject(featurePermissions);
      setStorePermissions(data);
      console.log(data, "featurePermissionsfeaturePermissions");
    }
  }, []);
  useEffect(() => {
    dispatch(getAllTasks({ payload: storeActivePagination || 1 }));
    dispatch(getJoinedUsers());
  }, []);

  return (
    <DashboardLayout>
      {loading && <Loader />}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Tasks MANAGEMENT
                </MDTypography>
                <Box>
                  <Button
                    variant="contained"
                    sx={{
                      color: "#fff",
                      bgcolor: "#000",
                      "&:hover": {
                        bgcolor: "darkslategray",
                      },
                    }}
                    disabled={handleDisabled("create")}
                    onClick={() => navigate("/createTask")}
                  >
                    Create New Task
                  </Button>
                </Box>
              </MDBox>
              <MDBox pt={3}>
                {allTasks && allTasks.length >= 1 ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                    checkBoxes={false}
                  />
                ) : (
                  <MDTypography
                    component="h2"
                    href="#"
                    color="text"
                    fontWeight="bold"
                    sx={{
                      textAlign: "center",
                      my: 10,
                    }}
                  >
                    YOU CAN'T ADD ANY TASK
                  </MDTypography>
                )}
              </MDBox>

              <MDBox
                m={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pagination count={totalPages} onChange={handlePaginationChange} />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <ConfirmationModal
        modalOpen={deleteConfirmationModal}
        modalToggle={handleDeleteConfirmationToggle}
        onSubmit={handleAllDelete}
        loading={loading}
      />
      <AssignTaskModal
        open={open}
        onClose={handleOpen}
        storeTaskId={storeTaskId}
        storeActivePagination={storeActivePagination}
      />
    </DashboardLayout>
  );
};

export default Tasks;
