/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import { ThemeProvider } from "@mui/material/styles";

import MDBox from "components/MDBox";

import Configurator from "examples/Configurator";
import Sidenav from "examples/Sidenav";

import theme from "assets/theme";

import themeDark from "assets/theme-dark";

import routes from "routes";

import { setMiniSidenav, setOpenConfigurator, useMaterialUIController } from "context";

import brandDark from "assets/images/logo-ct-dark.png";
import brandWhite from "assets/images/logo-ct.png";
import { useDispatch, useSelector } from "react-redux";
import { getOrgTheme } from "store/Slices/themeSlice";
import ProtectedRoute from "AuthGuard/ProtectedRoute";
import AuthRoutes from "AuthGuard/AuthRoutes";
import { getProfile } from "store/Slices/profileSlice";
import { toast } from "react-toastify";
import PackageExpireModal from "examples/Modal/PackageExpireModal";
import { getSubscription } from "store/Slices/authSlice";
import { getDeclinedError } from "store/Slices/subscriptionSlice";
import Loader from "components/Loader";

export default function App() {
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => state.auth);
  const { profile } = useSelector((state) => state.orgProfile);
  const { orgTheme } = useSelector((state) => state.oraganizationTheme);
  const [controller, dispatchController] = useMaterialUIController();
  const { miniSidenav, direction, layout, sidenavColor, darkMode } = controller;

  const { pathname } = useLocation();
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [openModal, setopenModal] = useState(false);
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatchController, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatchController, true);
      setOnMouseEnter(false);
    }
  };

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        const Element = route.route.startsWith("/authentication") ? (
          <AuthRoutes element={route.component} />
        ) : (
          <ProtectedRoute element={route.component} />
        );
        return <Route exact path={route.route} element={Element} key={route.key} />;
      }

      return null;
    });

  useEffect(() => {
    if (user) {
      dispatch(getOrgTheme())
        .then((res) => {})
        .catch((rejected) => toast.error(rejected?.response?.data?.detail));
      dispatch(
        getProfile({
          onSuccess: (profileData) => {},
        })
      )
        .then((res) => {})
        .catch((rejected) => toast.error(rejected?.response?.data?.detail));
    }

    if (!user?.subscriptionStatus) return;
    if (
      user?.subscriptionStatus == "active" ||
      user?.subscriptionStatus == "trialing" ||
      user?.subscriptionStatus == "canceled"
    )
      return;

    setSubscriptionLoading(true);
    dispatch(
      getDeclinedError({
        onSuccess: () => {
          setSubscriptionLoading(false);
          setopenModal(true);
        },
        onError: () => {
          setSubscriptionLoading(false);
        },
      })
    )
      .then((res) => {})
      .catch((rejected) => toast.error(rejected?.response?.data?.detail));
  }, [user]);

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      {/* {loading && <Loader />} */}
      {subscriptionLoading && <Loader />}
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={
              orgTheme?.file_url
                ? orgTheme?.file_url
                : orgTheme?.sidebar_type === "#000000"
                ? brandWhite
                : brandDark
            }
            brandName={profile?.name}
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        </>
      )}
      {/* {layout === "vr" && <Configurator />} */}
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
      <PackageExpireModal openModal={openModal} setopenModal={setopenModal} />
    </ThemeProvider>
  );
}
