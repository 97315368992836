import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import Papa from "papaparse";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { Icon, Pagination, colors } from "@mui/material";
import { useFormik } from "formik";
import { addUser } from "components/schema";
import { createUsers } from "store/Slices/usersSlice";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "store/Slices/usersSlice";
import Loader from "components/Loader";
import { revokeInvitaion } from "store/Slices/usersSlice";
import { refreshInvitation } from "store/Slices/usersSlice";
import { deleteInvitation } from "store/Slices/usersSlice";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import UsersCards from "examples/Cards/UsersCards";
import { getStats } from "store/Slices/usersSlice";
import InvitesTable from "./InvitesTable";
import { useDebounce } from "use-debounce";
import { getRouteByNumber } from "context";
import { permission } from "context";
import { getContextViewRedirectLink } from "store/Slices/usersSlice";
import { featurePermissionsObject } from "context";
import { getRemoveUsersLimit } from "store/Slices/usersSlice";
import { deleteSeats } from "store/Slices/usersSlice";

const Users = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { users, loading, paginationData, contextCopying } = useSelector(
    (state) => state.userManagment
  );
  const [userStats, setUserStats] = useState([]);
  // Modal states
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [storePermissions, setStorePermissions] = useState(null);
  // handle formData, column and row Data
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [Emails, setEmails] = useState([]);
  const [userDelete, setUserDelete] = useState("0");
  const [invitedModal, setInvitedModal] = useState(false);
  const [inviteData, setInviteData] = useState(null);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [maxDeleteLimit, setMaxDeleteLimit] = useState(0);
  const [debouncedSearch] = useDebounce(search, 500);
  const parsedObject = JSON.parse(user?.rolePermissions);
  let pagePermissions;
  let featurePermissions;
  if (parsedObject) {
    const [key, value] = Object.entries(parsedObject)[0];
    pagePermissions = key;
    featurePermissions = value;
  }

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: { email: "" },
    validationSchema: addUser,
    onSubmit: (values) => {
      setEmails([values.email]);
      resetForm();
      handleClose();
      getUserStats();
    },
  });

  // Modal open function
  const handleOpen = () => {
    setOpen(true);
  };

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  //Modal close function
  const handleClose = () => {
    setOpen(false);
    setDeleteOpen(false);
    setInvitedModal(false);
  };

  const getUserStats = () => {
    dispatch(
      getStats({
        onSuccess: (res) => {
          setUserStats(res);
        },
      })
    )
      .unwrap()
      .then((res) => {})
      .catch((rejected) => toast.error(rejected?.response?.data?.detail));
  };

  // csv file uploader function
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    Papa.parse(file, {
      header: true,
      complete: (result) => {
        const csvRows = result.data;
        const newEmails = csvRows?.filter((row) => row.email).map((row) => row.email);
        setEmails(newEmails);
        // setTimeout(() => {
        //   getUserFunction();
        // }, 4000);
      },
      error: (error) => {
        console.error("Error parsing CSV:", error);
      },
    });
  };
  // console.log({ Emails });

  const handleRevoke = (email, status) => {
    dispatch(
      revokeInvitaion({
        email,
        status,
        onSuccess: (res) => {
          getUserFunction();
          toast.success(res);
          getUserStats();
        },
      })
    )
      .unwrap()
      .then((res) => {})
      .catch((rejected) => toast.error(rejected?.response?.data?.detail));
  };
  const handleRefreshToken = (email) => {
    dispatch(
      refreshInvitation({
        email,
        onSuccess: (res) => {
          toast.success(res);
        },
      })
    )
      .unwrap()
      .then((res) => {})
      .catch((rejected) => toast.error(rejected?.response?.data?.detail));
    // console.log(email, "email");
  };
  const handleDelete = (email) => {
    dispatch(
      deleteInvitation({
        email,
        onSuccess: (res) => {
          getUserFunction();
          toast.success(res);
          getUserStats();
        },
      })
    )
      .unwrap()
      .then((res) => {})
      .catch((rejected) => toast.error(rejected?.response?.data?.detail));
    // console.log(email, "email");
  };

  const handleDeleteSubmit = (val) => {
    val.preventDefault();
    if (userDelete <= "0") return toast.error("Please Add Seats to delete");
    dispatch(
      deleteSeats({
        payload: userDelete,
        onSuccess: () => {
          getUserStats();
          toast.success("Seats Deleted Succesfully");
          setUserDelete("0");
        },
      })
    );
    handleClose();
  };

  useEffect(() => {
    const submitPayload = () => {
      const payload = {
        emails: Emails,
      };
      if (payload.emails.length > 0) {
        dispatch(createUsers(payload))
          .unwrap()
          .then((res) => {
            if (Emails?.length > 1) {
              setInviteData(res);
              setInvitedModal(true);
            } else {
              if (res.already_registered?.length > 0) {
                toast.error(`Email:${res.already_registered[0]} already registered `);
              }
              if (res.invited_by_other_org?.length > 0) {
                toast.error(
                  `Email:${res.already_registered[0]} already invited by other organization`
                );
              }
              if (res.new_added_emails?.length > 0) {
                toast.success(`Email:${res.new_added_emails[0]} Invited Sucessfully`);
              }
              if (res.refresh_invites?.length > 0) {
                toast.success(`Invitation Resent to Email:${res.refresh_invites[0]}`);
              }
            }
            getUserFunction();
          })
          .catch((rejected) => toast.error(rejected?.response?.data?.detail));
      }
    };
    submitPayload();
  }, [Emails]);

  useEffect(() => {
    setColumns([
      { Header: "Email", accessor: "email", align: "left" },
      { Header: "Created At", accessor: "createdAt", align: "center" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ]);
    // add user array
    const customAddUser =
      users &&
      users.length >= 1 &&
      users.map((data, index) => {
        return {
          email: (
            <MDTypography
              component="a"
              href="#"
              variant="button"
              color="text"
              fontWeight="medium"
              key={index + 100}
            >
              {data?.email}
            </MDTypography>
          ),
          createdAt: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {data?.created_on}
            </MDTypography>
          ),
          status: (
            <Typography
              component="a"
              href="#"
              variant="caption"
              color="textPrimary"
              fontWeight="medium"
            >
              <Box
                component="span"
                sx={{
                  ...pillSx,
                  backgroundColor:
                    data?.status === "joined"
                      ? "#388e3c"
                      : data?.status === "revoked"
                      ? "#ef5350"
                      : data?.status === "pending"
                      ? "#f57c00"
                      : "#ab47bc",
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                {data?.status}
              </Box>
            </Typography>
          ),
          action: (
            <MDBox sx={{ display: "flex", justifyContent: "start", alignItems: "start" }}>
              {data?.status == "joined" ? (
                <>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => dispatch(getContextViewRedirectLink(data?.customer_id))}
                    disabled={handleDisabled("create") || loading || contextCopying}
                    sx={{
                      ml: 1.3,
                      color: "#fff",
                      bgcolor: "#388e3c",
                      "&:hover": {
                        bgcolor: "darkslategray",
                      },
                    }}
                  >
                    {contextCopying ? "Loading..." : "Context View"}
                  </Button>
                  <Link to={`/user-details/${data?.customer_id}_${data?.customer_name}`}>
                    <Button
                      variant="contained"
                      size="small"
                      disabled={loading}
                      sx={{
                        ml: 1.3,
                        color: "#fff",
                        bgcolor: "#388e3c",
                        "&:hover": {
                          bgcolor: "darkslategray",
                        },
                      }}
                    >
                      User Details
                    </Button>
                  </Link>
                  <Button
                    variant="contained"
                    size="small"
                    disabled={handleDisabled("delete") || loading}
                    onClick={() => handleRevoke(data?.email, data?.status)}
                    sx={{
                      ml: 1.3,
                      color: "#fff",
                      bgcolor: "#ef5350",
                      "&:hover": {
                        bgcolor: "darkslategray",
                      },
                    }}
                  >
                    Revoke Subscription
                  </Button>
                </>
              ) : data?.status == "revoked" ? (
                <>
                  {" "}
                  <Button
                    variant="contained"
                    size="small"
                    disabled={handleDisabled("update") || loading}
                    onClick={() => handleRevoke(data?.email, data?.status)}
                    sx={{
                      ml: 1.3,
                      color: "#fff",
                      bgcolor: "#f57c00",
                      "&:hover": {
                        bgcolor: "darkslategray",
                      },
                    }}
                  >
                    Active Revoked
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    disabled={handleDisabled("delete") || loading}
                    onClick={() => handleDelete(data?.email)}
                    sx={{
                      ml: 1.3,
                      color: "#fff",
                      bgcolor: "#ef5350",
                      "&:hover": {
                        bgcolor: "darkslategray",
                      },
                    }}
                  >
                    <Icon color="#fff" sx={{ fontSize: "20px" }}>
                      delete
                    </Icon>
                  </Button>
                </>
              ) : data?.status == "pending" ? (
                <>
                  {" "}
                  <Button
                    variant="contained"
                    size="small"
                    disabled={handleDisabled("update") || loading}
                    onClick={() => handleRevoke(data?.email, data?.status)}
                    sx={{
                      ml: 1.3,
                      color: "#fff",
                      bgcolor: "info",
                      "&:hover": {
                        bgcolor: "darkslategray",
                      },
                    }}
                  >
                    Revoke Invitation
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    disabled={handleDisabled("update") || loading}
                    onClick={() => handleRefreshToken(data?.email)}
                    sx={{
                      ml: 1.3,
                      color: "#fff",
                      bgcolor: "info",
                      "&:hover": {
                        bgcolor: "darkslategray",
                      },
                    }}
                  >
                    Resend Invitation
                  </Button>
                </>
              ) : (
                <></>
              )}
            </MDBox>
          ),
        };
      });
    setRows(Array.isArray(customAddUser) ? customAddUser : []);
  }, [users]);

  useEffect(() => {
    getUserFunction();
  }, [page, debouncedSearch]);

  const getUserFunction = () => {
    let payload = `page=${page}&search=${debouncedSearch}`;

    dispatch(
      getUsers({
        payload,
        onSuccess: () => {
          setEmails([]);
        },
      })
    )
      .unwrap()
      .then((res) => {})
      .catch((rejected) => toast.error(rejected?.response?.data?.detail));
  };

  const setUserDeleteChange = (event) => {
    const value = event.target.value;
    if (value === "" || value === "-") {
      setUserDelete(value);
    } else {
      const numericValue = Number(value);
      if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= maxDeleteLimit) {
        setUserDelete(value);
      } else {
        toast.error(`Please enter a number between 0 and ${maxDeleteLimit}`);
      }
    }
  };

  const handleDisabled = (val) => {
    if (storePermissions) {
      if (storePermissions.hasOwnProperty(val)) {
        return !storePermissions[val];
      }
      return null;
    }
  };

  useEffect(() => {
    if (user?.activeBundle == null) {
      toast.error("Please Active your Bundle");
      return navigate("/subscription");
    }
  }, []);

  useEffect(() => {
    if (pagePermissions !== permission.admin && pagePermissions !== permission.users) {
      const routeLink = getRouteByNumber(pagePermissions);
      return navigate(routeLink);
    }
    if (featurePermissions) {
      const data = featurePermissionsObject(featurePermissions);
      setStorePermissions(data);
      console.log(data, "featurePermissionsfeaturePermissions");
    }
    getUserStats();
  }, []);

  const pillSx = {
    display: "inline-block",
    padding: "0.2em 0.6em",
    borderRadius: "9999px",
    fontSize: "0.75rem",
    fontWeight: 500,
    textAlign: "center",
    color: "white",
  };
  const styleModal = {
    width: "90vw", // use a percentage width for responsiveness
    maxWidth: "500px", // set a max width to prevent it from getting too large
    margin: "auto", // center the modal
    padding: "20px", // add some padding
    boxShadow: 24,
    borderRadius: "8px",
  };
  // console.log({ users });

  return (
    <DashboardLayout>
      {(loading || contextCopying) && <Loader />}
      <MDBox py={3}>
        <Grid container spacing={3}>
          {!user?.data?.parent_organization_name && (
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <UsersCards
                  icon="person_add"
                  title="Available Seats"
                  count={userStats?.stripe_quantity || "N/A"}
                />
              </MDBox>
            </Grid>
          )}
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <UsersCards
                color="dark"
                icon="person_add"
                title="Active Seats"
                count={userStats?.joined_users_count || "N/A"}
                // count={
                //   userStats?.stripe_quantity
                //     ? userStats?.stripe_quantity -
                //       (userStats?.joined_users_count + userStats?.pending_users_count)
                //     : 0
                // }
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <UsersCards
                color="success"
                icon="person_add"
                title="Pending Seats"
                // count={userStats?.joined_users_count + userStats?.pending_users_count}
                count={userStats?.pending_users_count || "N/A"}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  USERS MANAGEMENT
                </MDTypography>
                <Box>
                  <Button
                    variant="contained"
                    sx={{
                      color: "#fff",
                      bgcolor: "#000",
                      "&:hover": {
                        bgcolor: "darkslategray",
                      },
                    }}
                    disabled={handleDisabled("delete")}
                    onClick={() => {
                      dispatch(
                        getRemoveUsersLimit({
                          onSuccess: (res) => {
                            setMaxDeleteLimit(res);
                          },
                        })
                      );
                      handleDeleteOpen();
                    }}
                  >
                    Delete Seats
                  </Button>
                  <input
                    type="file"
                    accept=".csv"
                    style={{ display: "none" }}
                    id="file-input"
                    onChange={handleFileInputChange}
                  />
                  <label htmlFor="file-input">
                    <Button
                      disabled={handleDisabled("create")}
                      variant="contained"
                      sx={{
                        marginRight: "10px",
                        marginLeft: "10px",
                        color: "#fff",
                        bgcolor: "#000",
                        "&:hover": {
                          bgcolor: "darkslategray",
                        },
                      }}
                      component="span"
                    >
                      Import (csv)
                    </Button>
                  </label>
                  <Button
                    variant="contained"
                    sx={{
                      color: "#fff",
                      bgcolor: "#000",
                      "&:hover": {
                        bgcolor: "darkslategray",
                      },
                    }}
                    disabled={handleDisabled("create")}
                    onClick={handleOpen}
                  >
                    Add New
                  </Button>
                </Box>
              </MDBox>
              <MDBox pt={3}>
                {users && users.length >= 1 ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                    checkBoxes={false}
                  />
                ) : (
                  <MDTypography
                    component="h2"
                    href="#"
                    color="text"
                    fontWeight="bold"
                    sx={{
                      textAlign: "center",
                      my: 10,
                    }}
                  >
                    YOU CAN'T ADD ANY USERS
                  </MDTypography>
                )}
              </MDBox>

              <MDBox
                m={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pagination
                  count={paginationData?.total_pages}
                  page={page}
                  onChange={handlePageChange}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {/* user Page ENd */}

      {/* Add Users modal box */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
          <Card sx={styleModal}>
            <Box
              variant="gradient"
              bgcolor="info.main"
              borderRadius="12px"
              boxShadow={3}
              mx={2}
              p={2}
              mb={1}
              textAlign="center"
            >
              <Typography variant="h4" fontWeight="medium" color="white.main" mt={1} mb={1}>
                Add New User
              </Typography>
            </Box>
            <Box mx={1} p={1} mt={1}>
              <form onSubmit={handleSubmit}>
                <Box mb={2}>
                  <MDInput
                    type="email"
                    label="Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    variant="outlined"
                  />
                  {errors.email && touched.email && (
                    <Typography color="error" sx={{ fontSize: "13px" }}>
                      {errors.email}
                    </Typography>
                  )}
                </Box>

                <Box mt={4} mb={1} sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
                  <MDButton variant="gradient" color="info" onClick={handleClose}>
                    Cancel
                  </MDButton>
                  <MDButton variant="gradient" color="info" type="submit">
                    Submit
                  </MDButton>
                </Box>
              </form>
            </Box>
          </Card>
        </Grid>
      </Modal>
      <Modal
        open={invitedModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <InvitesTable inviteData={inviteData} handleClose={handleClose} />
      </Modal>
      {/* End Users modal box */}

      {/* Delete User modal box */}
      <Modal
        open={deleteOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
          <Card sx={styleModal}>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              p={2}
              mb={1}
              textAlign="center"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1} mb={1}>
                Delete Seats
              </MDTypography>
            </MDBox>
            <MDBox mx={1} p={1} mt={1}>
              <form onSubmit={handleDeleteSubmit}>
                <MDBox mb={2}>
                  <MDInput
                    type="number"
                    label="Delete Seats"
                    name="delete"
                    value={userDelete}
                    onChange={setUserDeleteChange}
                    min={0}
                    max={5}
                    fullWidth
                    disabled={!maxDeleteLimit}
                  />
                  {!maxDeleteLimit && (
                    <Typography color="error" sx={{ fontSize: "13px" }}>
                      No Seats are avaliable to delete
                    </Typography>
                  )}
                </MDBox>

                <MDBox mt={4} mb={1} sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
                  <MDButton variant="gradient" color="info" onClick={handleClose}>
                    Cancel
                  </MDButton>
                  <MDButton
                    variant="gradient"
                    color="info"
                    type="submit"
                    disabled={!maxDeleteLimit}
                  >
                    Submit
                  </MDButton>
                </MDBox>
              </form>
            </MDBox>
          </Card>
        </Grid>
      </Modal>

      {/* Delete Users modal box */}
    </DashboardLayout>
  );
};

export default Users;
