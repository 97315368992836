import { Box, TextField, TextareaAutosize } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import React from "react";
import Select from "react-select";
import DropDowns from "./DropDowns";
import { Label } from "@mui/icons-material";
import { createByChatGpt } from "store/Slices/questionsSlice";
import { useDispatch, useSelector } from "react-redux";
import { getQuestions } from "store/Slices/questionsSlice";
const bodyStyle = {
  height: "auto",
};
export default function ChatGptQuestions({
  chatgptQuestions,
  setChatgptQuestions,
  rolesDetails,
  categoriesDetails,
  setAddModalOpen,
  debouncedSearch,
  questionsType,
}) {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.questions);
  const handleQuestionTextChange = (event) => {
    const updatedQuestions = { ...chatgptQuestions, questionNo: event.target.value };
    setChatgptQuestions(updatedQuestions);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let payload = {
      role_category_id: chatgptQuestions?.role_category_id,
      question_type_id: chatgptQuestions?.question_type_id,
      no_of_questions: chatgptQuestions?.questionNo !== "" ? chatgptQuestions?.questionNo : "1",
    };
    dispatch(
      createByChatGpt({
        payload,
        onSuccess: () => {
          setChatgptQuestions({
            question_type_id: "",
            role_category_id: "",
            questionNo: "",
            roleId: "",
          });

          dispatch(getQuestions({ page: 1, search: debouncedSearch }));
        },
      })
    );
    setAddModalOpen(false);
  };
  return (
    <form onSubmit={handleSubmit}>
      <Box sx={bodyStyle}>
        <Box mb={2}>
          <DropDowns
            rolesDetails={rolesDetails}
            question={chatgptQuestions}
            categoriesDetails={categoriesDetails}
            Questions={chatgptQuestions}
            setQuestions={setChatgptQuestions}
            index={""}
            questionsType={questionsType}
          />
          <TextField
            type="number"
            inputProps={{ min: 1 }}
            placeholder="Example:500"
            value={chatgptQuestions.questionNo}
            onChange={(event) => handleQuestionTextChange(event)}
            style={{ width: "100%", marginTop: "12px" }}
            required
          />
        </Box>
      </Box>
      <MDBox mt={4} mb={1} sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
        <MDButton
          variant="gradient"
          color="info"
          onClick={() => setAddModalOpen(false)}
          disabled={loading}
        >
          Cancel
        </MDButton>
        <MDButton variant="gradient" color="info" type="submit" disabled={loading}>
          {loading ? "Loading..." : "Submit"}
        </MDButton>
      </MDBox>
    </form>
  );
}
