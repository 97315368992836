import { Box, Button, Card, Grid, Modal, Typography } from "@mui/material";
import Loader from "components/Loader";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import TypingEffect from "components/TypingEffect";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleSkillGap } from "store/Slices/BoardingSlice";
import { sendSkillGapReport } from "store/Slices/BoardingSlice";
import { toast } from "react-toastify";
import { htmlToText } from "html-to-text";
import { Document, Page, pdf, StyleSheet, Text, View } from "@react-pdf/renderer";
import TextLoader from "components/TextLoader";
import loadingImage from "assets/images/loading.png";

const SkillsGapDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const spiltID = id?.split("_")?.[0];
  const analysisRef = useRef(null);
  const { skillGapDetail, singleSkillGap, loading, mailLoading } = useSelector(
    (state) => state.boarding
  );
  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState("");
  const [storeHtmlPDF, setStoreHtmlPDF] = useState("");
  console.log(storeHtmlPDF, "storeHtmlPDF");

  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };

  const generatePdf = async () => {
    const doc = <SkillGapPDF />;
    const pdfInstance = pdf(doc);
    const blob = await pdfInstance.toBlob();

    return blob;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const pdfBlob = await generatePdf();

    const pdfFile = new File([pdfBlob], "skill_gap_analysis.pdf", {
      type: "application/pdf",
    });

    const payload = {
      subject: "Skill Gap Analysis Report",
      recipient_email: email,
      body: `
  Dear ${email},

  I hope this message finds you well.

  Please find attached the PDF document outlining the results of our recent skill gap analysis. This report provides a comprehensive evaluation of the current skills within your team and highlights areas where development opportunities exist.

  In the attached document, you will find:
  - An overview of the current skill levels and competencies.
  - Detailed analysis of the skill gaps identified.
  - Recommendations for targeted training and development to bridge these gaps.

  Should you have any questions or need further clarification on any aspect of the report, please do not hesitate to reach out.

  Thank you for your attention to this matter. I look forward to discussing how we can address these gaps effectively.

  Best regards,
  The Nexa Team
  http://stagingnexa.com
  support@nexa.com
      `,
      attachments: pdfFile,
    };
    dispatch(
      sendSkillGapReport({
        payload,
        onSuccess: () => {
          setEmail("");
          toast.success(`Email sent to ${email}`);
        },
      })
    );
    handleOpenModal();
  };

  const styles = StyleSheet.create({
    page: {
      padding: 30,
    },
    section: {
      margin: 10,
      padding: 10,
    },
    heading: {
      fontSize: 20,
      marginBottom: 10,
    },
    questionHeading: {
      fontSize: 20,
      marginBottom: 20,
    },
    text: {
      fontSize: 12,
      marginBottom: 10,
      lineHeight: 2.5,
    },
    htmltext: {
      fontSize: 12,
      marginBottom: 10,
      marginTop: 20,
      lineHeight: 1.5,
    },
  });

  const SkillGapPDF = () => (
    <Document>
      <Page style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.questionHeading}>Questions and Answers</Text>
          {singleSkillGap?.questions_answers.map((qa, index) => (
            <Text key={index} style={styles.text}>
              {qa.question}
              {"\n"}
              User Response: {qa.answer}
            </Text>
          ))}
        </View>
        <View style={styles.section}>
          <Text style={styles.heading}>Skill Gap Details</Text>
          <Text style={styles.htmltext}>{storeHtmlPDF}</Text>
        </View>
      </Page>
    </Document>
  );
  useEffect(() => {
    if (skillGapDetail) {
      analysisRef.current.scrollIntoView({ behavior: "smooth" });
      const htmlContent = skillGapDetail;

      const plainText = htmlToText(htmlContent, {
        wordwrap: 130,
      });
      setStoreHtmlPDF(plainText);
    }
  }, [skillGapDetail]);
  useEffect(() => {
    dispatch(getSingleSkillGap({ payload: spiltID, onSuccess: () => {} }));
  }, []);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "45%",
    boxShadow: 24,
    p: 4,
    maxHeight: "80vh",
    overflowY: "auto",
  };
  return (
    <DashboardLayout>
      {mailLoading && <Loader />}
      {loading && (
        <TextLoader
          title="Please wait while we generate your clients perfect skills gap assessment to help them take actionable goals toward their career journey !"
          icon={loadingImage}
        />
      )}
      {!loading && (
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDTypography variant="h5" color="white">
                    Skill Gap Analysis
                  </MDTypography>
                  <Box>
                    <Button
                      variant="contained"
                      sx={{
                        color: "#fff",
                        bgcolor: "#000",
                        "&:hover": {
                          bgcolor: "darkslategray",
                        },
                      }}
                      onClick={handleOpenModal}
                    >
                      Send Email
                    </Button>
                  </Box>{" "}
                </MDBox>
                <MDBox ref={analysisRef} px={8} pt={3} mb={2}>
                  <TypingEffect storeFetchValue={skillGapDetail} />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      )}

      <Modal
        open={openModal}
        onClose={handleOpenModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style}>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1} mb={1}>
              Skills Gap Analysis
            </MDTypography>
          </MDBox>
          <MDBox mx={1} p={1} mt={1}>
            <form onSubmit={handleSubmit}>
              <MDBox mb={2}>
                <Box>
                  <MDBox px={1} pt={3} mb={1}>
                    <MDInput
                      type="email"
                      label="Email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      fullWidth
                    />
                  </MDBox>
                </Box>
              </MDBox>
              <MDBox mt={4} mb={1} sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
                <MDButton variant="gradient" color="info" onClick={handleOpenModal}>
                  Cancel
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="info"
                  type="submit"
                  // onClick={handleSubmit}
                >
                  Submit
                </MDButton>
              </MDBox>
            </form>
          </MDBox>
        </Card>
      </Modal>
    </DashboardLayout>
  );
};

export default SkillsGapDetail;
