import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";
const dashRepo = RepositoryFactory.get("Dashboard");

export const checkOutSession = createAsyncThunk(
  "subscription/checkOutSession",
  async (payload, { rejectWithValue }) => {
    try {
      // console.log(payload, "payload");
      // let data;
      // if (payload?.quantity == null) {
      const { data } = await dashRepo.checkOutSessionBundle(payload);
      // alert("bundle Activate");
      // } else {
      //   data = await dashRepo.checkOutSession(payload);
      // }
      if (data?.success == true) {
        return data || "";
      } else {
        toast.error(data?.detail);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const CancelationActiveBundle = createAsyncThunk(
  "subscription/CancelationActiveBundle",
  async ({ pricingId, quantity }, { rejectWithValue }) => {
    try {
      const payload = {
        pricingId,
        quantity,
      };
      const { data } = await dashRepo.CancelationActiveBundle(payload);

      if (data?.status_code == 200) {
        return data;
      } else {
        toast.error(data?.detail);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getInvoices = createAsyncThunk(
  "subscription/getInvoices",
  async ({ onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.getInvoices();
      // console.log(data, "databyahsan");
      if (data?.status_code == 200) {
        onSuccess();
        return data?.data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const cancelSubscription = createAsyncThunk(
  "subscription/cancel",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.cancelSub();
      toast.success(data?.message);
      return data.message;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getDeclinedError = createAsyncThunk(
  "getDeclinedError/cancel",
  async ({ onSuccess, onError }, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.getDeclinedError();
      if (data?.status_code == 200) {
        onSuccess();
      }
      return data?.data?.declined_payments[0];
    } catch (err) {
      onError();
      return rejectWithValue(err);
    }
  }
);

export const handleRevokeSubscriptionCancel = createAsyncThunk(
  "handleRevokeSubscriptionCancel/cancel",
  async ({ onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.handleRevokeSubscriptionCancel();
      if (data) {
        onSuccess();
        toast.success(data?.message);
      }
      return data?.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// export const getAllUsers = createAsyncThunk(
//   "subscription/getAllUsers",
//   async ({ onSuccess }, { rejectWithValue }) => {
//     try {
//       const { data } = await dashRepo.getAllUsers();
//       // console.log(data, "databyahsan");
//       if (data?.status_code == 200) {
//         onSuccess();
//         return data?.data;
//       }
//     } catch (err) {
//       return rejectWithValue(err);
//     }
//   }
// );

const initialState = {
  invoices: [],
  loading: false,
  error: null,
  declineError: null,
};

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(checkOutSession.pending, (state) => {
        // console.log("Running");
        state.loading = true;
        state.error = null;
      })
      .addCase(checkOutSession.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(checkOutSession.rejected, (state, action) => {
        // console.log("Error");
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getInvoices.pending, (state) => {
        // console.log("Running");
        state.loading = true;
        state.error = null;
      })
      .addCase(getInvoices.fulfilled, (state, action) => {
        // console.log(action.payload, "action.payloadaction.payload");
        state.loading = false;
        state.invoices = action.payload;
      })
      .addCase(getInvoices.rejected, (state, action) => {
        // console.log("Error");
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(cancelSubscription.pending, (state) => {
        state.loading = true;
      })
      .addCase(cancelSubscription.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(cancelSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getDeclinedError.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDeclinedError.fulfilled, (state, action) => {
        state.loading = false;
        state.declineError = action.payload;
      })
      .addCase(getDeclinedError.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(handleRevokeSubscriptionCancel.pending, (state) => {
        state.loading = true;
      })
      .addCase(handleRevokeSubscriptionCancel.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(handleRevokeSubscriptionCancel.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default subscriptionSlice.reducer;
