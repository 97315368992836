import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { ToastContainer } from "react-toastify";
import "./assets/css/loader.css";
import "./assets/css/privacyPolicy.css";
import "./assets/css/Pricing.css";
import "./assets/css/skillsGap.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/task.css";

import { MaterialUIControllerProvider } from "context";
import Providers from "store/Providers";
const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <ToastContainer />
    <Providers>
      <MaterialUIControllerProvider>
        <App />
      </MaterialUIControllerProvider>
    </Providers>
  </BrowserRouter>
);
