import { Box, Paper, Typography } from "@mui/material";
import FeedbackMessageComponent from "components/FeedbackMessageComponent";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import FeedbackMessageComponent from "../FeedbackMessageComponent";

const TaskFeedbackSection = () => {
  const { getSingleTaskData, loading } = useSelector((state) => state.task);
  const [modifiedFeedbacks, setModifiedFeedbacks] = useState([]);
  console.log(modifiedFeedbacks, "modifiedFeedbacks");
  const singleTask = {
    comments: [
      {
        created_at: "2022-11-22T10:30:00.000Z",
        message: "hello",
      },
      {
        created_at: "2022-11-22T10:30:00.000Z",
        message: "hello",
      },
      {
        created_at: "2022-11-22T10:30:00.000Z",
        message: "hello",
      },
      {
        created_at: "2022-11-22T10:30:00.000Z",
        message: "hello",
      },
      {
        created_at: "2022-11-22T10:30:00.000Z",
        message: "hello",
      },
    ],
  };

  useEffect(() => {
    // setqueryLength(singleTask?.feedbacks?.length);
    const restructuredData = getSingleTaskData?.feedbacks?.reduce((acc, feedback) => {
      const feedbackDate = moment(feedback?.created_at).format("MM/DD/YYYY");
      const existingDateIndex = acc?.findIndex((item) => item.date === feedbackDate);
      if (existingDateIndex !== -1) {
        acc[existingDateIndex]?.feedbacks.push(feedback);
      } else {
        acc.push({
          date: feedbackDate,
          feedbacks: [feedback],
        });
      }
      return acc;
    }, []);
    setModifiedFeedbacks(restructuredData);
  }, [singleTask]);

  return (
    <>
      {getSingleTaskData?.feedbacks?.length <= 0 && (
        <Box sx={{ textAlign: "center", my: 6 }}>
          <Typography variant="h5" fontWeight="bold">
            No feedbacks Found
          </Typography>
        </Box>
      )}
      {modifiedFeedbacks?.map((item, index) => (
        <Box key={index}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              position: "sticky",
              top: 0,
              zIndex: 1,
            }}
          >
            <Paper
              elevation={2}
              sx={{
                border: "1px solid black",
                color: "black",
                fontWeight: "bold",
                borderRadius: "8px",
                p: 1,
                backgroundColor: "white",
                fontSize: "14px",
              }}
            >
              {moment(item?.date).format("DD-MM-YYYY")}
            </Paper>
          </Box>

          {item?.feedbacks?.map((feedback, feedbackIndex) => (
            <FeedbackMessageComponent key={feedbackIndex} feedback={feedback} />
          ))}
        </Box>
      ))}
    </>
  );
};

export default TaskFeedbackSection;
