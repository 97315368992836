import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import Loader from "components/Loader";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { featurePermissionsObject } from "context";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getDashboardTrainingStats,
  getDashboardSimulationStats,
  getOrgResumeStats,
} from "store/Slices/profileSlice";
import { loginFrequency } from "store/Slices/usersSlice";
import { simulationScorePerUser } from "store/Slices/usersSlice";
import { pRoundScorePerUser } from "store/Slices/usersSlice";
import { simulationSessionTopScore } from "store/Slices/usersSlice";
import { incompletionRates } from "store/Slices/usersSlice";
import { pRoundSessionTopScore } from "store/Slices/usersSlice";
import { timeSpent } from "store/Slices/usersSlice";
import { careerReadiness } from "store/Slices/usersSlice";
import { summaryReport } from "store/Slices/usersSlice";
import { monthlyAvgScore } from "store/Slices/usersSlice";
import {
  getJoinedUsers,
  getUserAvgScore,
  getUserCompletedResumes,
  getUserFeedbackSatisfaction,
  getUserSimulationStats,
  getUserSimultionStatus,
  getUserTrainingStats,
  getUserTrainingStatus,
  userResumeStats,
} from "store/Slices/usersSlice";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const Analytics = ({ featurePermissions }) => {
  const dispatch = useDispatch();
  const { joinedUsers } = useSelector((state) => state.userManagment);
  const {
    userTrainingStats,
    userSimulationStats,
    userResumes,
    compltedResumeCount,
    averageScore,
    satisfaction,
    trainingStatus,
    simulationStatus,
    statsLoading,
    roundsCompletionData,
    simulationData,

    monthlyAvgScoreVal,
    careerReadinessVal,
    summaryReportVal,
    loginFrequencyVal,
    timeSpentVal,
    simulationScorePerUserVal,
    pRoundScorePerUserVal,
    simulationSessionTopScoreVal,
    pRoundSessionTopScoreVal,
    incompletionRatesVal,
    loading,
  } = useSelector((state) => state.users);
  const [singleUser, setSingleUser] = useState(null);
  const [storePermissions, setStorePermissions] = useState(null);

  const { dashboardTrainingStats, dashboardSimulationStats, orgResume } = useSelector(
    (state) => state.orgProfile
  );

  const formatTimeSpent = (minutes) => {
    const duration = moment.duration(minutes, "minutes");
    const hours = Math.floor(duration.asHours());
    const mins = duration.minutes();
    return `${hours}hr : ${mins} min`;
  };

  const getJoinedUsersFunction = () => {
    dispatch(getJoinedUsers())
      .unwrap()
      .then((res) => {})
      .catch((rejected) => toast.error(rejected?.response?.data?.detail));
  };

  const handleDisabled = (val) => {
    if (storePermissions) {
      if (storePermissions.hasOwnProperty(val)) {
        return !storePermissions[val];
      }
      return null;
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF();

    // Customer Info
    doc.setFontSize(18);
    doc.text("Customer Information", 14, 22);

    const customerInfo = summaryReportVal.customer_info;
    doc.setFontSize(12);
    doc.text(`Name: ${customerInfo.name}`, 14, 32);
    doc.text(`Contact: ${customerInfo.contact}`, 14, 38);
    doc.text(`Employment Status: ${customerInfo.employment_status}`, 14, 44);
    doc.text(`Education: ${customerInfo.education}`, 14, 50);
    doc.text(`Address: ${customerInfo.address}`, 14, 56);
    doc.text(`LinkedIn Profile: ${customerInfo.linkedIn_profile}`, 14, 62);

    // Onboarding Info
    doc.setFontSize(18);
    doc.text("Onboarding Information", 14, 72);

    const onboardingData = summaryReportVal.onboarding_info.map((info) => [
      info.question,
      info.answer,
    ]);
    doc.autoTable({
      startY: 78,
      head: [["Question", "Answer"]],
      body: onboardingData,
      theme: "striped",
      headStyles: { fillColor: [22, 160, 133] },
      columnStyles: {
        0: { cellWidth: 60 },
        1: { cellWidth: "auto" },
      },
      margin: { top: 10 },
      styles: {
        cellPadding: 3,
        fontSize: 10,
        overflow: "linebreak",
      },
    });

    // Training Sessions
    const finalY = doc.lastAutoTable.finalY + 10;
    doc.setFontSize(18);
    doc.text("Training Sessions", 14, finalY);

    const trainingSessions = summaryReportVal.training_sessions;
    doc.setFontSize(12);
    doc.text(`Total Count: ${trainingSessions.total_count}`, 14, finalY + 10);
    doc.text(`Abandon Count: ${trainingSessions.abandon_count}`, 14, finalY + 16);
    doc.text(
      `Average Score: ${Number(trainingSessions?.average_score || 0).toFixed(2)}`,
      14,
      finalY + 22
    );

    // Simulation Sessions
    const simFinalY = finalY + 32;
    doc.setFontSize(18);
    doc.text("Simulation Sessions", 14, simFinalY);

    const simulationSessions = summaryReportVal.simulation_sessions;
    doc.setFontSize(12);
    doc.text(`Total Count: ${simulationSessions.total_count}`, 14, simFinalY + 10);
    doc.text(`Abandon Count: ${simulationSessions.abandon_count}`, 14, simFinalY + 16);
    doc.text(
      `Average Score: ${Number(simulationSessions?.average_score || 0).toFixed(2)}`,
      14,
      simFinalY + 22
    );

    doc.save("summary-report.pdf");
  };

  const prepareChartData = (data) => {
    const sortedEntries = Object.entries(data).sort((a, b) => {
      const monthA = parseInt(a[0].split("-")[1], 10); // Extract the month number
      const monthB = parseInt(b[0].split("-")[1], 10); // Extract the month number
      return monthA - monthB; // Sort in ascending order of months
    });

    // Separate the sorted entries into labels and values
    const labels = sortedEntries.map((entry) => entry[0]);
    const values = sortedEntries.map((entry) => entry[1]);

    return {
      labels,
      datasets: [
        {
          label: "Monthly Average Score",
          data: values,
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
      ],
    };
  };

  const chartData = prepareChartData(monthlyAvgScoreVal);

  useEffect(() => {
    if (featurePermissions) {
      const data = featurePermissionsObject(featurePermissions);
      setStorePermissions(data);
      console.log(data, "featurePermissionsfeaturePermissions");
    }
    dispatch(getDashboardTrainingStats());
    dispatch(getDashboardSimulationStats());
    dispatch(getOrgResumeStats());
    getJoinedUsersFunction();
  }, []);

  useEffect(() => {
    if (singleUser) {
      dispatch(getUserTrainingStats(singleUser));
      dispatch(getUserSimulationStats(singleUser));
      dispatch(getUserTrainingStatus(singleUser));
      dispatch(getUserSimultionStatus(singleUser));
      dispatch(userResumeStats(singleUser));
      dispatch(getUserCompletedResumes(singleUser));
      dispatch(getUserAvgScore(singleUser));
      dispatch(getUserFeedbackSatisfaction(singleUser));

      dispatch(monthlyAvgScore(singleUser));
      dispatch(careerReadiness(singleUser));
      dispatch(summaryReport(singleUser));
      dispatch(loginFrequency(singleUser));
      dispatch(timeSpent(singleUser));
      dispatch(simulationScorePerUser(singleUser));
      dispatch(pRoundScorePerUser(singleUser));
      dispatch(simulationSessionTopScore(singleUser));
      dispatch(pRoundSessionTopScore(singleUser));
      dispatch(incompletionRates(singleUser));
    }
  }, [singleUser]);

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <MDBox
          mx={2}
          mt={-3}
          mb={5}
          py={2}
          px={2}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <MDTypography variant="h6" color="white">
            Analytics
          </MDTypography>
        </MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="Practice Rounds"
                count={dashboardTrainingStats?.total_sessions}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Interviews Taken"
                count={dashboardSimulationStats?.total_sessions}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Resumes"
                count={orgResume}
              />
            </MDBox>
          </Grid>

          {/* <p>User Analytics</p> */}

          {/* <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="CV's Generated"
                count="+91"
              />
            </MDBox>
          </Grid> */}
        </Grid>
        <Grid container spacing={2} my={3}>
          <MDBox mx={2} mt={4} display="flex" justifyContent="space-between" alignItems="center">
            <MDTypography variant="h6">User's Reports</MDTypography>
          </MDBox>
          <Grid item xs={12} md={12} lg={12}>
            <MDBox mb={2}>
              <Box>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Select User</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="account"
                    value={singleUser}
                    onChange={(e) => setSingleUser(e.target.value)}
                    disabled={handleDisabled("create")}
                    label="Select User"
                  >
                    {joinedUsers?.map((user, index) => {
                      return (
                        <MenuItem key={index} value={user?.customer_id}>
                          {user?.customer_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            </MDBox>
            {statsLoading || loading ? (
              <Loader />
            ) : (
              <>
                {singleUser && (
                  <MDBox pt={6} pb={3}>
                    <Grid container spacing={6}>
                      <Grid item xs={12}>
                        <Grid container spacing={3}>
                          <Grid
                            display="flex"
                            justifyContent="end"
                            item
                            xs={12}
                            sx={{ paddingTop: "0px !important" }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              sx={{ color: "#fff", marginBottom: "8px" }}
                              onClick={generatePDF}
                            >
                              Download Summary Report
                            </Button>
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                              <ComplexStatisticsCard
                                color="dark"
                                icon="model_training"
                                title="Practice Rounds"
                                count={userTrainingStats?.total_sessions}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                              <ComplexStatisticsCard
                                icon="leaderboard"
                                title="Interview Simulation Taken"
                                count={userSimulationStats?.total_sessions}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                              <ComplexStatisticsCard
                                color="success"
                                icon="article"
                                title="Resumes"
                                count={userResumes}
                              />
                            </MDBox>
                          </Grid>

                          <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                              <ComplexStatisticsCard
                                color="info"
                                icon="person"
                                title="Resumes Completed"
                                count={compltedResumeCount}
                              />
                            </MDBox>
                          </Grid>

                          {/* NEW Cards */}
                          <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                              <ComplexStatisticsCard
                                color="success"
                                icon="model_training"
                                title="P Rounds Completion"
                                displayIcon={trainingStatus ? "check" : "close"}
                                categories={roundsCompletionData?.categories}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                              <ComplexStatisticsCard
                                color="warning"
                                icon="leaderboard"
                                title="Interview Simulation Completion"
                                displayIcon={simulationStatus ? "check" : "close"}
                                categories={simulationData?.categories}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                              <ComplexStatisticsCard
                                color="primary"
                                icon="article"
                                title="Avg Interview Score"
                                count={averageScore}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                              <ComplexStatisticsCard
                                color="secondary"
                                icon="grading"
                                title="Feedback and Satisfaction"
                                count={satisfaction}
                              />
                            </MDBox>
                          </Grid>

                          <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                              <ComplexStatisticsCard
                                color="primary"
                                icon="update"
                                title="Career Readiness"
                                count={careerReadinessVal}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                              <ComplexStatisticsCard
                                color="dark"
                                icon="login"
                                title="Login Frequency"
                                count={loginFrequencyVal}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                              <ComplexStatisticsCard
                                icon="timelapse"
                                title="Time Spent On Platform"
                                count={formatTimeSpent(timeSpentVal)}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                              <ComplexStatisticsCard
                                color="success"
                                icon="scoreboard"
                                title="Average Simulation Score"
                                count={Number(simulationScorePerUserVal).toFixed(2)}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                              <ComplexStatisticsCard
                                color="success"
                                icon="scoreboard"
                                title="Average P Rounds Score"
                                count={Number(pRoundScorePerUserVal).toFixed(2)}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                              <ComplexStatisticsCard
                                color="success"
                                icon="scoreboard"
                                title="Top Simulation Score"
                                count={Number(simulationSessionTopScoreVal).toFixed(2)}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                              <ComplexStatisticsCard
                                color="success"
                                icon="scoreboard"
                                title="Top P Rounds Score"
                                count={Number(pRoundSessionTopScoreVal).toFixed(2)}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                              <ComplexStatisticsCard
                                color="warning"
                                icon="pendingActions"
                                title="Incomplete Simulations"
                                count={`${incompletionRatesVal?.incomplete_simulations || 0}/${
                                  incompletionRatesVal?.total_simulations || 0
                                }`}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                              <ComplexStatisticsCard
                                color="warning"
                                icon="pendingActions"
                                title="Incomplete P Rounds"
                                count={`${incompletionRatesVal?.incomplete_trainings || 0}/${
                                  incompletionRatesVal?.total_trainings || 0
                                }`}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12}>
                            <MDBox mb={1.5}>
                              <Bar
                                data={chartData}
                                options={{
                                  responsive: true,
                                  plugins: {
                                    legend: {
                                      position: "top",
                                    },
                                    title: {
                                      display: true,
                                      text: "Monthly Average Score",
                                    },
                                  },
                                }}
                              />
                            </MDBox>
                          </Grid>
                        </Grid>

                        {/* <MDBox mt={4.5}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                  <MDBox mb={3}>
                    <ReportsBarChart
                      color="info"
                      title="Nexa Score"
                      date="campaign sent 2 days ago"
                      chart={reportsBarChartData}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <MDBox mb={3}>
                    <ReportsLineChart
                      color="success"
                      title="Career Satisfaction"
                      date="updated 4 min ago"
                      chart={sales}
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox> */}
                      </Grid>
                    </Grid>
                  </MDBox>
                )}
              </>
            )}
          </Grid>
        </Grid>

        {/* <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="Daily Active Users : 2,300"
                  date="campaign sent 2 days ago"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="Average Session Duration : 10 min"
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox> */}
      </Grid>
    </Grid>
  );
};

export default Analytics;
