import { Box, Button, Card, Container, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import PageLayout from "examples/LayoutContainers/PageLayout";
import React from "react";
import { Link } from "react-router-dom";
import logo from "assets/images/logos/header-logo-official.png";

const Terms2 = ({ handleNext }) => {
  return (
    <PageLayout>
      <MDBox px={1} width="100%" height="100vh" mx="auto">
        <Box sx={{ position: "relative", pt: 2 }}>
          <Box
            sx={{
              position: "relative",
              top: "24px",
              left: 0,
              width: "100%",
              zIndex: 9,
              padding: "20px 0",
              position: "static",
            }}
          >
            <Container>
              <Box className="ic-navbar-heads">
                <Box className="ic-logos">
                  <Link to="/">
                    <img src={logo} alt="Logo" style={{ width: "auto", height: "auto" }} />
                  </Link>
                </Box>
              </Box>
            </Container>
          </Box>
          <Container sx={{ textAlign: "center", mt: "30vh" }}>
            {termsData.map((term, index) => (
              <Box key={index} sx={{ pb: 4, textAlign: "center" }}>
                <Typography variant="h5" sx={{ mb: 2, color: "#000" }}>
                  {term.heading}
                </Typography>
                <Typography variant="body1" sx={{ ml: 4 }}>
                  {term.para}
                </Typography>
              </Box>
            ))}
            <Box sx={{}}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  color: "#fff",
                  borderRadius: "20px",
                  padding: "13px 30px",
                  fontSize: "14px",
                }}
                onClick={handleNext}
              >
                I'm Ready
              </Button>
            </Box>
          </Container>
        </Box>
      </MDBox>
    </PageLayout>
  );
};

export default Terms2;

const termsData = [
  {
    heading: "Welcome to Nexa. You're about to embark on a Career journey like no other",
    para: "The following questions will personalize this journey for you.",
  },
];
