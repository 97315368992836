/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import BasicLayout from "layouts/authentication/components/BasicLayout";

import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import logo from "assets/images/logos/header-logo-official.png";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { signInSchema } from "components/schema";
import { loginOrganization } from "store/Slices/authSlice";
import Loader from "components/Loader";
import { Image } from "@mui/icons-material";
import { toast } from "react-toastify";
import { checkOutSession } from "store/Slices/subscriptionSlice";
const initialValues = {
  email: "",
  password: "",
};

function Basic() {
  const { loading } = useSelector((state) => state.auth);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: initialValues,
    validationSchema: signInSchema,
    onSubmit: (values) => {
      const payload = values;
      dispatch(
        loginOrganization({
          payload,
          onSuccess: () => {
            resetForm();
            navigate("/introduction");
          },
        })
      );
    },
  });

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <BasicLayout image={bgImage}>
      {loading && <Loader />}
      <Card>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox
            // variant="gradient"
            // bgColor="white"
            // borderRadius="lg"
            // coloredShadow="info"
            mx={2}
            mt={1}
            p={2}
            mb={1}
            textAlign="center"
          >
            <img src={logo} alt="Logo" />
          </MDBox>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                name="email"
                fullWidth
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.email && touched.email ? (
                <MDTypography color="error" sx={{ fontSize: "13px" }}>
                  {<p>{errors.email}</p>}
                </MDTypography>
              ) : null}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                name="password"
                fullWidth
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.password && touched.password ? (
                <MDTypography color="error" sx={{ fontSize: "13px" }}>
                  {<p>{errors.password}</p>}
                </MDTypography>
              ) : null}
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
