import Repository from "./Repository";
const ADD_SIMPLE_QUESTIONS = "/organization/question";
const IMPORT_QUESTION = "/organization/add-bulk-questions";
const CREATE_BY_CHATGPT = "/organization/generate-questions";
const DELETE_BULK_QUESTIONS = "/organization/questions-bulk";
const GET_QUESTIONS = "/organization/question";
const DELETE_QUESTION = "/organization/question";
const UPDATE_QUESTION = "/organization/question";
const questionsRepository = {
  addSimpleQuestions(payload) {
    return Repository.post(`${ADD_SIMPLE_QUESTIONS}`, payload);
  },
  importQuestions(payload) {
    return Repository.post(`${IMPORT_QUESTION}`, payload);
  },
  createByChatGpt(payload) {
    return Repository.post(`${CREATE_BY_CHATGPT}`, payload);
  },
  getQuestions(payload) {
    return Repository.get(
      `${GET_QUESTIONS}?page=${
        payload.page ? payload.page : 1
      }&size=50&sort_by=role_category_desc&search=${payload.search}`
    );
  },
  deleteQuestion(questionId) {
    return Repository.delete(`${DELETE_QUESTION}?id=${questionId}`);
  },
  deleteQuestionsBulk(payload) {
    return Repository.post(`${DELETE_BULK_QUESTIONS}`, payload);
  },
  updateQuestion(questionId, payload) {
    return Repository.put(`${UPDATE_QUESTION}?id=${questionId}`, payload);
  },
};

export default questionsRepository;
