import { Card } from "@mui/material";
import Modal from "@mui/material/Modal";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useDispatch, useSelector } from "react-redux";
import { getSubscription } from "store/Slices/authSlice";
import { reActivatePackage } from "store/Slices/authSlice";
import ChangePaymentModal from "./ChangePaymentModal";
import { useState } from "react";

const PackageExpireModal = ({ openModal, setopenModal }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);
  const { declineError } = useSelector((state) => state.subScription);
  const [modal, setModal] = useState(false);
  const onClose = () => {
    setModal(!modal);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "90%", sm: "60%", md: "60%", lg: "40%" },
    boxShadow: 24,
    p: 4,
  };

  const handleReactivate = () => {
    dispatch(
      reActivatePackage({
        payload: declineError?.invoice_id,
        onSuccess: () => {
          setopenModal(false);
          dispatch(getSubscription());
        },
        // declineError?.invoice_id
      })
    )
      .unwrap()
      .then((res) => {
        console.log(res, "resbyahsanuqbak");
      });
  };

  return (
    <div>
      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style}>
          <MDBox mx={1} p={1} mt={1}>
            <MDTypography variant="h3" mt={1} sx={{ textAlign: "center", fontWeight: "medium" }}>
              {declineError?.last_payment_error || "Your Card Was Declined"}
            </MDTypography>
            <MDBox mt={6} mb={1} sx={{ display: "flex", gap: "15px", justifyContent: "center" }}>
              <MDButton
                variant="gradient"
                color="info"
                disabled={loading}
                onClick={handleReactivate}
              >
                Retry with same payment method
              </MDButton>
              <MDButton variant="gradient" color="info" disabled={loading} onClick={onClose}>
                Update Payment Method
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      </Modal>
      <ChangePaymentModal
        modal={modal}
        onClose={onClose}
        setopenModal={setopenModal}
        role="packageExpire"
      />
    </div>
  );
};

export default PackageExpireModal;
