import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Icon } from "@mui/material";
import { sendMessageAction } from "store/Slices/ChatSlice";
import { toast } from "react-toastify";
import { getSingleChatAction } from "store/Slices/ChatSlice";
import { useDispatch } from "react-redux";
import { sendMessage } from "config/socketActions";

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapForm: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      margin: `${theme.spacing(0)} auto`,
      marginBottom: theme.spacing(2),
    },
    wrapText: {
      width: "100%",
    },
    button: {
      //margin: theme.spacing(1),
    },
  })
);

export const TextInput = ({ chatID, handleDisabled }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [message, setMessage] = useState("");
  const handleSendMessage = (e) => {
    e.preventDefault();
    let payload = {
      chat_id: chatID,
      content: message,
    };

    dispatch(sendMessageAction(payload))
      .unwrap()
      .then((res) => {
        sendMessage(payload);
        dispatch(getSingleChatAction(chatID));
      })
      .catch((err) => {
        toast.error(err.data.message);
      });
  };

  return (
    <>
      <form onSubmit={handleSendMessage} className={classes.wrapForm} noValidate autoComplete="off">
        <TextField
          id="standard-text"
          label="Send Message"
          className={classes.wrapText}
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          //margin="normal"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={handleDisabled("update")}
        >
          <Icon>send</Icon>
        </Button>
      </form>
    </>
  );
};
